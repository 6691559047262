.review {
  &__elem {
    padding-bottom: 40px;
    margin-bottom: 30px;
    border-bottom: 2px solid #eef3f6;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
  }

  &__header-wrap {
    display: flex;
    align-items: center;

    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__author {
    margin-right: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #383838;
  }

  &__date {
    font-size: 14px;
    color: #b4b4b4;

    @include max-screen(767px) {
      font-size: 12px;
    }
  }

  &__rating {
    position: relative;
    margin-left: auto;
    padding-left: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #26c3df;
    background: url('../img/svg/rating.svg') no-repeat left center;
    background-size: 40%;

    @include max-screen(767px) {
      font-size: 14px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: -20px;
      left: 0;
    }

    &:hover {
      .profile__rating-desc {
        display: block;
        top: 40px;
        @include max-screen(767px) {
          top: 20px;
        }
      }
    }
  }

  &__content {
    font-size: 14px;
    color: #383838;
  }

  &__boat {
    margin-top: 30px;
  }

  &__boat-name {
    color: #848485;
  }
}
.full{
    width: 100%;   
}
