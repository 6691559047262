.rentals{
   padding-top: 72px;
   padding-bottom: 17px;

   @include max-screen(992px){
      padding-top: 36px;
   }

   &__title{
      margin: 0;
      color: #383838;
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;

      @include max-screen(992px){
         color: #383838;
         font-size: 18px;
         line-height: 24px;
      }
   }
   &__subtitle{
      color: #383838;
      line-height: 18px;
      text-align: center;
      font-size: 16px;
      @include max-screen(992px){
         font-size: 14px;
         line-height: 20px;
         margin-top: 11px;
      }
   }
   &__boats{
      margin-top: 42px;
      min-height: 200px;
      position: relative;
      @include max-screen(992px){
         margin-top: 28px;         
      }
   }
   &__sidebar{
      @include min-screen(992px){
         position: relative;
         top: -70px;
      }
      @include max-screen(991px){
         padding-left: 5px;
         padding-right: 5px;
      }
   }
   &__sidebar-coutry{
      margin-top: 37px;
   }
   &__sidebar-country-title{
      color: #919191;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      margin-top: 0;
      margin-bottom: 10px;
   }
   &__sidebar-country-text{
      color: #919191;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
   }
   &__sidebar-map{
      height: 350px;
      @include max-screen(991px){
         display: none;
         margin-top: 28px;
      }
   }
   &__sidebar-map-poster{
      &._for-desktop{
         @include max-screen(991px){
            display: none;
         }
      }
      &._for-tablet{
         display: none;

         @include screen(768px, 991px){
            display: block;
         }
      }
      &._for-mobile{
         width: 100%;
         @include min-screen(768px){
            display: none;
         }
      }
   }
   &__sidebar-filter{
     @include max-screen(991px){
        display: none;
        margin-top: 28px;
     }
   }
   &__sidebar-coutry{
      @include max-screen(992px){
         display: none;
      }
   }
   &__mobile-controls-btn{
      height: 56px;
      border-radius: 3px;
      border: 2px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      &._is-active{
         border-color: #5fd9ef;  
      }
   }
   &__mobile-controls{
      margin-top: 35px;

      @include min-screen(992px){
         display: none;
      }
   }
   &__boats-item{
      @include max-screen(992px){
         margin-bottom: 34px;
      }
   }

   &__wrap {
      min-height: 100vh;
   }

   .ui-slider-handle{
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: $base-color2;
      background-image: url('../img/svg/rectangle-22.svg');
      background-repeat: no-repeat;
      background-position: center center;
      outline: 0;
      top: -10px;
      z-index: 1;
      user-select: none;
   }
   .ui-slider{
      height: 5px;
      border-radius: 0;
      background-color: #b8ccd2;
      border: 0;
      cursor: pointer;
      user-select: none;
   }
   .ui-slider-range{
      background-color: $base-color2;
      cursor: pointer;
      user-select: none;
   }
}


.rating-title{
    display: inline;
}
.rating-block{
   vertical-align: middle;
   @include min-screen(768px){
      display: inline-block!important;
      padding-right: 240px;
   }
}
.rating-comment{
    margin-top: 10px;
}
