.profile-header {

  &._profile {
    padding-top: 68px;
    padding-bottom: 79px;
  }

  &._rentals-boat {
    padding-top: 0;
    @include max-screen(1090px) {
      padding-top: 40px;
    }
    @include max-screen(992px) {
      padding-bottom: 53px;
    }
  }

  &._view-boat {
    padding-top: 0;
    @include max-screen(1090px) {
      padding-top: 40px;
    }
  }

  &._inner {
    min-height: auto;
  }

  @include max-screen(992px) {
    padding-top: 39px;
  }
  @include max-screen(992px) {
    padding-bottom: 0;
  }

  &__container {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    &._column {
      flex-direction: column;
    }

  }

  &__column-container {
    display: flex;
    justify-content: space-between;

    &._time-response {
      @include min-screen(768px) {
        margin-top: 29px;
      }
    }

    &._full-tablet {
      width: 100%;
      @include screen(320px, 768px) {
        height: 100%;
        align-items: center;
      }
    }
  }

  &__content-column {
    display: flex;
    width: 100%;

    @include max-screen(767px) {
      &:not(._no-wrap) {
        flex-wrap: wrap;
      }
    }

    &._for-mobile {
      @include min-screen(768px) {
        display: none;
      }
    }
  }

  &__column {
    flex: 1;
    @include max-screen(992px) {
      flex: 50%;
    }

    &._small {
      @include min-screen(992px) {
        max-width: 191px;
      }
    }

    &._medium {
      @include min-screen(992px) {
        max-width: 229px;
      }
    }

    &._big {
      max-width: 420px;
    }

    &._city-wrap {
      font-size: 14px;
    }

    &._right {
      text-align: right;
    }

    &._for-big-desktop {
      @include max-screen(992px) {
        display: none;
      }
    }

    &._tablet-margin {
      @include max-screen(992px) {
        margin-bottom: 10px;
      }
    }

    &._mobile-hide {
      @include max-screen(768px) {
        display: none;
      }
    }
  }

  &__content {
    width: 100%;

    @include min-screen(1160px) {
      max-width: 590px;

      &._full {
        max-width: 100%;
      }
    }

    &._rentals-boat {
      @include max-screen(576px) {
        margin-bottom: 32px;
      }
    }
  }

  &__avatar-wrap {
    margin-right: 40px;
    @include max-screen(576px) {
      margin-right: 15px;
      margin-bottom: 28px;
    }
  }

  &__avatar {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    cursor: pointer;
    background-color: #8d969d;

    input {
      visibility: hidden;
      display: none;
    }

    @include max-screen(576px) {
      width: 82px;
      height: 82px;
    }
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include max-screen(576px) {
      &:not(._row-mobile) {
        flex-direction: column;
        align-items: flex-start;
      }

    }

    &._flex-start {
      justify-content: flex-start;
    }

    &._for-table-desktop {
      @include max-screen(768px) {
        display: none;
      }
    }

    &._rentals-boat {
      @include max-screen(576px) {
        flex-direction: row;
      }
      @include max-screen(400px) {
        justify-content: space-between;
      }
    }

    &._tablet-wrap {
      @include max-screen(992px) {
        flex-wrap: wrap;
      }
    }

    &._user-profile {
      @include screen(576px, 768px) {
        margin-bottom: 20px;
      }
    }
  }

  &__avatar-edit {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 120px;
    height: 30px;
    background-color: rgba(53, 60, 66, 0.8);
    text-align: center;
    padding-top: 6px;

    @include max-screen(576px) {
      width: 82px;
      height: 27px;
      padding-top: 3px;
    }
  }

  &__avatar-edit-link {
    color: #ffffff;

    &._for-desktop {
      @include max-screen(576px) {
        display: none;
      }
    }

    &._for-mobile {
      @include min-screen(575px) {
        display: none;
      }
    }
  }

  &__title {
    color: #353c42;
    font-size: 24px;
    font-weight: 400;
    line-height: 100%;
    margin: 0;
    margin-bottom: 7px;
    display: inline-block;

    &._for-desktop {
      @include max-screen(767px) {
        display: none;
      }
    }

    &._rentals {
      font-weight: 700;
      margin-top: -2px;

      @include max-screen(576px) {
        font-size: 18px;
      }
    }

    @include max-screen(576px) {
      line-height: 28px;
    }
  }

  &__mobile-title {
    max-width: 325px;
    display: flex;
    align-items: center;

    @include max-screen(768px) {
      max-width: 280px;
    }
    @include max-screen(576px) {
      max-width: 200px;
      align-items: flex-start;
    }

    @include min-screen(768px) {
      display: none;
    }
  }

  &__location {
    @include max-screen(576px) {
      display: flex;
      width: 100%;
      margin-bottom: 11px;
    }

    &._rentals-boat {
      @include max-screen(576px) {
        margin-bottom: 0;
        margin-top: 11px;
      }
    }
  }

  &__location-city {
    color: #353c42;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 24px;
    margin-right: 14px;
    position: relative;

    @include max-screen(576px) {
      margin-right: 26px;
    }

    &::before {
      content: '\e93b';
      color: #8d969d;
      position: absolute;
      left: 0;
      font-family: "icomoon" !important;
      font-size: 16px;
      top: 1px;
    }
  }

  &__location-edit {
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-bottom: 1px dotted;

    &:hover {
      text-decoration: none;
    }

    @include max-screen(767px) {
      margin-left: auto;
    }
  }

  &__language-wrap {
    @include max-screen(576px) {
      display: flex;
      width: 100%;
    }
  }

  &__languages {
    width: 100%;
    color: #353c42;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    @include max-screen(767px) {
      padding-left: 24px;
      display: flex;
    }
  }

  &__hint {
    color: #848484;

    &._aboard {
      font-size: 14px;
      @include min-screen(992px) {
        float: right;
      }
    }

    &._small {
      font-size: 14px;
    }

    &._bold {
      color: #353c42;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  &__languages-edit {
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-bottom: 1px dotted;
    margin-left: 15px;

    &:hover {
      text-decoration: none;
    }

    @include max-screen(576px) {
      margin-left: auto;
    }
  }

  &__area {
    width: 100%;
    max-width: 590px;
    border-radius: 3px;
    background-color: #ffffff;
    height: 54px;
    border: 0;
    outline-color: $base-color;
    color: #848484;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    padding: 18px 22px;
    resize: none;

    &._note {
      max-width: 100%;
      margin-right: 10px;

      @include max-screen(768px) {
        margin-bottom: 10px;
      }
    }

    @include max-screen(576px) {
      font-size: 14px;
      line-height: 24px;
      padding: 16px 15px 16px 20px;
      max-width: 100%;
    }

  }

  &__form {
    width: 100%;
    margin-top: 28px;

    @include max-screen(576px) {
      margin-top: 34px;
    }
  }

  &__event {
    height: 140px;
    border-radius: 3px;
    border: 2px solid #ffffff;
    right: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 26px 29px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    flex-shrink: 0;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-top: 44px;

    @include min-screen(992px) {
      align-items: start;
    }

    &::after {
      content: '';
      position: relative;
      bottom: 0;
      width: 310px;
      height: 2px;
      background-color: #ff8400;
      display: block;
      margin: 0 auto -2px;

      @include max-screen(992px) {
        margin-bottom: -3px;
        width: 100%;
      }
    }
  }

  &__event-hint {
    color: #353c42;
    position: relative;

    &::after {
      content: "\e912";
      font-family: "icomoon" !important;
      display: inline-block;
      vertical-align: bottom;
      font-size: 16px;
      margin-left: 10px;
    }
  }

  &__event-date {
    color: #848484;
    margin-bottom: 15px;
    margin-top: 9px;
  }

  &__event-title {
    color: #353c42;
    font-size: 18px;
    font-weight: 700;
    margin-top: 24px;
    outline: 0;
  }

  &__event-top {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 100%;

    @include max-screen(992px) {
      position: relative;
      max-width: 366px;
      top: -30px;
      margin: auto;
    }

    &::before,
    &::after {
      width: 40px;
      height: 28px;
      border: 2px solid #ffffff;
      background-color: #eef3f6;
      display: block;
      position: absolute;
      content: '';
      top: -14px;
    }

    &::before {
      left: 79px;
      @include max-screen(576px) {
        left: 16px;
      }
    }

    &::after {
      right: 77px;
      @include max-screen(576px) {
        right: 14px;
      }
    }
  }

  &__user-online {
    color: #51bd61;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    margin-left: 41px;
    margin-top: -3px;

    &._profile {
      margin-left: 14px;
    }

    &._rentals-boat {
      @include max-screen(576px) {
        margin-top: 5px;
        margin-left: 18px;
        margin-right: 5px;
      }
    }

    &._offline {
      color: #ff8400;

      &::before {
        background-color: #ff8400;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: #39b54a;
      border-radius: 50%;
      position: absolute;
      left: -12px;
      top: 6px;
    }
  }

  &__features {
    display: flex;
    margin-top: 34px;
    width: 100%;
    max-width: 510px;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include max-screen(576px) {
      flex-wrap: wrap;
      margin-top: 0px;
    }
  }

  &__features-title {
    color: #353c42;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
  }

  &__features-col {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin-bottom: 25px;

    @include max-screen(576px) {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  &__features-img {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 5px;
  }

  &__about {
    border: 2px solid #ffffff;
    padding: 32px 25px 35px 38px;
    margin-top: 21px;
    margin-bottom: 21px;
    max-width: 770px;
    min-width: 140px;
    border-radius: 2px;
    position: relative;

    &._full {
      max-width: 100%;
    }

    @include max-screen(576px) {
      margin-top: 13px;
      padding: 22px 25px 20px 19px;
    }
  }

  &__about-text {
    margin: 0;
    color: #383838;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @include max-screen(576px) {
      font-size: 14px;
    }
  }

  &__about-delta {
    width: 22px;
    height: 2px;
    position: absolute;
    top: -2px;
    left: 57px;
    display: block;
    background-color: #eef3f6;

    @include max-screen(576px) {
      left: 27px;
    }

    &::before,
    &::after {
      width: 16px;
      height: 2px;
      background-color: #fff;
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      transform: rotate(48deg);
      top: -6px;
      left: 8px;
    }

    &::after {
      transform: rotate(-48deg);
      top: -6px;
      left: -2px;
    }

  }

  &__about-edit {
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
    border-bottom: 1px dashed;
    display: inline-block;
    position: absolute;
    bottom: 15px;
    right: 16px;

    &:hover {
      text-decoration: none;
    }
  }

  &__verify-status {
    color: $base-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    @include screen(768px, 992px) {
      margin-right: 15px;
    }

    &::before {
      content: "\e908";
      font-family: $icon-font;
      margin-right: 9px;
      vertical-align: middle;
    }

    &._profile {
      line-height: 24px;
    }

    &._not-verified {
      color: #848484;
    }
  }

  &__send-btn-wrap {
    justify-content: center;
    margin-top: 30px;
  }

  &__success {
    display: inline-block;
    vertical-align: top;
    margin-top: 9px;
    margin-left: 15px;
    font-size: 12px;
    color: #848484;
  }

  &__status-length {
    float: right;
    margin-top: 5px;
    color: #848484;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    min-width: 100%;
    font-size: 14px;

    @include max-screen(767px) {
      flex-wrap: wrap;
      margin-top: 12px;
    }
  }

  &__blog-info {
    @include max-screen(767px) {
      min-width: 100%;
      padding: 10px 0 10px 24px;
    }
  }

  &__onboard {
    color: #848484;
    @include max-screen(767px) {
      padding-left: 24px;
    }
  }
}

.preloader-avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../img/25.gif) #fff no-repeat center;
  display: none;

  &.inActive {
    display: block;
  }
}

#profile-land-static {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  @include max-screen(767px) {
    max-width: 160px;
  }
}

#profile-location-static {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  @include max-screen(767px) {
    max-width: 160px;
  }
}

.public-profile .mobile-menu {
  display: none;
}

@media only screen and (max-width: 992px) {
  .public-profile .mobile-menu {
    display: block;
  }
}

.profile-header__form .topline__addboat {
  max-width: 100px;
}
