.login {
   padding-top: 89px;
   padding-bottom: 150px;

   &._modal {
      padding-top: 0;
      padding-bottom: 0;
   }

   &._sign{
      padding-top: 49px;
      padding-bottom: 65px;

      .sails-form__submit{
         margin-top: 20px;
      }
      .login__sign-message{
         margin-top: 25px;
         &._modal {
            margin-top: 5px;
         }
      }

      .login__social-item{
         &._facebook-login::before{
            left: 21px;
         }
         &._google-login::before{
            left: 14px;
         }
         &._vk-login::before{
            left: 11px;
         }
         @include max-screen(576px){
            &._facebook-login::before{
               left: 11px;
            }
            &._google-login::before{
               left: 12px;
            }
         }
      }
   }

   @include max-screen(992px){
      padding-top: 204px;
      
   }
   @include max-screen(576px){
      padding-top: 9px;
   }
   
   &__container{
      background-color: #fff;
      margin: 0 auto;
      max-width: 600px;
      padding: 29px 45px;
      border-radius: 2px;

      &._modal {
         min-width: 430px;
      }

      @include max-screen(576px){
         max-width: 300px;
         padding-left: 20px;
         padding-right: 20px;
         padding-bottom: 40px;
      }

      &._modal{
         padding: 14px 45px;
         @include max-screen(576px){
            min-width: 100%;
            padding-bottom: 20px;
         }
      }
   }
   &__logo{
      width: 150px;
      margin: 0 auto 28px;
      @include max-screen(576px){
         width: 120px;
         margin-bottom: 17px;
      }
      &._modal {
         margin: 0 auto 8px;
         @include max-screen(576px){
            margin-bottom: 4px;
         }
      }
   }
   &__title{
      margin: 0;
      text-align: center;
      margin-bottom: 27px;
      @include max-screen(767px) {
         &._profile {
            font-size: 22px;
         }
      }
   }
   &__social{
      display: flex;
      flex-direction: column;
   }
   &__social-item{
      width: 100%;
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      color: #ffffff;
      border-radius: 3px;
      text-align: center;
      margin-bottom: 10px;
      position: relative;

      @include max-screen(576px){
         font-size: 16px;
         padding-left: 38px;
      }

      &._modal {
         height: 40px;
         line-height: 40px;
         margin-bottom: 5px;
      }

      &::before{
         font-family: "icomoon" !important;
         position: absolute;
      }

      &._facebook-login{
         background-color: #3b5998;
         @include max-screen(576px){
            padding-left: 25px;
            padding-right: 10px;
         }

         &._modal {
            &:before {
               left: 11px;
            }
         }

         &:before{
            content: '\e921';
            left: 31px;
            font-size: 20px;

            &._modal {
               left: 21px;
               @include max-screen(576px){
                  left: 11px;
               }
            }
         }

         &:hover{
            background-color: #3b5998 + 25;
         }
      }
      &._google-login{
         background-color: #d95232;

         &._modal {
            &:before {
               left: 12px;
            }
         }

         &:before{
            content: '\e923';
            left: 26px;
            font-size: 21px;

            &._modal {
               left: 14px;
               @include max-screen(576px){
                  left: 12px;
               }
            }
         }

         &:hover{
            background-color: #d95232 + 25;
         }
      }
      &._vk-login{
         background-color: #5b7aa8;
         &:before{
            content: '\e922';
            left: 21px;
         }

         &:hover{
            background-color: #5b7aa8 + 25;
         }
      }
   }
   &__or-devider{
      height: 1px;
      background-color: #eef3f6;
      width: 100%;
      display: block;
      margin: 24px 0 33px;
      text-align: center;
      position: relative;
      &._modal {
         margin: 10px 0 15px;
      }
   }
   &__or-devider-text{
      position: relative;
      z-index: 1;
      background-color: #fff;
      top: -16px;
      color: #8c959c;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding: 0 13px;
      &._modal {
         font-size: 12px;
         line-height: 12px;
      }
   }
   &__lost-password-wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      margin-bottom: 25px;
      @include max-screen(576px){
         flex-direction: column;
      }
      &._reset{
         flex-wrap: wrap;
      }
   }
   &__lost-password{
      color: #919191;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
   }
   &__sign-message{
      color: #454545;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      margin-top: 32px;
      &._modal {
         font-size: 14px;
         line-height: 16px;
         margin-top: 5px;
      }
   }
   &__sign-message-link{
      color: #919191;
      border-bottom: 1px dotted;
      &:hover{
         text-decoration: none;
      }
   }
   &__conditions{
      color: #919191;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 0;
   }
}

.popup-bg {
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   background: rgba(0,0,0,.7);
   z-index: 701;
   visibility: hidden;
   opacity: 0;
   transition: .2s all linear;

   &.inActive {
      visibility: visible;
      opacity: 1;
   }
}

.popup-fixed {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
   z-index: 702;
   height: 255px;
   visibility: hidden;
   opacity: 0;
   transform: translateY(-200px);
   transition: .2s all linear;

   &.inActive {
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
   }
}

.popup-lang {
  height: 330px;
}

.close-popup {
   position: absolute;
   right: 15px;
   top: 13px;
   width: 25px;
   height: 25px;
   opacity: 0.3;
   cursor: pointer;

   &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 26px;
      width: 2px;
      background-color: #333;
   }

   &:before {
      transform: rotate(45deg);
   }

   &:after {
      transform: rotate(-45deg);
   }

   &:hover {
      opacity: 1;
   }
}