.verify {
  margin-left: auto;

  @include screen(768px, 992px) {
    margin: 0 20px; 
  }

  &__wrap {
    width: 470px;
    background-color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    padding-bottom: 30px;

    @include screen(768px, 900px) {
      width: 400px;
    }
    @include max-screen(767px) {
      width: 100%;
      padding: 15px;
    }
  }

  &__title {
    margin-bottom: 28px;
    font-size: 18px;
    font-weight: bold;
  }
  
  &__notice {
    position: relative;
    padding-left: 28px;
    margin-bottom: 26px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      background: url('../img/svg/verif.svg') no-repeat center;
    }

    @include min-screen(1100px) {
      &._code {
        padding-right: 45px;
      }
    }
  }

  &__profile-button {
    margin-left: auto;
    @include max-screen(767px) {
      margin-left: 0;
    }
  }

  &__phone {
    &::-webkit-inner-spin-button, ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }

  &__submit-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max-screen(900px) {
      flex-wrap: wrap;
    }
  }

  &__resend-wrap {
    display: flex;
    padding-left: 20px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -5px;

    @include max-screen(900px) {
      max-width: 200px;
      padding-left: 20px;
      margin-top: -5px;
    }

    @include max-screen(449px) {
      margin-top: 20px;
      padding-left: 0;
    }
  }

  &__resend {
    font-size: 12px;
    color: #848484;
    border-bottom: 1px dashed #848484;
    &:hover {
      text-decoration: none;
      border-bottom: 1px dashed #ff8400;
    }

    &._disabled {
      pointer-events: none;
      opacity: .6;
    }

    &._back {
      margin-top: 5px;
    }
  }

  &__success {
    padding-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #ff8400;
  }

  &__error {
    margin-bottom: 20px;
    font-size: 14px;
    color: red;
  }

  &__warning {
    display: flex;
    align-items: center;
    padding: 22px;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__name {
    padding-right: 20px;
    font-weight: bold;
  }

  &__text {
    position: relative;
    padding-left: 30px;
    font-size: 14px;

    @include max-screen(1170px) {
      margin: 14px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background: url('../img/svg/i.svg') no-repeat center;
      transform: translate(0, -50%);
      @include max-screen(767px) {
        top: 5px;
        transform: translate(0, 0);
      }
    }
  }
}