.content{
   max-width: 870px;
   background-color: #ffffff;
   border-radius: 2px;
   overflow: hidden;
   
   &__notify{
      height: 101px;
      background-color: #ff8400;
      background-image: url('../img/content-notify-bg.png');
      background-repeat: no-repeat;
      background-position: 331px -70px;
      display: flex;
      align-items: center;
      padding-left: 49px;
      @include max-screen(992px){
         padding-left: 30px;
         background-position: 91px -71px;
      }
      @include max-screen(767px){
         height: 135px;
         padding-left: 20px;
         padding-right: 20px;
      }
      @include max-screen(768px){
         background-position: 91px -40px;
      }
      @include max-screen(576px){
         background-position: -85px -38px;
      }
   }
   &__notify-text{
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
      position: relative;
      padding-left: 26px;
      
      @include max-screen(767px){
         line-height: 20px;
      }

      &::before{
         content: "\e925";
         font-family: icomoon!important;
         position: absolute;
         left: 0px;
         color: #fff;
         font-size: 16px;
      }
   }
   &__wrap{
      padding: 48px 29px 0 51px;
      @include max-screen(992px){
         padding-left: 32px;
         padding-top: 45px;
         padding-right: 0;
      }
      @include max-screen(767px){
         padding-left: 20px;
         padding-right: 20px;
         padding-top: 37px;
         padding-bottom: 17px;
      }
   }
   &__title{
      color: #353c42;
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      margin: 0;
      margin-bottom: 30px;
      width: 100%;
      @include max-screen(992px){
         font-size: 18px;
      }
   }
}