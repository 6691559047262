.related-post{
   padding-top: 30px;
   padding-bottom: 80px;

   @include max-screen(992px){
      padding-top: 34px;
   }
   @include max-screen(768px){
      padding-top: 22px;
      padding-bottom: 45px;      
   }

   &__title{
      margin-bottom: 21px;

      @include max-screen(992px){
         margin-bottom: 33px;
      }
   }
   &__more-wrap{
      padding-top: 13px;
      display: flex;
      justify-content: center;
      text-decoration: none;
      &:hover{
         text-decoration: none;
      }
      @include max-screen(768px){
         padding-top: 28px;
      }
   }
   &__more{
      @include max-screen(768px){
         padding: 0 29px;
      }
   }
}