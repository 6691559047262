.notify{
   padding-top: 61px;

   @include max-screen(992px){
      padding-top: 53px;
   }

   &._profile-tabs{
      padding-top: 44px;
      
      @include min-screen(576px){
         min-height: 393px;
      }
      @include min-screen(992px){
         min-height: 546px;
      }

      @include max-screen(992px){
         padding: 34px 0 60px;
      }
   }

   &._profile-boats-messages{
      margin-bottom: 81px;

      @include max-screen(992px){
         margin-bottom: 77px;
      }
      @include max-screen(576px){
         margin-bottom: 70px;
      }
   }
   &__content {
      padding-bottom: 50px;
   }
   &__title{
      text-align-last: left;
      margin-bottom: 20px;

      @include max-screen(992px){
         margin-bottom: 34px;
      }
   }
   &__item{
      border-radius: 3px;
      background-color: #ffffff;
      padding: 34px 65px 34px 32px;
      margin-bottom: 1px;
      position: relative;

      @include max-screen(576px){
         padding-top: 22px;
         padding-left: 21px;
         padding-bottom: 25px;
      }
   }
   &__item-text{
      margin: 0;
      font-size: 14px;
   }
   &__item-close{
      position: absolute;
      right: 30px;
      top: 0;
      font-size: 30px;
      display: flex;
      align-items: center;
      height: 100%;

      @include max-screen(992px){
         right: 20px;
      }
      @include max-screen(576px){
         right: 10px;
         padding-bottom: 4px;
         font-size: 25px;
      }
   }
   &__item-close-icon{
      cursor: pointer;
      
      &::before{
         transition: color .3s;
      }

      &:hover{
         &::before{
            color: $base-color;
         }  
      }
   }
   
   &__link {
      color: #ff8400;
   }
}