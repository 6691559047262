@font-face {
  font-family: "Open Sans";
  src: url('../fonts/OS/OpenSans-Regular.eot');
  src: url('../fonts/OS/OpenSans-Regular.eot') format('embedded-opentype'), 
  url('../fonts/OS/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url('../fonts/OS/OpenSans-Bold.eot');
  src: url('../fonts/OS/OpenSans-Bold.eot') format('embedded-opentype'), 
  url('../fonts/OS/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url('../fonts/OS/OpenSans-Light.eot');
  src: url('../fonts/OS/OpenSans-Light.eot') format('embedded-opentype'), 
  url('../fonts/OS/OpenSans-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}




// Icons
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?mck9ay');
  src:  url('../fonts/icons/icomoon.eot?mck9ay#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.ttf?mck9ay') format('truetype'),
    url('../fonts/icons/icomoon.woff?mck9ay') format('woff'),
    url('../fonts/icons/icomoon.svg?mck9ay#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-forma-1 .path1:before {
  content: "\e932";
  color: rgb(56, 56, 56);
}
.icon-forma-1 .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(38, 195, 223);
}
.icon-forma-2 .path1:before {
  content: "\e934";
  color: rgb(56, 56, 56);
}
.icon-forma-2 .path2:before {
  content: "\e935";
  margin-left: -1.0107421875em;
  color: rgb(38, 195, 223);
}
.icon-forma-3 .path1:before {
  content: "\e936";
  color: rgb(56, 56, 56);
}
.icon-forma-3 .path2:before {
  content: "\e937";
  margin-left: -1.06640625em;
  color: rgb(38, 195, 223);
}
.icon-filtr-map-mobile:before {
  content: "\e900";
  color: #8c959c;
}
.icon-filtr-mobile:before {
  content: "\e901";
  color: #8c959c;
}
.icon-close-mobile:before {
  content: "\e902";
  color: #26c3df;
}
.icon-send-message-mobile:before {
  content: "\e903";
  color: #fff;
}
.icon-profile-mobile:before {
  content: "\e904";
  color: #8c959c;
}
.icon-menu-mobile:before {
  content: "\e905";
  color: #8c959c;
}
.icon-button-message-white:before {
  content: "\e906";
  color: #fff;
}
.icon-button-phone-white:before {
  content: "\e907";
  color: #fff;
}
.icon-verif:before {
  content: "\e908";
  color: #ff8400;
}
.icon-notif-system:before {
  content: "\e909";
  color: #8c959c;
}
.icon-arrow-left-white:before {
  content: "\e90a";
  color: #fff;
}
.icon-arrow-right-white:before {
  content: "\e90b";
  color: #fff;
}
.icon-add-photo_white:before {
  content: "\e90c";
}
.icon-captain_includ:before {
  content: "\e90d";
  color: #8c959c;
}
.icon-pass:before {
  content: "\e90e";
  color: #8c959c;
}
.icon-left:before {
  content: "\e90f";
}
.icon-right:before {
  content: "\e910";
}
.icon-anc-white_add-boat:before {
  content: "\e911";
}
.icon-arrow:before {
  content: "\e912";
}
.icon-delete_notif:before {
  content: "\e913";
  color: #8c959c;
}
.icon-icon-own1 .path1:before {
  content: "\e914";
  color: rgb(238, 243, 246);
}
.icon-icon-own1 .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(56, 56, 56);
}
.icon-icon-own1 .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(38, 195, 223);
}
.icon-icon-own2 .path1:before {
  content: "\e917";
  color: rgb(238, 243, 246);
}
.icon-icon-own2 .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(38, 195, 223);
}
.icon-icon-own2 .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(56, 56, 56);
}
.icon-icon-own3 .path1:before {
  content: "\e91a";
  color: rgb(238, 243, 246);
}
.icon-icon-own3 .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(56, 56, 56);
}
.icon-icon-own3 .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(38, 195, 223);
}
.icon-no-avator .path1:before {
  content: "\e91d";
  color: rgb(228, 234, 237);
}
.icon-no-avator .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-check:before {
  content: "\e91f";
  color: #ff8400;
}
.icon-search-help:before {
  content: "\e920";
}
.icon-add-photo .path1:before {
  content: "\e921";
  color: rgb(255, 255, 255);
}
.icon-add-photo .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(140, 149, 156);
}
.icon-add-photo .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(140, 149, 156);
}
.icon-droplist2:before {
  content: "\e924";
}
.icon-hover-info:before {
  content: "\e925";
}
.icon-message:before {
  content: "\e926";
  color: #8c959c;
}
.icon-notic:before {
  content: "\e927";
  color: #8c959c;
}
.icon-filt .path1:before {
  content: "\e928";
  color: rgb(0, 0, 0);
}
.icon-filt .path2:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-droplist:before {
  content: "\e92a";
}
.icon-location-price:before {
  content: "\e92b";
  color: #ff8400;
}
.icon-filt_power:before {
  content: "\e92c";
  color: #8c959c;
}
.icon-filt_sail:before {
  content: "\e92d";
  color: #8c959c;
}
.icon-search-white:before {
  content: "\e92e";
  color: #fff;
}
.icon-social-fb:before {
  content: "\e92f";
  color: #26c3df;
}
.icon-social-ins:before {
  content: "\e930";
  color: #26c3df;
}
.icon-social-vk:before {
  content: "\e931";
  color: #26c3df;
}
.icon-location:before {
  content: "\e93b";
  color: #8c959c;
}
.icon-recreation1:before {
  content: "\e93c";
  color: #8c959c;
}
.icon-recreation2:before {
  content: "\e93d";
  color: #8c959c;
}
.icon-recreation3:before {
  content: "\e93e";
  color: #8c959c;
}
.icon-recreation4:before {
  content: "\e93f";
  color: #8c959c;
}
.icon-recreation5:before {
  content: "\e940";
  color: #8c959c;
}
.icon-recreation6:before {
  content: "\e941";
  color: #8c959c;
}
.icon-recreation7:before {
  content: "\e942";
  color: #8c959c;
}
.icon-recreation8:before {
  content: "\e943";
  color: #8c959c;
}
.icon-recreation9:before {
  content: "\e944";
  color: #8c959c;
}
.icon-add-boat-white:before {
  content: "\e945";
  color: #fff;
}
.icon-captain:before {
  content: "\e946";
  color: #ace5ff;
}
.icon-date:before {
  content: "\e947";
  color: #26c3df;
}
.icon-social-facebook:before {
  content: "\e921";
}
.icon-social-vk2:before {
  content: "\e922";
}
.icon-social-google:before {
  content: "\e923";
}
