.mobile_fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-top: 1px solid #dfe0e0;
  background-color: #ffffff;
  padding: 10px;

  @include min-screen(1090px){
    display: none;
  }

  &__hide-sidebar{
    @include max-screen(1090px){
      display: none;
    }
  }

  &__content{
  }

  &__row{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 5px 0;
  }

  &__price-info{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 33.33%;
    float: left;
  }

  &__relative{
    position: relative;
  }

  &__price{
    color: $base-color;
    font-weight: 600;
    flex-grow: 0;
    flex-shrink: 0;
    flex-wrap: nowrap;
    margin: 0 3px;
    line-height: 21px;
    font-size: 21px;
    display: inline-block;
  }

  &__price-period{
    line-height: 16px;
    font-size: 12px;
  }

  &__btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    width: 66.66%;
  }

  &__btn-text {
    display: block;
    font-size: 12px;
    font-weight: 500;

    @include min-screen(576px){
      box-sizing: border-box;
      display: inline-block;
      font-size: 15px;
      font-weight: 400;
    }

    @include max-screen(350px) {
      display: none;
    }
  }

  &__btn-icon {
    font-size: 22px;
    vertical-align: middle;

    @include min-screen(350px){
      font-size: 20px;
    }
  }

  &__btn{
	border-radius: 3px;
	color: #ffffff;
    line-height: 1.5;
	font-size: 15px;
	font-weight: 400;
	padding: 10px 12px;
    margin: 0 3px;
	border: 0;
	outline: 0;
	cursor: pointer;
    text-align: center;
    width: 140px;

    @include min-screen(576px){
      width: 200px;
    }

	&._orange{
	  background-color: $base-color;
	  &:hover{
		 background-color: #ee6a00;
	  }
	}

	&._blue{
	  background-color: $base-color2;
	  &:hover{
		 background-color: #09b6d5;
		}
	}
    &:hover{
        text-decoration: none;
        color: #ffffff;
      }
  }
}


/* свойства модального окна по умолчанию */
.modal {
  @include max-screen(1090px) {
    position: fixed; /* фиксированное положение */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* цвет фона */
    z-index: 1050;
    opacity: 0; /* по умолчанию модальное окно прозрачно */
    -webkit-transition: opacity 200ms ease-in;
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in; /* анимация перехода */
    pointer-events: none; /* элемент невидим для событий мыши */
    margin: 0;
    padding: 0;
  }
}

/* при отображении модального окно */
.modal:target {
  @include max-screen(1090px) {
    opacity: 1; /* делаем окно видимым */
    pointer-events: auto; /* элемент видим для событий мыши */
    overflow-y: auto; /* добавляем прокрутку по y, когда элемент не помещается на страницу */
    z-index: 1050;
  }
}

/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  @include max-screen(1090px) {
    position: relative;
    width: auto;
    margin: 30px auto;
  }
}

/* свойства для блока, содержащего контент модального окна */
.modal-content {
  @include max-screen(1090px) {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
  }
  @media (min-width: 768px) {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }
}

/* свойства для заголовка модального окна */
.modal-header {
  display: none;
  @include max-screen(1090px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #eceeef;
  }
}

.modal-title {
  @include max-screen(1090px) {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.25rem;
    font-weight: 500;
  }
}

/* свойства для кнопки "Закрыть" */
.close {
  @include max-screen(1090px) {
    float: right;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    text-decoration: none;
  }
}

/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus, .close:hover {
  @include max-screen(1090px) {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .75;
  }
}

/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  @include max-screen(1090px) {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 15px;
    overflow: auto;
  }
}

.dont-show-in-modal{
  @include max-screen(1090px){
      display: none;
    }
}