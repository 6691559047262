.testimonial{

   @include max-screen(768px){
      padding-top: 21px;
      margin-bottom: 18px;
   }

   &__header{
      display: flex; 
      padding-left: 30px;
      max-height: 52px;
      overflow: hidden;

      @include max-screen(992px){
         max-height: 68px;
      }
   }
   &__poster{
      width: 52px;
      height: 52px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 23px;
   }
   &__author{
      color: #353c42;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin-top: 4px;
      margin-bottom: 11px;

      @include max-screen(992px){
         margin-top: 1px;
      }
   }
   &__place{
      color: #353c42;
      font-size: 14px;
      padding-left: 22px;
      position: relative;
      line-height: 18px;

      &::before{
         content: '\e93b';
         color: #8c959c;
         font-family: $icon-font;
         position: absolute;
         left: -2px;
         top: -1px;
         font-size: 15px;
      }
   }
   &__content{
      border-radius: 3px;
      background-color: #eef3f6;
      padding: 34px 30px;
      margin-top: 33px;
      position: relative;

      @include max-screen(992px){
         margin-top: 17px;
      }

      &::after{
         bottom: 100%;
         left: 50px;
         border: solid transparent;
         content: " ";
         height: 0;
         width: 0;
         position: absolute;
         pointer-events: none;
         border-color: rgba(136,183,213,0);
         border-bottom-color: #eef3f6;
         border-width: 13px;
         margin-left: -6px;
      }
   }
   &__message{
      margin: 0;
      color: #353c42;
      font-size: 16px;
      line-height: 24px;
   }
   &__info{
      display: flex;
      flex-direction: column;
   }
}