.profile-messages{
   padding-top: 42px;
   padding-bottom: 66px;

   @include max-screen(992px){
      padding-top: 34px;
   }

   &__grid-row{
      display: flex;
      margin-top: 22px; 
      border: 2px solid #fff;
      border-radius: 4px;
      @include max-screen(992px){
         margin-top: 34px;
      }
      @include max-screen(767px){
         flex-wrap: wrap;
      }
      @include min-screen(992px) {
         height: 80vh;
      }
   }
   &__dialogs-list{
      flex: 0 0 31.7%;
      max-width: 31.7%;
      max-height: 100vh;
      overflow-y: auto;

      @include max-screen(992px){
         max-width: 302px;
         flex: 0 0 302px;
      }
      @include max-screen(767px){
         max-width: 100%;
         flex: 0 0 100%;
         margin-bottom: 30px;
      }

   }
   &__messages{
      flex: 0 0 68.3%;
      max-width: 68.3%;
      border-left: 2px solid #fff;
      display: flex;
      flex-direction: column;
      @include max-screen(992px){
         flex-shrink: 1;
      }
      @include max-screen(767px){
         flex: 0 0 100%;
         max-width: 100%;
         border-left: 0;
      }
   }
   &__messages-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 55vh;
      padding: 30px;
      overflow-y: auto;
      @include max-screen(992px){
         max-height: 60vh;
      }
   }
   &__add-message{
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      max-height: 200px;
      @include max-screen(992px){
         max-height: 55px;
      }
   }
   &__wrap {
      position: relative;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
   }
   &__main-wrap {
      position: relative;
      min-height: 70vh;
   }
   &__empty {
      padding: 169px 0;
      text-align: center;
      background: url('../img/svg/anc-white-add-boat.svg') no-repeat center;
      background-size: contain;
      font-size: 16px;
      color: #383838;
   }
   &__warning{
      text-align: right;
      font-size: 14px;
   }
   &__reload{
      color: #848484;
      border-bottom: 1px dashed #848484;
      transition: border-bottom .3s;
      &:hover{
         text-decoration: none;
         color: #848484;
         border-bottom: 1px dashed #eef3f6;
      }
   }
}