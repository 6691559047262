.author {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 38px;
  border-bottom: 2px solid #fff;
  min-height: 210px;

  &__margin-bottom {
    margin-bottom: 40px;
  }

  &__without-border-bottom {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
    min-height: 164px;
  }

  &__bottom-border {
    overflow: hidden;
    color: #fff;
    font-family: "Open Sans";
    font-size: 48px;
    font-weight: bold;
    line-height: 48px;
  }
  &__bottom-border:before,
  &__bottom-border:after {
      content: '';

      display: inline-block;

      vertical-align: middle;

      box-sizing: border-box;

      width: 100%;
      height: 2px;
      background: #fff;

      border: solid #eef3f6;
      border-width: 0 23px;
  }
  &__bottom-border:before {
      margin-left: -100%;
  }
  &__bottom-border:after {
      margin-right: -100%;
  }

  &._small {
    margin-bottom: 15px;
    padding-bottom: 15px;
    
    .author__avatar {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    .author__header {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .author__name {
      font-size: 14px;
    }
  }

  &__avatar {
    width: 110px;
    height: 110px;
    margin-right: 30px;
    border-radius: 50%;

    @include max-screen(767px) {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }

  &__content {
    width: 100%;
    @include max-screen(767px) {
      display: flex;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;

    @include max-screen(767px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__name {
    color: #353c42;
    font-weight: bold;
    font-size: 18px;

    @include max-screen(767px) {
      max-width: 90px;
      font-size: 14px;
    }
  }

  &__info {
    @include max-screen(767px) {
      display: none;
    }
  }

  &__wrap {
    display: flex;
  }

  &__wrap_width {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    @include max-screen(576px) {
      flex-direction: column;
    }
  }

  &__float_right {
    float: right;
  }

  &__stat {
    font-size: 14px;
    @include max-screen(767px) {
      display: none;
    }
  }

  &__stat_all {
    font-size: 14px;
  }

  &__photo {
    font-size: 14px;
    min-width: 200px;
  }

  &__subscribe {
    margin-left: auto;
    @include max-screen(767px) {
      margin-left: 0;
    }
  }

  &__rating {
    @include max-screen(767px) {
      margin-left: 0;
    }
  }
}