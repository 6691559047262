.calendar{
   border-radius: 3px;
   background-color: #ffffff;

   .fc-header-toolbar {
      padding-top: 20px;
   }

   .fc-center {
      & h2 {
         color: #353c42;
         font-size: 18px;
         font-weight: 700;
         line-height: 18px;
         text-transform: capitalize;
      }
   }

   .fc-prev-button,
   .fc-next-button {
      border: 0;
      background: #fff;
      box-shadow: none;
      outline: none;
   }

   .fc-prev-button {
      &:first-child {
         margin-left: 20px;
      }
   }

   .fc-next-button {
      margin-right: 20px;
   }

   .fc-icon-left-single-arrow,
   .fc-icon-right-single-arrow {
      display: inline;
      height: auto;
      font-family: icomoon;
      speak: none;
      font-style: normal;
      font-weight: 300;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      color: #8c959c;
   }

   .fc-icon-left-single-arrow {
      &::after {
         font-weight: 300;
         font-size: 30px;
         content: "\e90f";
      }
   }

   .fc-icon-right-single-arrow {
      &::after {
         font-weight: 300;
         font-size: 30px;
         content: "\e910";
      }
   }

   .fc-widget-header {
      border: 1px solid #eef3f6;
      border-left: 0;
      border-right: 0;   
   }

   .fc-day-header {
      padding: 10px 0;
      font-weight: 400;
      color: #848484;
      font-size: 14px;
   }

   .fc-body {
      .fc-fc-widget-content {
         &:first-child {
            border-left: 0;
            border-right: 0;
         }
      }
   }

   .fc-day {
      border-color: #eef3f6;
   }

   .fc-day-number {
      padding-right: 10px;
   }
   

   &__mounts{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 82px;
      border-bottom: 2px solid #eef3f6;
      padding: 0 32px;

      @include max-screen(992px){
         height: 51px;
         padding: 0 20px;       
      }
   }
   &__mount{
      color: #353c42;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      margin: 0;

      @include max-screen(992px){
         font-size: 12px;
      }
   }
   &__switch-mount{
      color: #8c959c;
      font-size: 30px;

      &:hover{
         text-decoration: none;
         color: $base-color2;
      }

      @include max-screen(992px){
         font-size: 20px;
      }
   }
   &__week{
      justify-content: space-between;
      display: flex;
      padding: 9px 0px 14px;
      border-bottom: 2px solid #eef3f6;

      @include max-screen(992px){
         padding: 4px 0 4px;
      }
   }
   &__week-day{
      flex: 1;
      text-align: center;
      color: #848484;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;

      @include max-screen(992px){
         font-size: 9px;         
      }
      @include max-screen(576px){
         font-size: 6px;
      }
   }
   &__days{
      display: flex;
      flex-wrap: wrap;
   }
   &__day{
      border-bottom: 1px solid #eef3f6;
      border-right: 1px solid #eef3f6;
      width: 14.2%;
      height: 121px;
      color: #353c42;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      flex-direction: column;
      padding: 15px 0;

      @include max-screen(992px){
         padding: 7px 0;
         font-size: 10px;
         height: 78px;
      }
      @include max-screen(576px){
         font-size: 7px;
         padding: 0;
         height: 60px;
      }

      &:nth-child(7n){
         border-right: 0;
      }

      &._is-other-mount{
         color: #848484;
      }
      &._is-day-boat{
         .calendar__day-boat{
            background-image: url('../img/wave-calendar.png');
         }
      }
      &._is-day-boat-orange{
         .calendar__day-boat-orange{
            background-image: url('../img/wave-calendar2.png');
         }
      }
      &._is-last-day-boat{
         .calendar__day-boat{
            width: 99%;

            @include max-screen(768px){
               width: 98%;
            }

            &::after{
               content: '';
               width: 11px;
               height: 100%;
               background-color: #fff;
               display: block;
               position: absolute;
               top: 0;
               right: 0;
            }
         }
      }
      &._is-last-day-boat-orange{
         .calendar__day-boat-orange{
            width: 99%;

            @include max-screen(768px){
               width: 98%;
            }

            &::after{
               content: '';
               width: 11px;
               height: 100%;
               background-color: #fff;
               display: block;
               position: absolute;
               top: 0;
               right: 0;
            }
         }
      }
      &._is-first-day-boat{
         .calendar__day-boat{
            padding-left: 11px;

            &::before{
               content: '';
               width: 11px;
               height: 100%;
               background-color: #fff;
               display: block;
               position: absolute;
               top: 0;
               left: 0;
            }
         }
      }
      &._is-first-day-boat-orange{
         .calendar__day-boat-orange{
            padding-left: 11px;

            &::before{
               content: '';
               width: 11px;
               height: 100%;
               background-color: #fff;
               display: block;
               position: absolute;
               top: 0;
               left: 0;
            }
         }
      }
   }
   &__day-num{
      text-align: right;
      padding: 0px 16px;

      @include max-screen(992px){
         padding: 0 9px;
         margin-bottom: 6px;
      }
      @include max-screen(576px){
         margin-bottom: 0;
      }
      @include min-screen(992px){
         flex: 1;
      }
   }
   &__day-boat,
   &__day-boat-orange{
      flex: 1;
      background-position-x: -1px;
      position: relative;
      right: -1px;
      width: calc(100% + 1px);
      height: 30px;

      @include max-screen(992px){
         max-height: 20px;
         background-size: cover;
      }
      @include max-screen(576px){
         max-height: 16px;
      }
   }
   &__day-boat-img{
      @include max-screen(992px){
         height: 100%;
      }
      @include max-screen(768px){
         position: relative;
         top: -1px;
      }
   }
   &__wave {
      margin-bottom: 3px;
      padding-left: 0px!important;
      margin-left: 0px;
      height: 30px;
      border: 0;
      color: #fff;
      box-sizing: border-box;
      &._incoming {
         background: transparent url('../img/svg/wave-orange.svg') repeat-x center;
         background-size: contain;
      }
      &._outcoming {
         background: transparent url('../img/svg/wave-blue.svg') repeat-x center;
         background-size: contain;
      }
      &._external {
         background: transparent url('../img/svg/wave-gray.svg') repeat-x center;
         background-size: contain;
      }
   }
}