.cookie-policy {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px 15px 8px 15px;
  width: 100%;
  z-index: 101;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgba(#ff8400, .5);
  text-align: center;

  @include max-screen(768px) {
    right: 0;
    bottom: 0;
  } 

  &._hidden {
    display: none;
  }

  &__text {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(#000, .5);
    text-align: left;

    @include min-screen(1670px) {
      padding-top: 9px;
      margin-right: 5px;
    }
  }

  &__wrap {
    display: inline-block;
    vertical-align: top;
  }

  &__button {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    width: 155px;
    border: 1px solid #B4CDD3;
    font-size: 12px;
    line-height: 30px;
    color: #000;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
      text-decoration: none;
      color: #000;
    }

    &._accept {
      border: 1px solid #ff8400;
      background-color: #ff8400;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    @include max-screen(768px) {
      width: 80px;
      margin-right: 5px;
    }
  }

  &__close {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 2px solid rgba(#000, .3);
    border-radius: 50%;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      top: 50%;
      left: 50%;
      background-color: rgba(#000, .3);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}