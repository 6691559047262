.comment{
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: 23px;
   position: relative;

   &._answer{
      background-color: #ffffff;
      width: auto;
      padding: 30px 35px  22px 30px;
      border-radius: 3px;
      margin-bottom: 61px;

      @include max-screen(992px){
         margin-bottom: 38px;
      }
      @include max-screen(768px){
         padding: 18px 17px 22px 17px;
      }
      @include min-screen(768px){
         margin-left: 60px;
      }
      

      &:after {
         bottom: 100%;
         left: 50px;
         border: solid transparent;
         content: " ";
         height: 0;
         width: 0;
         position: absolute;
         pointer-events: none;
         border-color: rgba(136, 183, 213, 0);
         border-bottom-color: #ffffff;
         border-width: 13px;
         margin-left: -13px;
      }

      .comment__login{
         margin-right: 18px;
      }
   }
   &__top-row{
      display: flex;
   }
   &__bottom-row{
      margin-bottom: 10px;

      @include min-screen(768px){
         padding-left: 60px;
      }
   }
   &__avatar{
      width: 40px;
      height: 40px;
      margin-right: 19px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      @include max-screen(767px){
         margin-right: 17px;
      }
   }
   &__login{
      color: #383838;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin-right: 35px;
   }
   &__date{
      opacity: 0.5;
      color: #383838;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__info{
      margin-bottom: 10px;
      padding-top: 5px;
      &._edit {
         margin-left: auto;
      }
      @include max-screen(992px){
         padding-top: 7px;
      }
      @include max-screen(768px){
         margin-bottom: 19px;
      }
   }
   &__text{
      margin: 0;
      word-wrap: break-word;
   }
   &__attach{
      margin-top: 12px;
   }
   &__attach-item{
      width: 70px;
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 6px;
      outline: 0;
   }
}