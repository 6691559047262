.navigation{
   display: flex;
   justify-content: center;
   padding: 26px;
   margin-bottom: 55px;

   &__item{
      color: #383838;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 19px;
      margin-right: 9px;
      margin-left: 9px;

      &._is-active{
         width: 40px;
         height: 42px;
         border: 2px solid #26c3df;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 3px;
         margin-top: 7px;
      }
   }

   &._rentals{
      padding-top: 0;
      
      @include max-screen(992px){
         margin: 0;
      }
   }

   &._profile {
      margin-bottom: 0;
   }
}