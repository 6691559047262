.popular-directions{
   padding-top: 104px;
   padding-bottom: 30px;
 
   @include max-screen(576px){
     padding-top: 62px;
     margin-right: 0;
     margin-left: 0;
   }
   &__more-wrap{
     display: flex;
     justify-content: center;
   }
   &__more{
     padding: 0px 27px;
   }
   &__item{
     margin-bottom: 53px;
     @include max-screen(576px){
       margin-bottom: 35px;
     }
     &:nth-child(n+3){
       @include max-screen(576px){
         display: none;
       }
     }
     @include screen(576px, 768px){
      display: flex;
      flex-direction: column;
     }
   }
   &__text{
    @include screen(576px, 768px){
      max-width: 370px
     }
   }
   &__poster{
     @include max-screen(576px){
       margin-bottom: 19px;
     }
   }
 }