.boad-filter{
   color: #ffffff;
   font-family: "Open Sans";
   font-weight: 400;
   width: 100%;
   text-align: center;
   margin-bottom: 30px;
   position: relative;
   @include max-screen(992px){
     margin-bottom: 22px;
   }
   @include max-screen(576px){
     margin-bottom: 0;
   }
   @include screen(420px, 767px){
      display: flex;
      flex-direction: column;
      align-items: center;
   }
 
   &__item-container{
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     background-image: url('../img/blur-bg.png');

     &._hidden {
        @include max-screen(767px){
           display: none;
        }
     }

     @include min-screen(768px){
       flex-wrap: nowrap;
     }
     @include max-screen(992px){
       max-width: 739px;
       margin-left: auto;
       margin-right: auto
     }
     @include max-screen(767px){
       display: none;
       position: absolute;
       max-width: 390px;
       z-index: 100;
       background-image: url('../img/boad-filter-mobile-bg.png');
     }
     @include max-screen(767px){
       background-size: cover;
     }
     @include screen(420px, 767px){
       top: 66px;
     }
     
   }

   &__checkbox {
     display: none;
     &:checked + .boad-filter__item {
      text-decoration: none;
      border-color: #fff;
      outline: 1px solid #fff;
      z-index: 100;

      .boad-filter__item-title{
        opacity: 1;
      }
     }
   }

   &__item{
     width: 130px;
     height: 130px;
     flex-shrink: 0;
     border: 1px solid #61cdfd;  
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     text-decoration: none;
     position: relative;
     padding-top: 7px;
     cursor: pointer;
     @include max-screen(1200px){
       flex-shrink: 1;
     }
     @include max-screen(992px){
       width: 82px;
       height: 82px;
     }
     @include max-screen(576px){
       width: 100px;
       height: 90px;
     }
     @include screen(320px, 420px){
       width: 33.3%;
     }
     @include screen(420px, 767px){
      width: 130px;
      height: 120px;
     }
     &:hover{
       text-decoration: none;
       border-color: #fff;
       outline: 1px solid #fff;
       z-index: 100;
 
       .boad-filter__item-title{
         opacity: 1;
       }
     }
     &::before{
       content: '';
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       -webkit-filter: blur(8px);
       -moz-filter: blur(8px);
       filter: blur(8px);
       background: transparent;
     }

     &._is-active{
       background-color: #fff;
       border-color: #fff;
       outline: 1px solid #fff;

      @include min-screen(768px){
        z-index: 100;
      }

       .boad-filter__item-title{
         color: #353c42;
         opacity: 1;
       }
       [class^="icon-"]::before{
         color: #00c5dd;
       }
     }
   }
   &__item-poster{
     margin-bottom: 10px;
     @include max-screen(992px){
       margin-bottom: 2px;
     }
 
     [class^="icon-"]::before{
       color: #fff;
       font-size: 40px;
       @include max-screen(992px){
         font-size: 26px;
       }
     }
   }
   &__item-title{
     color: #ffffff;
     font-family: "Open Sans";
     font-weight: 400;
     opacity: 0.7;
     transition: opacity .3s;
     @include max-screen(992px){
       font-size: 10px;
     }
   }
   &__title{
     color: #ffffff;
     font-family: "Open Sans";
     font-weight: 400;
     margin-bottom: 14px;
     display: inline-block;
     &.on{
       .boad-filter__icon{
         transform: rotate(180deg);
       }
     }
     @include max-screen(767px){
       border: 1px solid #61cdfd;
       width: 100%;
       display: flex;
       height: 54px;
       align-items: center;
       justify-content: space-between;
       padding: 0 20px;  
       margin-bottom: 12px;  
     }
     @include screen(420px, 767px){
      max-width: 390px;
     }
   }
   &__icon{
    color: #fff;
    transition: transform .3s;
    @include min-screen(768px){
      display: none;
    }
  }
}