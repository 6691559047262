.social-btn{
   &__item{
     font-size: 28px;
     display: inline-block;
     margin-right: 7px;
     line-height: 100%;

     &:hover{
       text-decoration: none;
     }
   }
 }