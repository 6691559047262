.main-capitans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include max-screen(576px){
    justify-content: flex-start;
  }

  &__boat-name {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 10px 5px 10px;
    font-family: "Open Sans";
    font-size: 12px;
  }

    &__boat-img{
      position: relative;
      display: block;
      height: 65px;
      width: 98px;
      background-size: cover;

      &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom,transparent 60%,#000 110%);
      }
  }
}