.help{
   padding-top: 44px;
   padding-bottom: 78px;
   min-height: 80vh;

   @include max-screen(992px){
      padding-top: 34px;
   }

   &__inner-title{
      line-height: 34px;
   }

   &__nav{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include max-screen(767px){
         display: none;
         border-radius: 3px;
         border: 1px solid $base-color2;
         background-color: #ffffff;
         padding: 25px 20px 10px 20px;
         margin-bottom: 10px;
      }
   }
   &__nav-item{
      color: #848484;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 20px;
      &._active {
         font-weight: bold;
      }
   }
   &__nav-icon{
      color: #919191;
      font-size: 14px;
      position: relative;
      transition: transform .3s;
      @include min-screen(768px){
         display: none;
      }
   }
   &__nav-item-title{
      color: #383838;
      line-height: 14px;
      margin-top: -1px;
      display: block;
      margin-bottom: 11px;
      @include max-screen(992px){
         margin-bottom: 12px;
      }
      @include max-screen(767px){
         font-size: 16px;
         height: 54px;
         border: 1px solid #b8ccd2;
         line-height: 54px;
         border-radius: 3px;
         padding-left: 22px;
         padding-right: 18px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 22px;
      }

      &._on{
         .add-boat__nav-icon{
            transform: rotate(180deg);
         }
      }
   }
   &__content{
      @include screen(768px, 992px){
         padding-left: 16px;
      }
   }
   &__reply-title{
      color: #919191;
      font-size: 14px;
      line-height: 100%;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 10px;

      @include max-screen(576px){
         margin-bottom: 14px;
         line-height: 110%;
      }
   }
   &__reply-link{
      color: #383838;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 10px;
   }
   &__reply-content{
      padding: 38px 51px 27px;
      background-color: #fff;
      margin-top: 2px;
      border-radius: 3px;
      @include max-screen(992px){
         padding-bottom: 6px;
         padding-left: 21px;
         padding-right: 16px;
      }
   }
   &__content-row{
      display: flex;
      justify-content: space-between;

      @include max-screen(992px){
         flex-direction: column;
      }
      @include min-screen(992px){
         margin-bottom: 39px;
      }
   }
   &__content-box{
      display: flex;
      flex-direction: column;
      @include min-screen(992px){
         width: 50%;
         max-width: 347px;
      }
      @include max-screen(992px){
         margin-bottom: 39px;
      }
      @include max-screen(576px){
         margin-bottom: 34px;
      }
   }
   &__mobile-menu-btn{
      font-size: 16px;
      height: 54px;
      border: 1px solid #b8ccd2;
      line-height: 54px;
      border-radius: 3px;
      padding-left: 22px;
      padding-right: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;
      color: #383838;

      @include min-screen(768px){
         display: none;
      }
      @include max-screen(767px){
         margin-bottom: 10px;
      }

      &._on{
         .help__nav-icon{
            transform: rotate(180deg);
         }
      }
   }
   &__nav-icon{
      color: #919191;
      font-size: 14px;
      position: relative;
      transition: transform .3s;
      @include min-screen(768px){
         display: none;
      }
   }
}