.payment-details {
  display: flex;
  padding-bottom: 50px;
  margin-left: auto;

  @include screen(768px, 992px) {
    margin: 0 20px;
  }

  @include max-screen(1100px) {
    flex-wrap: wrap;
  }

  &__wrap {
    margin-bottom: 30px;

    @include max-screen(767px) {
      padding: 15px;
    }
  }

  &__title {
    margin-bottom: 28px;
    font-size: 18px;
    font-weight: bold;
  }

  &__notice {
    position: relative;
    padding-left: 28px;
    margin-bottom: 16px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      background: url('../img/svg/verif.svg') no-repeat center;
    }
  }

  &__cash_question {
    font-size: 16px;
    font-weight: 600;
  }

  &__saved {
    display: inline-block;
    height: 54px;
    line-height: 54px;
    margin-bottom: 16px;
    margin-left: 16px;
    font-size: 16px;
    color: #26c3df;
    &._error {
      color: red;
    }
  }

  &__person-links {
    padding: 0 0 0 28px;
    margin-bottom: 16px;
  }

  &__person-link {
    color: #353c42;
    font-size: 14px;
    text-decoration: underline;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @include max-screen(768px) {
      flex-direction: column;
    }
  }

  &__col {
    @include min-screen(767px) {

      margin-left: 5px;
      margin-right: 5px;

      &:first-child:not(:last-child) {
        margin-left: 0;
      }
      &:first-child:last-child {
        margin-left: 0;
        margin-right: 0;
      }
      &:last-child:not(:first-child) {
        margin-right: 0;
      }
    }
  }

  &__input {
    height: 54px;
    line-height: 54px;
    width: 100%;
    border: 1px solid #b8ccd2;
    color: #848484;
    font-size: 16px;
    font-weight: 400;
    padding: 0 20px;
    outline: 0;
    margin-bottom: 10px;
    border-radius: 2px;

    @include max-screen(992px) {
      padding: 10px;
    }

    &._error {
      border: 1px solid red;
    }

  }

  &__error {
    margin-bottom: 20px;
    font-size: 14px;
    color: red;
  }

  &__modal-title {
    font-size: 1.4em;
  }
}

.vue-tooltip.payment-details__error-tooltip {
  background-color: red;
}

.vue-tooltip.payment-details__error-tooltip .tooltip-arrow {
  border-color: red;
}