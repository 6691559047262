.my-setting{
   padding-top: 43px;
   padding-bottom: 76px;
   
   &__title{
      margin-bottom: 20px;
   }
   &__password{
      margin-bottom: 20px;
   }
   &__reset{
      display: none;
      min-width: 100%;
   }
   &__content {
      display: flex;
      @include max-screen(767px) {
         flex-direction: column-reverse;
         flex-wrap: wrap;
      }
   }
}