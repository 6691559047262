.main-blogs{
   padding: 108px 0 130px;
   background-color: #eef3f6;

  &._profile{
    padding-top: 63px;
    padding-bottom: 90px;
  }

   @include max-screen(992px){
     padding: 63px 0 68px;
   }
   @include max-screen(576px){
     padding-bottom: 70px;
   }
   &__title{
     margin-bottom: 44px;
     @include max-screen(992px){
       margin-bottom: 34px;
     }
   }
   &__more-wrap{
     display: flex;
     justify-content: center;
     padding-top: 32px; 
     @include max-screen(992px){
       padding-top: 15px;
     } 
     @include max-screen(576px){
       padding-top: 29px;
     }
   }
   &__boat-container{
     @include max-screen(576px){
       margin-left: -10px;
       margin-right: -10px;
     }
   }
 }