.tooltip{
   position: absolute;
   border-radius: 3px;
   background-color: #000;
   background-color: hsla(0, 0%, 20%, 0.9);
   color: #fff;
	padding: 15px;
   font-size: 14px;
   line-height: 18px;
	z-index: 400;
   display: none;

   @include max-screen(576px){
      padding: 7px;
      font-size: 10px;
      line-height: 14px;
   }
   &::after{
      border-top: 10px solid hsla(0, 0%, 20%, 0.9);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -10px;
      width: 0;
   }

   &._float-left{
      &::after{
         left: 17%;
      }
   }
   &._float-right{
      &::after{
         left: 81%;
      }
   }
}