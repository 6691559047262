.dialogs{
   &__item{
      display: flex;
      position: relative;
      align-items: center;
      height: 101px;
      border-radius: 3px;
      background-color: #ffffff;
      width: 100%;
      padding-left: 25px;
      padding-right: 21px;
      margin-bottom: 1px;
      cursor: pointer;

      &._is-active{
         background-color: #ff8400;

         .dialogs{
            &__user-name,
            &__text,
            &__date{
               color: #ffffff;
            }
            
         }
      }

      @include max-screen(992px){
         padding-left: 16px;
         padding-right: 16px;
      }

      &:hover {
         .dialogs__date {
            display: none;
         }
         .dialogs__delete {
            display: block;
         }
      }
   }
   &__content{
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
   }
   &__delete {
      display: none;
      position: absolute;
      right: 25px;
      top: 12px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      &::before, &::after {
         content: '';
         position: absolute;
         top: 50%;
         left: 50%;
         width: 20px;
         height: 2px;
         background-color: #383838;
      }
      &::before {
         transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
         transform: translate(-50%, -50%) rotate(-45deg);
      }
   }
   &__avatar{
      width: 53px;
      height: 53px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
   }
   &__user-name{
      color: #383838;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
   }
   &__date{
      opacity: 0.5;
      color: #383838;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__text{
      color: #383838;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 3px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 260px;
   }
   &__top-row{
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
   }
   &__spinner {
      position: relative;
      height: 100px;
      width: 100%;
   }
   &__wrap {
      height: 80vh;
   }
}