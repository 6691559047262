.footer-nav{
   display: flex;
   justify-content: space-around;
   @include max-screen(992px){
     flex-wrap: wrap;
   }
   @include min-screen(1140px){
     padding-left: 47px;
   }
 
   &__column{
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     
     @include max-screen(992px){
       width: 50%;
       margin-bottom: 27px;
     }
   }
   &__title{
     color: #565e66;
     font-weight: 700;
     line-height: 24px;
     margin-bottom: 20px;
     @include max-screen(576px){
       margin-bottom: 16px;
     }
   }
   &__item{
     color: #99a4ae;
     font-size: 14px;
     font-weight: 400;
     line-height: 26px;
   }
 }