.btn{
	height: 40px;
	line-height: 40px;
	border-radius: 3px;
	color: #ffffff;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	padding: 0 15px;
	border: 0;
	outline: 0;
	cursor: pointer;
	display: inline-block;
	transition: background .3s;
 
	&:hover{
		text-decoration: none;
		color: #ffffff;
	}

	&:disabled {
		background-color: #dadada;
		cursor: default;
	}

	&._delete{
		margin-left: auto;
	}

	&._confirm-popup {
		min-width: 200px;
		margin-bottom: 5px;
	}
 
	&._orange{
	  background-color: $base-color;
	  &:hover{
		 background-color: #ee6a00;  
	  }
	}

	&._disabled {
		background-color: #dadada;
	}

	&._blue{
	  background-color: $base-color2;
	  &:hover{
		 background-color: #09b6d5;  
		}
		&:disabled {
			background-color: #dadada;
		}
	}
	&._big{
	  height: 54px;
	  line-height: 54px;
	  font-size: 16px;
	  font-weight: 400;
	  padding: 0 32px;
	}
	&._medium{
		font-size: 16px;
		height: 52px;
		line-height: 52px;
		padding: 0 28px;
	}
	&._full{
		width: 100%;
		text-align: center;
	}
	&._money-header{
		padding: 0 45px;
		height: 63px;
		line-height: 63px;
		font-size: 16px;
	}
	&._transparent{
	  color: #383838;
	  border: 2px solid #26c3df;
	  &:hover{
		 border-color: #5fd9ef;  
	  }
	}
	&._transparent-delete{
	  color: $base-color;
	  border: 2px solid #26c3df;
	  &:hover{
		 border-color: #5fd9ef;
	  }
	}
	&._transparent-red{
	  color: #e4432e;
	  border: 2px solid #e4432e;
	  &:hover{
		 border-color: #ef4f2e;
	  }
	}
	&._full-text{
		height: auto;
		line-height: inherit;
	}
	&._transborder{
	  border: 2px solid transparent;
	}
	&._call-owner{
		padding: 0 27px;
		line-height: 50px;

		@include max-screen(1140px){
			padding: 0 20px;
		}
		@include screen(992px, 1200px){
			display: flex;
			white-space: nowrap;
		}
		&:first-child{
			margin-right: 6px;
			@include max-screen(576px){
				padding: 0 22px;
			}
			@include max-screen(335px){
				padding: 0 16px;
			}
		}
		&:last-child{
			@include max-screen(576px){
				padding: 0 20px;
			}
		}

	}
	&._add-boat{
		padding: 0 30px;
	}
	&._add-comments-submit{
		padding: 0 36px;
	}
	&._popular-directions{
		padding: 0px 28px;
	}
	&._search-submit{
		padding: 0 43px;

		@include max-screen(992px){
			padding: 0 32px;
		}
		@include max-screen(576px){
			padding: 0 17px;
			font-size: 18px;
		}
	}
	&._add-event{
		font-size: 16px;
		padding: 0 18px;
		height: 54px;
		line-height: 54px;
		margin-top: 4px;

		@include screen(576px, 992px){
			position: relative;
			top: -15px;
		}
		@include max-screen(576px){
			margin-top: 33px;
			height: 54px;
			line-height: 54px;
		}
	}
	&._add-message{
		padding: 0 14px;
		font-size: 27px;
	}
	&._add-message-foto{
		border: 0;
		height: 54px;
		line-height: 54px;
		padding: 0 6px;

		@include max-screen(576px){
			padding: 0 9px;
		}
	}
	&._send-rentals{
		padding: 0 42px;
		@include screen(1090px, 1200px){
			padding: 0 20px;
		}	
	}
	&._rental-call{
		height: 54px;
		line-height: 54px;	
		padding: 0 17px;	
		font-size: 16px;
		flex: 1;
		margin-right: 0;
		text-align: center;

		@include max-screen(576px){
			margin-bottom: 5px;
		}
		@include min-screen(576px){
			&:first-child{
				margin-right: 8px;
			}
		}
	}
	&._call {
		@include min-screen(1100px) {
			display: none;
		}
	}
	&._write-blog{
		padding: 0 22px;
	}
	&._for-big-desktop{
		@include max-screen(768px){
			display: none;
		}
	}
	&._form-submit{
		padding: 0 57px;
	}
	&._form-delete{
		padding: 0 32px;
	}
}