.profile-calendar{
   padding-top: 43px;
   padding-bottom: 81px;

   @include max-screen(992px){
      padding-top: 36px;      
   }

   &__header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 21px;

      @include max-screen(992px){
         margin-bottom: 6px;
      }
      @include max-screen(576px){
         flex-direction: column;
         align-items: flex-start;
         margin-bottom: 30px;
      }
   }
   &__add-event-icon{
      font-size: 18px;
      margin-right: 8px;
      vertical-align: middle;
      line-height: 100%;
   }
}