.message{
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: 1px;
   position: relative;
   padding-bottom: 22px;
   padding-top: 33px;

   @include max-screen(576px){
      padding-top: 20px;
      padding-bottom: 12px;
   }

   &__top-row{
      display: flex;

      @include max-screen(576px){
         margin-bottom: 15px;         
      }
   }
   &__bottom-row{
      margin-bottom: 10px;

      @include min-screen(768px){
         padding-left: 57px;
      }
   }
   &__avatar{
      width: 40px;
      height: 40px;
      margin-right: 19px;
      flex-shrink: 0;
      @include max-screen(767px){
         margin-right: 17px;
      }
   }
   &__login{
      color: #383838;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin-right: 35px;
   }
   &__date{
      opacity: 0.5;
      color: #383838;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__info{
      margin-bottom: 10px;
      padding-top: 5px;
      @include max-screen(992px){
         padding-top: 2px;
      }
      @include max-screen(576px){
         display: flex;
         padding-top: 11px;
         justify-content: space-between;
      }
   }
   &__text{
      margin: 0;
   }
   &__attach{
      margin-top: 12px;
   }
   &__attach-item{
      width: 70px;
      display: inline-block;
      outline: 0;
   }
}