.boat-slider{
   position: relative;

   @include max-screen(992px){
      margin-left: -10px;
      margin-right: -10px;
   }

   &__slider {
      min-height: 600px;
      @include max-screen(992px) {
         max-height: 50vh;
         min-height: 50vh;
      }
   }

   &__slide{
      position: relative;
      height: 600px;
      max-height: 600px!important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: rgba(#8d969d, .1);
      @include max-screen(1000px) {
         max-height: 50vh !important;
      }
   }
   &__slide-title{
      position: absolute;
      top: 34px;
      left: 40px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      max-width: 295px;
      @include max-screen(992px){
         font-size: 14px;
         top: 24px;
         line-height: 20px;         
      }
      @include max-screen(576px){
         font-size: 12px;
         line-height: 16px;
         top: 14px;
         left: 15px;
      }
   }
   &__slider-control{
      position: absolute;
      height: 100%;
      top: 0;
      display: flex;
      width: 77px;
      z-index: 100;
      align-items: center;
      opacity: .7;
      transition: opacity .3s;
      cursor: pointer;

      @include max-screen(992px){
         width: 47px;
         &::before{
            font-size: 40px;
         }      
      }
      @include max-screen(576px){
         width: 40px;
      }

      &:hover{
         opacity: 1;
      }
      &::before{
         position: relative;
         font-family: $icon-font;
         color: #fff;
         font-size: 47px;
         
         @include max-screen(576px){
               font-size: 24px;
         }
      }
      &._prev{
         left: 0;
         justify-content: flex-end;
         &::before{
            content: "\e90a";
         }
      }
      &._next{
         right: 0;
         justify-content: flex-start;
         &::before{
            content: "\e90b";
         }
      }
   }
   &__preview{
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;
      bottom: 30px;
      z-index: 100;
      @include max-screen(992px){
         bottom: 22px;
      }
      @include max-screen(576px){
         bottom: 10px;
      }
   }
   &__preview-slide{
      display: flex;
      align-items: center;
      width: 100px;
      max-height: 67px;
      margin-right: 10px;
      border: 1px solid transparent;
      background-color: rgba(#000, .3);
      cursor: pointer;
      @include max-screen(992px){
         width: 70px;
      }

      &:last-child{
         margin-right: 0;
      }
      &._is-active{
         border-color: #fff;
      }
      &._hide-table{
         @include max-screen(992px){
            display: none;
         }
      }
      &._add-slide{
         background-color: rgba(53, 60, 66, 0.65);
         display: flex;
         align-items: center;
         justify-content: center;
         color: #fff;
         font-size: 37px;
         transition: background-color .3s;
         text-decoration: none;

         &:hover{
            background-color: rgba(53, 60, 66, 0.9);
         }
      }
   }
}
