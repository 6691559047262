.topline{
     height: 50px;
     display: flex;
     align-items: center;
     position: relative;
     z-index: 200;

     &._content{
      height: 65px;
      box-sizing: border-box;
      @include max-screen(992px){
        height: 54px;
      }
     }
   
     @include min-screen(992px){
       justify-content: space-between;
     }
   
     &__content-wrap{
      display: flex;
      align-items: center;
     }
     &__logo{
      flex-shrink: 0;
     }
     &__logo-img{
         width: 150px;
         @include max-screen(992px){
           width: 120px;
         }
     }
     &__nav-container{
       display: flex;
       align-items: center;
       @include max-screen(992px){
         display: none;
       }
     }
     &__switch-language{
       margin-left: 20px;
       color: #ffffff;
       font-size: 14px;
       font-weight: 400;;
       text-transform: uppercase;
       
       &:hover{
        color: #ffffff;
       }

       &._content{
        color: #353c42;
        line-height: 18px;

        &:hover{
          color: $base-color;
        }
        @include max-screen (992px){
          display: none;
         }
       }
       &._make-money{
         margin-left: 0;

         &:hover{
           color: $base-color;
         }
       }
     }
     &__addboat-icon{
         margin-right: 7px;
         font-size: 18px;
         vertical-align: middle;
     }
     &__mobile-menu-btn{
       display: none;
       width: 20px;
       height: 14px;
       z-index: 700;
       position: relative;
       margin-right: 16px;
       top: 4px;
       transition: opacity .3s;
   
       &._on{
         opacity: 0;
       }
       &._is-opened .topline__mobile-menu-bars {
         background-color: transparent;
       }
       &._is-opened .topline__mobile-menu-bars:before {
         transform: rotate(45deg) translate(-2px, -1px);
         background-color: $base-color2;
       }
       &._is-opened .topline__mobile-menu-bars:after {
         transform: rotate(-45deg) translate(5px,-6px);
         background-color: $base-color2;
       }
       &._hidden-btn{
        margin: 0;
        margin-left: 5px;
       }
       &._topline-btn{

       }
       @include max-screen(992px){
         display: block;
       }
       @include max-screen(576px){
          margin-right: 20px;
        }
     }
     &__mobile-menu-bars{
        &, 
        &::after, 
        &::before {
          width: 100%;
          height: 2px;
          background-color: #fff;
          transition: transform 0.3s;
          backface-visibility: hidden;
          border-radius: 1px;
          display: block;
        }
        &::after, 
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 6px;
        }
        &::after {
          top: 12px;
        }

        &._gray{
          &, 
          &::after, 
          &::before{
            background-color: #8c959c;
          }
        }
     }
     &__notify{
        display: flex;
        height: 100%;
        align-items: center;
        @include max-screen(992px){
          position: absolute;
          right: 15px;
        }
        @include max-screen(767px){
          position: absolute;
          right: 11px;
        }
     }
     &__notify-item{
      padding-top: 5px;
      margin-right: 21px;
      position: relative;
      
      @include max-screen(992px){
        margin-right: 23px;
      }

      &._is-active{
        .topline__notify-icon::after{
          display: block;
        }
      }
      
      &:last-child{
        margin-right: 0;
      }
     }
     &__notify-icon{
      font-size: 20px;
      position: relative;
      color: #8c959c;
      cursor: pointer;

      &::after{
        content: '';
        width: 12px;
        height: 12px;
        border: 2px solid #ffffff;
        background-color: #ff8400;
        position: absolute;
        border-radius: 50%;
        display: none;
      }
     }
     &__notify-notice{
      .topline__notify-icon::after{
        top: -3px;
        right: -1px;
      }
     }
     &__notify-message{
      .topline__notify-icon::after{
        top: -3px;
        right: -4px;    
      }
     }
     &__avatar{;
      display: inline-block;
      margin-right: 0px;
      flex-shrink: 0;
     }
     &__avatar-img{
       width: 40px;
       height: 40px;
       border-radius: 50%;

       &._notification {
          margin-right: 5px;
       }
     }
     &__help-link{
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-right: 26px;
      @include max-screen(1200px){
        margin-right: 15px;
      }
      @include max-screen (992px){
        display: none;
       }
     }
     &__addboat{
      flex-shrink: 0;
      @include max-screen (992px){
        display: none;
       }
     }
     &__addboat-main{
       flex-shrink: 0;
     }
     &__search-icon{
       display: none;
       text-decoration: none;
       &:hover {
          text-decoration: none;
       }
       @include max-screen(992px){
         display: block;
       }
     }
     &__content-container{
       @include max-screen(576px){
        width: 100%;
       }
     }
     &__no-reg-nav{
      display: inline-flex;

       @include max-screen(992px){
        position: absolute;
        right: 0;
       }
     }
     &__no-reg-nav-link{
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-right: 26px;

      @include screen(992px, 1100px){
        margin-right: 12px;
      }
      @include max-screen(768px){
        display: none;
      }
     }
   }

   .topline-nav{
     &__item{
       margin-right: 23px;
       color: #ffffff;
       font-family: "Open Sans";
       font-size: 14px;
       font-weight: 400;

       &:hover{
        color: #ffffff;
       }

       &._make{
        &:hover{
          color: $base-color;
         }
       }
     }
   }

   .top-link{
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;   
      margin-right: 7px;
   }
.topline__notify > .dropdown {
  position: relative;
  display: inline-block;
  min-width: 80px;
  
  @include max-screen(767px) {
      display: none;
  }
}

.dropdown-content {
    top: 64px;
    right: -20px;
    
    border: 2px solid #ECECEC;
    background-color: white;
    display: none;
    position: absolute;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-title{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 0px 10px 0px 1px;
    margin: 12px 0px 17px;
    
    @include max-screen(767px) {
	display: none;
    }
}
.dropdown-content a{
    color: #353c42;
    font-size: 14px;
    display: block;
    
    @include max-screen(767px) {
	display: none;
    }
}
.nav-links{
    display: flex;
    padding: 0px 5px;
    
    @include max-screen(767px) {
	display: none;
    }
}
.topline__content-container{
     @include max-screen(992px){
         width: 100%;
     }
}
