.popular-boat{
   background-color: #eef3f6;
   padding-top: 105px;
   padding-bottom: 130px;
   @include max-screen(992px){
     padding-top: 63px;
     padding-bottom: 70px;    
   }
   @include max-screen(576px){
     padding-bottom: 60px;
   }

   &._make-money{
    padding-bottom: 132px;

    @include max-screen(992px){
      padding-top: 54px;
      padding-bottom: 50px;
    }
    @include max-screen(576px){
      padding-top: 43px;
    }
   }
   &._profile{
     background-color: #fff;
     padding-top: 61px;
     padding-bottom: 22px;
   }

   &__item{
     &:nth-child(3){
       @include screen(576px, 992px){
         display: none;
       }
     }
     @include screen(576px, 768px){
      display: flex;
      flex-direction: column;
     }
   }
 
   &__more-wrap{
     display: flex;
     justify-content: center;
     margin-top: 0;
   }
 }