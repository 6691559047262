.blog {
  margin-top: 60px;
  margin-bottom: 100px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    &._list {
      align-items: flex-start;

      @include max-screen(767px) {
        flex-direction: column-reverse;
      }
    }
  }

  &__best-wrap {
    display: flex;
    align-items: center;

    &._small {
      margin-bottom: 35px;

      .blog__best {
        margin-right: 5px;
        font-size: 14px;
      }

      .publications__menu {
        padding: 0 10px;
      }

      .profile__tab-elem {
        margin-right: 10px;

        &::after {
          bottom: -10px;
        }
      }
    }
  }

  &__best {
    margin-right: 40px;
    color: #383838;
    font-weight: bold;
    font-size: 24px;

    @include max-screen(767px) {
      margin-right: 25px;
      font-size: 18px;
    } 
  }

  &__search {
    width: 670px;

    @include max-screen(1100px) {
      width: 45%;
    }

    @include max-screen(767px) {
      width: 100%;
    }

    &._sidebar {
      width: 100%;
      margin-bottom: 20px;
    }

    &._list {
      @include screen(768px, 1100px) {
        width: 35%;
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__sidebar {
    min-width: 300px;
    max-width: 300px;
    margin-left: 30px;

    @include max-screen(1100px) {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
    }

    @include screen(768px, 1100px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between
    }
  }

  &__sidebar-block {
    margin-bottom: 10px;
    padding: 40px 27px 40px 30px;
    border-radius: 5px;
    background-color: #fff;

    &._stripped {
      background-color: transparent;
    }

    &._half {
      @include screen(768px, 1100px) {
        width: 49%;
      }
    }

    @include screen(768px, 1100px) {
      width: 100%;
    }

    @include max-screen(767px) {
      padding: 20px;
    }
  }

  &__sidebar-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #353c42;
  }

  &__sidebar-text {
    margin-bottom: 20px;
    font-size: 14px;
    color: #383838;
  }

  &__subscribe-input {
    height: 40px;
    width: 100%;
    padding-left: 20px;
    background-color: #eef3f6;
    border-radius: 5px;
    border: 0;
    outline: none;
    color: #848484;
    font-size: 14px;
  }

  &__sidebar-social-wrap {
    @include screen(768px, 1100px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__sidebar-social {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__social-icon {
    margin-right: 20px;
  }

  &__social-stat {
    font-size: 18px;
    color: #353c42;
    font-weight: bold;
  }

  &__subgroup {
    font-size: 12px;
    color: #8c959c;
    font-weight: normal;
  }

  &__show-all-link {
    font-size: 14px;
    font-weight: normal;
    color: #848484;
    border-bottom: 1px dashed #848484;
    line-height: 17px;

    &:hover {
      border-bottom: 0;
      text-decoration: none;
    }

    &._inline {
      display: inline-block;
      margin-left: 20px;
    }
  }

  &__menu-link {
    position: relative;
    display: inline-block;
    margin-right: 37px;
    font-size: 24px;
    font-weight: bold;
    color: #848484;
    transition: color .2s ease;

    &:hover {
      text-decoration: none;
      color: #383838;
    }

    &._active {
      color: #383838;
    }

    @include max-screen(767px) {
      margin-right: 11px;
      font-size: 18px;
    }
  }

  &__publication {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__publication-link {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #353c42;
  }

  &__title {
    color: #383838;
    font-size: 48px;
    font-weight: normal;

    @include max-screen(767px) {
      font-size: 24px;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &__author-avatar {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @include max-screen(767px) {
      width: 40px;
      height: 40px;
    }
  }

  &__author-name {
    font-size: 14px;
    font-weight: bold;
    color: #353c42;

    &:hover {
	color: #353c42;
    }

    &._active {
	color: #353c42;
    }
  }

  &__author-date {
    display: inline-block;
    margin-left: 35px;
    margin-right: 50px;
    color: #919191;
    font-weight: normal;

    @include max-screen(767px) {
      display: block;
      margin-left: 0;
      margin-right: 20px;
      font-size: 12px;
    }
  }

  &__post-text {
    margin-bottom: 40px;
    font-size: 14px;
    color: #383838;
  }

  &__post-image-wrap {
    margin-bottom: 40px;
  }

  &__post-image {
    width: 100%;
    margin-bottom: 20px;
  }

  &__post-image-caption {
    padding-left: 30px;
    font-size: 14px;
    color: #8c959c;
  }

  &__author-answers {
    margin-top: 15px;
    font-size: 12px;
    color: #848484;
    line-height: 16px;
  }

  &__author-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__author-controls {
    text-align: center;
  }

  &__aboard {
    font-size: 12px;
    color: #848484;
  }

  &__recommended-boats {
      display: flex;
      margin-left: auto;
  }

  &__boat-small {
    width: 135px;
    margin: 0 5px;
    text-align: center;
    
    @include max-screen(767px) {
	display: none;
    }
  }

  &__boat-image {
    display: block;
    width: 100%;
    height: 85px;
    background-size: cover;
    background-position: center;
  }

  &__boat-desc {
    padding: 10px;
    background-color: #fff;
  }

  &__boat-name {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #353c42;
  }

  &__other-title {
    margin-top: 80px;
    margin-bottom: 30px;
    font-size: 36px;
    color: #383838;

    @include max-screen(767px) {
      margin-top: 60px;
      font-size: 24px;
    }
  }

  &__other {
    display: flex;
    flex-wrap: wrap;
  }

  &__other-elem {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 31%;
    height: 145px;
    padding: 15px;
    margin-right: 29px;
    margin-bottom: 30px;
    background-size: cover;
    background-position: center;
    color: #fff;
    font-weight: bold;
    font-size: 14px;

    &:nth-child(3n+3) {
      margin-right: 0;
    }

    @include max-screen(767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__social {
    @include max-screen(767px) {
      margin-top: 15px;
    }
  }

  &__profile-captain {
    @include max-screen(767px) {
      display: none;
    }
  }
}
