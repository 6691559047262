.short-blog{
   margin-bottom: 27px;
   @include max-screen(576px){
     margin-bottom: 10px;
   }

   &__header{
      position: absolute;
      left: 45px;
      top: 23px;
      padding-right: 13px;
      @include max-screen(992px){
         bottom: 18px;
      }
      @include max-screen(576px){
         bottom: 26px;
         max-width: 245px;
      }
   }
 
   &__content{
      position: absolute;
      left: 45px;
      bottom: 23px;
      padding-right: 13px;
      @include max-screen(992px){
         bottom: 18px;
      }
      @include max-screen(576px){
         bottom: 26px;
         max-width: 245px;
      }
   }
   &__title{
     color: #ffffff;
     font-size: 24px;
     font-weight: 700;
     line-height: 18px;
     
     @include max-screen(576px){
       line-height: 24px;
       letter-spacing: 0px;
     }
     @include min-screen(992px){
      letter-spacing: 1px;
     }
   }
   &__info{
     display: flex;
     align-items: center;
     margin-top: 13px;
   }
   &__date{
     opacity: 0.5;
     color: #ffffff;
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     margin-right: 20px;    
   }
   &__user-name{
     color: #ffffff;
     font-size: 14px;
     font-weight: 700;
     line-height: 18px;
   }
   &__avatar{
     width: 40px;
     height: 40px;
     border-radius: 50%;
     overflow: hidden;
     margin-right: 12px;
   }
   &__poster{
      position: relative;
      display: block;
      height: 370px;
      background-size: cover;
      &::before{
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-image: url('../img/short-blog-shadow.png');
         background-position: bottom;
         background-repeat: repeat-x;
      }
      @include screen(576px, 768px){
         width: 100%;
      }
      @include max-screen(767px) {
         height: 300px;
      }
   }
   &__poster-main{
      position: relative;
      display: block;
      height: 370px;
      background-size: cover;
      &::before{
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background: linear-gradient(to top,transparent 60%,#000 110%);
      }
      @include screen(576px, 768px){
         width: 100%;
      }
      @include max-screen(767px) {
         height: 300px;
      }
   }
   &__poster-img{
      @include max-screen(992px){
         width: 100%;
      }
   }
   &__empty {
      margin-left: 18px;
      font-size: 16px;
   }
}