.publications {
  margin-top: 20px;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    @include max-screen(767px) {
      flex-direction: column;
    }

    @include screen(768px, 1100px) {
      position: relative;
    }
  }

  &__add {
    @include screen(768px, 1100px) {
      position: absolute;
      right: 0;
      top: -60px;
    }
  }

  &__menu {
    position: relative;
    padding: 18px 30px;
    background-color: #fff;
    border-radius: 5px;

    @include max-screen(767px) {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -15px;
      left: 65px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      transform: rotate(45deg);

      @include max-screen(767px) {
        display: none;
      }
    }

    &._without-arrow {
      &::before {
        display: none;
      }
    }

    &._blog {
      display: inline-block;
      margin-top: 20px;

      &::before {
        left: 115px;
      }
    }
  }

  &__search {
    width: 300px;
    height: 55px;
    padding-left: 30px;
    border: 0;
    border-bottom: 2px solid #fff;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    color: #848484;
    background: url('../img/svg/search.svg') no-repeat left center;

    @include max-screen(767px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__elem {
    margin-bottom: 30px;
    border-radius: 5px;
  }

  &__photo {
    position: relative;
    height: 545px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include max-screen(767px) {
      height: 168px;
    }
  }

  &__control {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__desc-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 25px 35px 40px;
    color: #fff;
    &_down {
      padding: 0 25px 0 40px;
    }
    @include max-screen(767px) {
      padding: 15px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: white;

    @include max-screen(767px) {
      font-size: 18px;
    }
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
  }

  &__desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    max-width: 65%;
    font-size: 14px;

    @include max-screen(767px) {
      display: none;
    }
  }

  &__author {
    display: flex;

    @include max-screen(767px) {
      display: none;
    }
  }

  &__avatar {
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }

  &__name {
    font-weight: bold;
    font-size: 14px;
  }

  &__rating {
    padding-left: 22px;
    color: rgba(#fff, 0.6);
    background: url('../img/svg/author-rating.svg') no-repeat left center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    padding: 32px 40px 20px 40px;
    background-color: #fff;;

    &._blog {
      padding: 32px 0 55px 0;
      background-color: transparent;
      border-bottom: 2px solid #fff;
    }

    @include max-screen(767px) {
      padding: 20px;
    }
  }

  &__desc-wrap-main {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 25px 0 40px;
  }

  &__info_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_left {
      float: left;
    }

    &_right {
      float: right;
    }

    &_views {
      display: inline-block;
      margin-right: 43px;
      padding-left: 30px;
      background: url('../img/svg/publication-views.svg') no-repeat left center;
      color: #919191;
    }

    &_comments {
      display: inline-block;
      margin-right: 43px;
      padding-left: 30px;
      background: url('../img/svg/comment-icon.svg') no-repeat left center;
      color: #919191;
    }
  }

  &__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__info-elem {
    max-width: 45%;

    @include max-screen(767px) {
      max-width: 49%;
    }
  }

  &__likes {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 14px;
  }

  &__like {
    margin-right: 25px;
    padding-left: 25px;
    color: #39b54a;
    background: url('../img/svg/like.svg') no-repeat left center;

    @include max-screen(767px) {
      margin-right: 10px;
    }
  }

  &__dislike {
    margin-right: 25px;
    padding-left: 25px;
    color: #b53939;
    background: url('../img/svg/dislike.svg') no-repeat left center;
  }

  &__hashtags {
    color: #383838;
    font-size: 14px;

    @include max-screen(767px) {
      min-width: 200%;
    }
  }

  &__counters {
    margin-bottom: 25px;
    font-size: 14px;
    text-align: right;

    &._left {
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__date {
    display: inline-block;
    margin-right: 50px;
    color: #919191;
    font-weight: normal;

    @include max-screen(767px) {
      display: none;
    }

    @include screen(768px, 1100px) {
      margin-right: 40px;
    }
  }

  &__views {
    display: inline-block;
    margin-right: 43px;
    padding-left: 30px;
    background: url('../img/svg/publication-views.svg') no-repeat left center;
    color: #919191;

    @include max-screen(767px) {
      margin-right: 15px;
    }
  }

  &__comments {
    display: inline-block;
    padding-left: 27px;
    background: url('../img/svg/comment-icon.svg') no-repeat left center;
    color: #353c42;
  }

  &__community {
    text-align: right;
    font-size: 14px;

    @include max-screen(767px) {
      display: none;
    }
  }

  &__community-link {
    color: #848485;
  }

  &__small-form {
    width: 570px;
    
    @include max-screen(1100px) {
      width: 100%;
    }
  }

  &__cover-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__cover {
    width: 47%;
    display: flex;
    justify-content: space-between;

    @include max-screen(767px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__cover-photo {
    width: 135px;
    height: 85px;
    background-size: cover;
    background-position: center;

    &._empty {
      border: 2px dashed #b8ccd2;
      background: url('../img/svg/photo-icon.svg') no-repeat center; 
    }

    &._big {
      position: relative;
      width: 100%;
      height: 545px;

      @include max-screen(767px) {
        height: 162px;
      }

      @include screen(768px, 1100px) {
        height: 430px;
      }

      &._empty {
        background-size: 30%;
      }
    }

    &._half {
      width: 100%;
      height: 420px;
      margin-bottom: 20px;

      @include max-screen(767px) {
        height: 128px;
      }

      @include screen(768px, 1100px) {
        height: 330px;
      }

      &._empty {
        background-size: 40%;
      }
    }
  }

  &__cover-control {
    width: 100px;
    text-align: center;
  }

  &__cover-delete {
    display: inline-block;
    margin-top: 10px;
    font-size: 12px;
    color: #ff4800;
    border-bottom: 1px dashed #ff4800;
    transition: border-bottom .2s ease;

    &:hover {
      text-decoration: none;
      border-bottom: 1px dashed #fff;
    }
  }

  &__double-photo {
      width: 47%;
      @include max-screen(767px) {
      display: contents;
      }
  }

  &__btn {
    padding-left: 50px;
    background-position: 20px center;
    background-repeat: no-repeat;

    &._text {
      background-image: url('../img/svg/text-block.svg');
    }

    &._image {
      background-image: url('../img/svg/image-block.svg');
    }

    &._image-double {
      background-image: url('../img/double-image-block.png');
      background-position: 15px center;
    }

    &._video {
      background-image: url('../img/svg/video-block.svg');
      background-position: 15px center;
    }

    @include max-screen(767px) {
      display: block;
      margin-bottom: 10px;
    }

    @include screen(768px, 1100px) {
      margin-bottom: 5px;
    }
  }

  &__add-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding-top: 45px;
    border-top: 2px solid #eef3f6;

    @include max-screen(1100px) {
      flex-direction: column;
    }
  }

  &__add-date {
    font-size: 12px;
    color: #8c959c;
  }

  &__add-controls {
    @include max-screen(767px) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    @include screen(768px, 1100px) {
      display: flex;
      align-items: center;
    }
  }

  &__date-value {
    font-size: 14px;
    color: #353c42;
  }

  &__save-to-draft {
    display: inline-block;
    margin-right: 40px;
    font-size: 14px;
    color: #848484;
    border-bottom: 1px dashed #848484;
    transition: border-bottom .2s ease;

    &:hover {
      text-decoration: none;
      border-bottom: 1px dashed #fff;
    }

    @include max-screen(767px) {
      order: 1;
      flex: 1;
      margin-right: 0;
      margin-left: 10px;
      line-height: 16px;
      text-align: center;
    }

    @include screen(768px, 1100px) {
      margin-right: auto;
    }
  }

  &__ctrl-btn {
    &._pre {
      @include max-screen(767px) {
        order: 0;
      }

      @include screen(768px, 1100px) {
        margin-right: 10px;
      }
    }

    &._save {
      @include max-screen(767px) {
        order: 2;
        margin-top: 10px;
      }
    }
  }
}

.big-photo-conteiner{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
		  
    position: relative;
    width: 860px;
    height: 545px;

    @include max-screen(767px) {
        height: 162px;
    }

    @include screen(768px, 1100px) {
        height: 430px;
    }
    
}

.half-photo-conteiner{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
		  
    width: 420px;
    height: 420px;
    margin-bottom: 20px;

    @include max-screen(767px) {
      height: 128px;
    }

    @include screen(768px, 1100px) {
      height: 330px;
    }
}

.big-photo{
    max-width: 860px;
    max-height: 545px;

    @include max-screen(767px) {
        max-height: 162px;
    }

    @include screen(768px, 1100px) {
        max-height: 430px;
    }
}

.hals-photo{		  
    max-width: 420px;
    max-height: 420px;

    @include max-screen(767px) {
      max-height: 128px;
    }

    @include screen(768px, 1100px) {
      max-height: 330px;
    }
}

.cover-image{
    max-width: 135px;
    max-height: 85px;
}

.disable-cursour {
    cursor: wait;
}

.error-validation{
    display: inline-block;
    font-size: 13px;
    color: #ff0000;
    padding-left: 20px;
}


.title-pub{
    margin-bottom: 20px;
    margin-top: 0px;
}

.ti-selected-item{
    background-color: #26c3df!important;
}

.youtube-block{
    margin-bottom: 20px;
    text-align: center;
}
.youtube-block iframe{
    width: 700px;
    height: 450px;

    @include max-screen(767px) {
	height: 250px;
        width: 100%;
    }
}

.delete{
    color: #ff0000;
}

.ck-editor__editable_inline, .ql-editor {
    min-height: 180px;
}

.blog__boat-name{
    display: block;
    &:visited {
      color: #353c42;
    }
    &:hover {
      color: #353c42;
    }
    &:active {
      color: #353c42;
    }
}
.comments{
    max-width: 840px;
}
.blog__aboard{
    min-width: 130px;
}
.black-link{
    color: #353c42;
    &:visited {
      color: #353c42;
    }
    &:hover {
      color: #353c42;
    }
    &:active {
      color: #353c42;
    }
}
.white-link{
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
}
.publications-list{
    min-width: 100%;

    @include max-screen(767px) {
	width: 95%;
        min-width: 95%;
    }
}
.block-link{
    display: block;
    margin-right: 30px;
}
.corner-text{
    font-size: 12px;
    color: #848484;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.list-table{
    max-width: 300px
}

.blog__post-text{
    word-wrap: break-word;
    width: 840px;
}
.elem-conteiner{
    height: 145px;
    width: 31%;
}
.elem-bg{
    background-size: cover;
    background-position: center;
    height: 145px;
    width: 29.5%;
    padding: 0!important;
    margin-right: 29px;
    margin-bottom: 30px;
    
    @include max-screen(767px) {
	width: 40%;
    }
}
.back-shadow{
    background: linear-gradient(to bottom, transparent 40%, black 120%);
    width: 100%!important;
    margin: 0!important;
}
.post__breadcrumb .breadcrumb ._blog{
    margin-bottom: 10px;
}
.fa{
    height: 25px;
    width: 25px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.fa-vk{
    background-image: url('../img/social2/vk.png');
}
.fa-facebook{
    background-image: url('../img/social2/facebook.png');
}
.fa-odnoklassniki{
    background-image: url('../img/social2/ok.png');
}
.fa-linkedin{
    background-image: url('../img/social2/linkedin.png');
}
.fa-twitter{
    background-image: url('../img/social2/twitter.png');
}
.social-container{
    display: inline-block;
    vertical-align: top;
    padding-top: 10px;
}
.likes-container{
    display: inline-block;
    vertical-align: top;
    padding: 12px 10px 25px;
    margin: 0px;
}
.container ._content{
    padding: 0px!important;
}
.base-form__group ._full{
    width: 100%!important;
    margin-right: 0px!important;
}
.slider-arrow{
    height: 33px;
    width: 35px;
    position: absolute;
    
    top: 220px;
    @include max-screen(767px) {
	top: 40px;
    }
    
    background-color: white;
    opacity: .4;
    text-align: center;
    line-height: normal;
    font-size: 23px;

    &:hover {
    opacity: .7;
    }
}
.slider-left{
    left: 20px;
}
.slider-right{
    right: 20px;
}
.arrow{
    
}
.title-pub{
    line-height: 140%;
}
.comments__title{
    text-align: left!important;
}

.img-background{
    position: absolute;
    width: 100%;
    height: 545px;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include max-screen(767px) {
      height: 168px;
      width: 100%;
    }
}
.img-gradient{
    @extend .img-background;
    background: linear-gradient(to bottom,transparent 60%,#000 110%);
}
.blog__post-text{
    max-width: 100%;
}
