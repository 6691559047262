.ui-button, 
.ui-state-default, 
.ui-widget-content 
.ui-state-default, 
.ui-widget-header 
.ui-state-default, 
html .ui-button.ui-state-disabled:active, 
html .ui-button.ui-state-disabled:hover{
   font-size: 15px;
   font-weight: 400;
   color: #353c42;
   background-color: transparent;
   border: 0;
   padding: 6px;
}
.ui-datepicker-header{
   background-color: #fff;
   padding: 15px;
   color: #fff;
   border: 0;
   border-bottom: 1px solid #eef3f7;
   position: relative;
}
.ui-datepicker{
   border: 0;
}
.ui-datepicker-calendar{
   padding: 15px;
}
.ui-widget-header .ui-icon{
   outline: 0;
   border: 0;
   text-decoration: none;
   background-color: transparent;
}
.ui-datepicker-next{
   float: right;
}
.ui-widget-header a{
   color: #8c959c;
}
.ui-datepicker-title{
   text-align: center;
   color: #353c42;
   font-size: 18px;
   font-weight: 700;
   line-height: 18px;
}
.ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
	border: 0;
	background: transparent;
	font-weight: bold;
	color: #fff;
}

.ui-icon{
   display: none;
}

.ui-datepicker-prev,
.ui-datepicker-next{
   position: absolute;
   top: 12px;
   z-index: 100;
   display: inline-block;
   transition: color .3s;
   cursor: pointer;

   &::before{
      font-family: $icon-font;
      line-height: 100%;
      display: inline-block;
   }

   &:hover{
      color: #000;
   }
}
.ui-datepicker-prev{
   left: 15px;

   &::before{
      content: "\e90a";
   }
}
.ui-datepicker-next{
   right: 15px;

   &::before{
      content: "\e90b";
   }
}