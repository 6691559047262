.rentals-boat{
   padding-top: 62px;

   &._no-padding {
      padding-top: 0;
   }

   @include max-screen(992px){
      padding-top: 32px;
   }

   &__breadcrumb{
      margin-bottom: 5px;
      @include max-screen(992px){
         margin-bottom: 13px;
      }
      @include max-screen(576px){
         display: none;
      }
   }
   &__title{
      margin-bottom: 35px;
      @include max-screen(992px){
         margin-bottom: 0;
      }
   }
   &__row{
      display: flex;
      position: relative;
      margin-top: 58px;

      @include max-screen(1090px){
         flex-direction: column-reverse;
         margin-top: 0;
      }
   }
   &__profile{
      @include screen(1090px, 1200px){
        max-width: 700px
      }
      @include max-screen(992px){
         margin-top: 20px;
      }
      @include min-screen(1200px){
         max-width: 770px;
         width: 100%;
      }
   }
   &__description{
      background-color: #fff;
      margin-top: 8px;

      &._rental{
         margin-top: -2px;
      }
   }
   &__text-wrap{
      max-width: 545px;
      width: 100%;
      position: relative;

      @include max-screen(576px){
         padding-left: 20px;
         padding-right: 20px;
      }
   }
   &__description-text{
      margin-top: 0;
      margin-bottom: 24px;

      @include max-screen(576px){
        padding-right: 10px;
      }
   }
   &__description-hint{
      color: #353c42;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      width: 103px;
      margin: 3px 36px 0 40px;
      @include max-screen(992px){
         margin-left: 60px;
      }
      @include max-screen(767px){
         flex-shrink: 0;
      }
      @include max-screen(576px){
         width: 100%;
         margin: 5px 0;
         padding-left: 20px;
         padding-right: 20px;
      }
   }
   &__container{
      padding: 53px 0;
      @include max-screen(576px) {
        padding: 25px 0;
      }

      &._rental{
         position: relative;
         padding: 5px 0 53px 0;
      }
   }
   &__content{
      display: flex;
      color: #383838;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 40px;

      @include max-screen(576px){
        flex-direction: column;
        margin-bottom: 20px;
      }

      &._is-closed{
        display: none;
      }
      &._hide-hint{
        padding-left: 175px;
        margin-bottom: 0;
        @include max-screen(991px){
          padding-left: 195px;
        }
        @include max-screen(576px){
          padding-left: 130px;
        }
      }
      &._desc {
         margin-bottom: 0;
      }
   }
   &__text-container{
      max-height: 200px;
      overflow: hidden;

      @include max-screen(768px){
        max-height: 190px;
      }
      @include max-screen(576px){
        max-height: 390px;
      }

      &._is-opened{
         max-height: 100%;
      }

      &._is-closed {
         position: relative;
         &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 60px;
            left: 0;
            bottom: 0;
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
         }
      }
   }
   &__description-read-more{
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      margin-top: 23px;
      display: inline-block;
      position: relative;

      &:hover{
         text-decoration: none;
         &::after{
            color: $base-color;
         }
      }
      &::after {
         content: "\e92a";
         font-family: $icon-font;
         position: absolute;
         right: -22px;
         top: 1px;
         color: #c3c3c3;
         font-size: 10px;
         transition: transform .3s ease-in;
      }

      &._open {
         &::after {
            transform: rotate(180deg);
         }
      }
   }
   &__description-read-link{
      border-bottom: 1px dashed;
   }
   &__blogs{
      padding-top: 60px;
      padding-bottom: 82px;
      @include max-screen(992px){
        padding-bottom: 62px;
      }
      @include max-screen(576px){
        padding-top: 52px;
      }
   }
   &__blogs-title{
      margin-bottom: 21px;
      @include max-screen(992px){
        margin-bottom: 35px;
      }
   }
   &__blogs-container{
      max-width: 770px;
   }
   &__more-blogs-wrap{
      display: flex;
      justify-content: center;
      margin-top: 18px;
      @include max-screen(576px){
        margin-top: 29px;
      }
   }
   &__map-container{
      background-color: #fff;
      padding-top: 60px;
      padding-bottom: 80px;
      @include max-screen(992px){
        padding-top: 54px;
      }
   }
   &__map-title{
      margin-bottom: 22px;
      @include max-screen(992px){
        margin-bottom: 32px;
      }
   }
   &__comments{
      padding-bottom: 82px;
   }
   &__comments-container{
      max-width: 770px;
   }
   &__sidebar{
      background-color: #fff;
      border-radius: 3px;
      border: 2px solid #eef3f6;
      width: 100%;
      padding: 42px 28px 39px;
      z-index: 200;

      @include min-screen(1090px){
         position: absolute;
         top: 0;
         right: 0;
         max-width: 370px;
         margin-left: 30px;
      }
      @include max-screen(576px){
        padding: 15px 18px;
        z-index: 1;
      }

      &._logged{
        padding-bottom: 30px;
      }

      &._short {
         top: 20px;
         padding-top: 20px;
         padding-bottom: 10px;

         @include max-screen(1090px){
            width: 98%;
            margin: 0 auto 50px auto;
         }
      }
   }
   &__form-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: -2px;
      @include max-screen(992px){
        margin-bottom: 0;
      }
   }
   &__form-day-price{
      color: #353c42;
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
   }
   &__form-day-select{
      width: 150px;
      @include max-screen(1090px){
         width: 341px;
      }
      @include max-screen(768px){
        width: 150px;
      }
   }
   &__form-dates{
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
   }
   &__form-dates-container{
     display: flex;
     justify-content: space-between;
   }
   &__form-label-group{
      display: flex;
      justify-content: space-between;
   }
   &__form-column{
     &._passages{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      @include screen(768px, 1090px){
        flex: 1;
      }
     }
   }
   &__side-row{
      border-bottom: 2px solid #eef3f6;
      display: flex;
      justify-content: space-between;
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding-bottom: 15px;
      margin-bottom: 16px;
   }
   &__total-hint{
      color: #353c42;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 7px;
   }
   &__total-price{
      color: $base-color;
      font-family: "Open Sans";
      font-size: 36px;
      font-weight: 700;
      line-height: 18px;
   }
   &__total{
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      margin-bottom: 5px;
   }
   &__call-btn{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      @include max-screen(576px){
        flex-direction: column;
      }
   }
   &__call-btn-icon{
      font-size: 17px;
      margin-right: 4px;
      vertical-align: middle;
   }
   &__placement{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }
   &__placement-info{
    color: #353c42;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
   }
   &__placement-days{
    color: $base-color;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    flex-shrink: 0;
   }
   &__control-btn{
     margin-bottom: 10px;

   }
   &__description-edit{
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
    border-bottom: 1px dashed;
    position: absolute;
    bottom: 6px;
    right: -26px;

    @include max-screen(768px){
      right: 10px;
    }
    @include max-screen(410px){
      right: auto;
      left: 0;
      bottom: -26px;
    }

    &:hover{
      text-decoration: none;
    }
   }
   &__title-wrap{
    display: flex;
    justify-content: space-between;
    max-width: 770px;
    align-items: baseline;
   }
   &__map-edit{
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
    border-bottom: 1px dashed;

    &:hover{
      text-decoration: none;
    }
   }
   &__boat-position {
     max-width: 770px;
     width: 100%;
     height: 400px;
   }
   &__feature-wrap{
     display: flex;
     width: 100%;
     @include max-screen(767px) {
        flex-wrap: wrap;
     }
   }
   &__feature-column{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 5px;
   }
   &__feature-item{
     margin-bottom: 7px;
     @include max-screen(576px) {
       margin-bottom: 0;
     }
   }
   &__activities-item{
     margin-right: 15px;
     &:last-child{
       margin-right: 0;
     }
   }

   &__photo-preview {
      margin-bottom: 25px;
   }

   &__rental-form {
      display: flex;
      max-width: 770px;
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;

      @include max-screen(1110px) {
         padding: 0 10px;
         margin: auto;
      }
   }

   &__error {
      margin-top: -25px;
      margin-bottom: 15px;
      overflow: hidden;
      font-size: 14px;
      color: red;

      &._hidden {
         display: none;
      }
   }

   &__success {
      margin-bottom: 50px;
      text-align: center;
      font-size: 36px;
      color: #383838;

      @include max-screen(767px) {
        font-size: 32px;
      }
   }

   &__no-actual {
      color: $base-color;
      font-family: "Open Sans";
      font-size: 24px;
      text-align: center;
      line-height: 18px;
   }
}
.boat-goods__note{
    @include max-screen(320px) {
	margin-bottom: 15px;
    }
}
.note_editing{
    display: block;
}

.rentals-boat__description-text > p{
    margin: 0px;
}
