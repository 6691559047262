.short-prof-boats{
   position: relative;
   border-radius: 0 0 3px 3px;
   background-color: #ffffff;
   padding-bottom: 5px;

   &._add-boats{
      background-image: url('../img/svg/anc-white-add-boat.svg');
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      height: 100%;
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: 2px solid #ffffff;
      padding-top: 5px;

      @include max-screen(768px){
         height: 361px;
         margin-top: 33px;
         background-size: contain;
      }
      @include max-screen(380px){
         background-size: 93%; 
      }
   }

   &__poster{
      position: relative;
      display: block;

      &::after{
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: #000;
         opacity: 0;
         transition: opacity .3s;
      }

      &:hover{
         &::after{
            opacity: .3;
         }
      }

      &._profile {
         min-height: 200px;
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center;
      }
   }
   &__poster-img{
      width: 100%;
   }
   &__posted{
      height: 33px;
      background-color: rgba(53, 60, 66, 0.8);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      color: #ffffff;
      font-size: 14px;
      line-height: 18px;
      padding-left: 14px;
      z-index: 10;
   }
   &__posted-link{
      background-color: #ff8400;
      color: #ffffff;
      font-size: 14px;
      line-height: 33px;
      padding: 0 18px;
      position: relative;
      &:hover{
         color: #ffffff;
      }

      @include max-screen(576px){
         padding: 0 14px;
      }

      &::before{
         content: '';
         border-style: solid;
         border-width: 0 0 33px 10px;
         border-color: transparent transparent #ff8400 transparent;
         position: absolute;
         left: -10px;
         top: 0;

         @include max-screen(1200px){
            left: -9px;
         }
      }

      &._disabled {
         cursor: default;
         
         &:hover {
            text-decoration: none;
         }
      }
   }
   &__content{
      padding: 24px 20px;
      @include max-screen(992px){
         padding: 29px 15px 18px;
      }
      @include max-screen(576px){
         padding: 23px 10px 3px;
      }
   }
   &__title{
      color: #353c42;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      margin-top: 0;
      margin-bottom: 20px;
   }
   &__info-row{
      display: flex;
      flex-direction: column;
   }
   &__info{
      line-height: 18px;
      display: flex;
      justify-content: space-between;
   
      @include min-screen(992px){
         padding-right: 9px;
      }

      @include min-screen(576px){
         padding-right: 4px;
      }
   }
   &__info-title{
      color: #919191;
      font-size: 14px;
      margin-bottom: 8px;
   }
   &__info-count{
      color: #353c42;
      font-size: 18px;
   }
   &__btns{
      padding-top: 28px;
      display: flex;
      justify-content: space-between;
      @include max-screen(576px){
         flex-direction: column;
      }
   }
   &__btn{
      padding: 0px 29px;
      height: 54px;
      line-height: 54px;
      font-size: 16px;

      @include screen(992px, 1080px){
         padding: 0 12px;
      }
      @include screen(1080px, 1180px){
         padding: 0 20px;
      }
      @include max-screen(576px){
         width: 100%;
         text-align: center;
         margin-bottom: 10px;
      }
   }
   &__add-btn{
      font-size: 16px;
      height: 54px;
      line-height: 54px;
      padding: 0px 21px; 

      @include max-screen(57px){
         padding: 0px 25px;
      }
   }
   &__add-btn-icon{
      font-size: 17px;
      margin-right: 5px;
   }
}