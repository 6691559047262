.profile-notify {
  border-bottom: 2px solid #eef3f6;

  &__elem {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include max-screen(767px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__content {
    max-width: 70%;
    font-size: 14px;
    @include max-screen(767px) {
      max-width: 100%;
      margin-bottom: 15px;
    }
  }

  &__title {
    color: #383838;
    font-weight: bold;
  }

  &__date {
    margin-bottom: 10px;
    color: #aeaeae;
  }

  &__mark-as-read {
    margin-left: auto;
    font-size: 12px;
    color: #ff4800;
    border-bottom: 1px dashed #ff4800;
    transition: border-bottom .3s ease;

    &:hover {
      text-decoration: none;
      border-bottom: 1px dashed #fff;
    }

    @include max-screen(767px) {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
