.error-page{
   width: 100vw;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   padding: 15px;

   &__header-logo{
      width: 150px;
   }
   &__container{
      margin: 50px 0 40px;
      width: 503px;
      max-width: 100%;
      height: 503px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;

      @include max-screen(576px){
         height: 380px;
      }

      &._error-404{
         background-image: url('../img/svg/sail.svg');
      }
   }
   &__error-code{
      color: #ff8500;
      font-size: 160px;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase;

      @include max-screen(576px){
         font-size: 100px;
      }
   }
   &__text{
      text-align: center;
      color: #353c42;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      margin: 0;
   }
   &__footer{
      max-width: 425px;
   }
   &__link{
      color: $base-color2;
   }
}