.short-boat-info {
  display: flex;
  flex-direction: column;
  &__title {
    color: #353c42;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
    @include max-screen(992px) {
      margin-bottom: 17px;
    }
    @include max-screen(576px) {
      margin-bottom: 16px;
    }
  }
  &__place {
    color: #353c42;
    font-size: 14px;
    position: relative;
    padding-left: 23px;
    margin-bottom: 7px;
    @include max-screen(992px) {
      padding-left: 22px;
      margin-bottom: 4px;
    }
    @include max-screen(576px) {
      line-height: 20px;
      margin-bottom: 8px;
    }
    &::before {
      content: '\e93b';
      color: #8d969d;
      font-family: 'icomoon' !important;
      position: absolute;
      left: -2px;
      top: -3px;
      font-size: 17px;
      @include max-screen(992px) {
        top: 0;
        font-size: 16px;
      }
    }
  }
  &__other-info {
    color: #919191;
    font-size: 14px;
    line-height: 20px;
    @include max-screen(767px) {
      line-height: 18px;
    }
  }
}
