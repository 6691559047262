.boat-goods{
   display: flex;
   min-height: 116px;
   border-bottom: 2px solid #eef3f6;
   justify-content: space-between;
   padding: 26px 0;
   margin-bottom: 10px;
   flex-direction: column;

   @include max-screen(576px){
      margin-bottom: 12px;
   }

   &__poster{
      padding-right: 20px;
      @include max-screen(576px){
         flex-shrink: 0;
         margin-left: 3px;
         margin-top: 4px;
      }
   }
   &__column{
      display: flex;
      height: 100%;
      align-items: center;
      height: 100%;
      position: relative;
      &._with-wrap {
         flex-wrap: wrap;
         justify-content: space-between;
         @include max-screen(1100px) {
            width: 100%;
            align-content: center;
            margin-bottom: 10px;
         }
      }
      &._detail {
         margin-left: auto;
      }
      &._date {
         @include min-screen(1100px) {
            max-width: 140px;
            min-width: 140px;
         }
      }
   }
   &__rental-dates {
      font-size: 14px;
      line-height: 28px;
   }
   &__rental-price {
      font-size: 14px;
      line-height: 28px;
   }
   &__duration {
      font-size: 24px;
      font-weight: bold;
      color: #353c42;
      line-height: 30px;
      @include max-screen(576px){
          font-size: 14px;
      }
   }
   &__row{
      display: flex;
      width: 100%;
      padding-bottom: 10px;
      align-items: center;

      @include max-screen(1100px){
         flex-direction: column;
         align-items: flex-start;
      }
   }
   &__date-wrap{
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      width: 160px;
      flex-shrink: 0;

      @include max-screen(992px){
         width: 100%;
         flex-direction: row;
         justify-content: space-between;
         flex-wrap: wrap;
      }
   }
   &__date{
      color: #848484;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 18px;
   }
   &__status_count{
      color: $base-color;
   }
   &__note{
      margin-left: 10px;
      color: #848484;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      border-bottom: 1px dotted;

      &:hover{
         text-decoration: none;
      }

      &._is-cheked{
         color: #ff4800;
      }

      &._action {
         margin-right: 10px;
      }

      @include max-screen(1099px) {
         &._desc {
            display: none;
         }
      }

      @include min-screen(1100px) {
         &._mob {
            display: none;
         }
      }
   }
   &__subtitle {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      color: #8c959c;
      width: 100%;
      @include max-screen(1100px) {
         width: auto;
         margin-bottom: 0;
      }
   }
   &__title-wrap{
      @include min-screen(992px){
         max-width: 400px;
         min-width: 400px;
         margin-right: 20px;
         flex-grow: 2;
      }
      @include max-screen(992px){
         margin-top: 8px;
      }
      @include max-screen(1100px) {
         align-items: flex-start;
         justify-content: flex-start;
         margin-bottom: 20px;
      }
   }
   &__goods-title{
      color: #353c42;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;

      &._yacht {
         color: #8c959c;
      }

      @include max-screen(767px) {
         display: inline-block;
      }
      @include max-screen(576px){
         line-height: 23px;
      }
   }
   &__captain-price-wrap{
      display: flex;
      flex-grow: 1;

      @include max-screen(992px){
         width: 100%;
         margin-top: 22px;
      }
      @include max-screen(576px){
         margin-top: 20px;
      }
   }
   &__captain{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #848484;
   }
   &__captain-name{
      color: #353c42;
   }
   &__price{
      color: #ff8400;
      font-size: 24px;
      font-weight: 700;  
      @include max-screen(576px){
          font-size: 14px;
      }
   }
   &__captain-wrap{
      flex-grow: 1;
      margin-right: 14px;
   }
   &__note-wrap{
      background-color: #fff7e6;
      color: #353c42;
      font-size: 14px;
      line-height: 18px;
      margin-top: 16px;
      padding: 15px 28px;
      position: relative;
      border-radius: 3px;
      margin-bottom: 15px;
      position: relative;

      @include max-screen(992px){
         margin-top: 10px;
         margin-bottom: 21px;
         padding-left: 20px;
      }
      @include max-screen(576px){
         padding-right: 10px
      }

      &._for-mobile{
         width: 100%;

         @include min-screen(992px){
            display: none;
         }
      }
      &._for-desktop{
         @include max-screen(992px){
            display: none;
         }
      }
   }
   &__note-text{
      margin: 0;
   }
   &__note-edit{
      color: #848484;
      font-size: 12px;
      line-height: 14px;
      float: right;
      border-bottom: 1px dotted;
      margin-top: 6px;
      outline: 0;
      cursor: pointer;

      &:hover{
         text-decoration: none;
      }
   }

   &__actions {
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      justify-content: flex-end;
      width: 100%;

      @include max-screen(767px) {
         flex-wrap: wrap;
      }
   }

   &__note-edit-form{
      display: flex;
      align-items: baseline;

      @include max-screen(768px) {
         flex-wrap: wrap;
      }
   }

   &__avatar{
      margin: 0 5px;
      width: 40px;
      border-radius: 50%;
   }

   &__details-button {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      color: #fff;
      background-color: #26c3df;
      border-radius: 3px;
      cursor: pointer;
      width: 110px;
   }

   &__arrow {
      display: inline-block;
      margin-left: 10px;
      width: 0; 
      height: 0; 
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;      
      border-top: 6px solid #fff;
      transition: transform .3s;

      &._open {
         transform: rotate(180deg);
      }
   }

   &__details {
      padding: 40px;
      background-color: #eef3f6;
      border-radius: 3px;
      @include max-screen(767px) {
         padding: 20px;
      }
   }

   &__status-button {
      width: 150px;
      margin-right: 10px;
      padding: 10px 0;
      border-radius: 3px;
      background-color: #26c3df;
      color: #fff;
      text-align: center;
      transition: background-color .3s;

      &._wide {
         width: auto;
         padding: 10px 20px;

         @include max-screen(767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
         }
      }

      &:hover {
         text-decoration: none;
         color: #fff;
         background-color: rgba(#26c3df, .8);
      }

      &._approve {
         background-color: #ff8400;

         &:hover {
            background-color: rgba(#ff8400, .8);
         }
      }

      &._disabled {
         background-color: #8c959c;

         &:hover {
            background-color: rgba(#8c959c, .8);
         }
      }

      @include max-screen(767px) {
         width: 100%;
         margin-right: 0;
         margin-bottom: 5px;
      }
   }

   &__info {
      display: flex;
      flex-wrap: wrap;
      @include screen(768px, 1100px) {
         justify-content: space-between;
      }
   }

   &__desc {
      min-width: 100%;
      @include max-screen(767px) {
         order: -1;
         margin-bottom: 20px;
      }
   }

   &__elem {
      font-size: 14px;
      color: #353c42;
      &._user {
         @include min-screen(1100px) {
            width: 220px;
            margin-right: 0;
         }

         @include max-screen(767px) {
            order: -2;
         }
      }

      &._phone {
         @include min-screen(1100px) {
            width: 150px;
            margin-right: 0;
         }
      }

      &._contact {
         @include min-screen(1100px) {
            min-width: 180px;
            max-width: 180px;
         }
      }

      &._passanges {
         @include min-screen(1100px) {
            margin-right: 20px;
         }
      }

      @include max-screen(767px) {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;
         margin-bottom: 20px;
      }
   }

   &__info-title {
      margin-bottom: 10px;
      color: #8c959c;

      &._user {
         margin-bottom: 4px;
      }

      @include max-screen(767px) {
         margin-bottom: 0;

         &._user {
            margin-bottom: 0;
         }
      }
   }

   &__user {
      display: flex;
      align-items: center;
   }

   &__user-avatar {
      margin-right: 12px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
   }

   &__link {
      color: #353c42;
   }

   &__delta {
      @include max-screen(767px) {
         left: auto;
         right: 27px;
      }
   }
}
.cash-info{
   padding-top: 20px;
   &__title {
      font-size: 14px;
      color: #8c959c;
   }
   &__amount {
      font-weight: 600;
      color: $base-color;
   }
   @include max-screen(767px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
   }
}
.rating-block{
   padding-right: 40px;
   @include min-screen(768px) {
      right: 0;
      position: absolute;
   }
}
.rating-element{
    padding-top: 20px;
}
.rentals-boat__title{
    display: inline-block;
}
.boat-title-rating{
    font-size: 20px!important;
    vertical-align: text-bottom;
}
.select2-results__option{
    font-family: "Open Sans",sans-serif;
    font-size: 12px!important;
}
.select2-selection__rendered{
    font-family: "Open Sans",sans-serif;
    font-size: 12px!important;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
   @include max-screen(992px){
      max-height: 50vh;
   }
}

.boat-title-line {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
   @include max-screen(767px){
      flex-direction: column;
   }

   &__rating {
      align-self: flex-end;
      margin-bottom: 5px;
      margin-right: 5px;
   }
}

.vue-tooltip.tooltip-approve-disabled {
   background-color: #8c959c;
}

.vue-tooltip.tooltip-approve-disabled .tooltip-approve-disabled-arrow {
   border-color: #8c959c;
}