.profile-nav{
   @include min-screen(992px){
      margin-top: 51px;
      border-bottom: 2px solid #fff;
   }
   
   &__container{
      @include min-screen(992px){
         display: flex;
         margin-bottom: -2px;
      } 
   }
   &__item{
      @include min-screen(992px){
         color: #848484;
         font-size: 16px;
         font-weight: 400;
         line-height: 24px;
         margin-right: 38px;
         padding: 12px 0;

         &:hover{
            color: $base-color;
         }
   
         &._is-active{
            color: #383838;
            border-bottom: 3px solid #26c3df;

            &:hover{
               color: $base-color;
            }
         }
      }

      &._logout {
         color: #ff8400;
      }
   }
}