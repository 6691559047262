.lang-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &__elem {
    padding: 20px;
    width: 48%;
    border: 1px solid #848484;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;

    &._active {
      border: 1px solid #ff8400;
    }
  }

  &__flag {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    
    &._ru {
      background-image: url('../img/svg/ru.svg');
    }

    &._en {
      background-image: url('../img/svg/en.svg');
    }
  }
}