.side-filter{
   background-color: #fff;
   padding: 30px;
   padding-bottom: 85px;
   position: relative;
   user-select: none;

   @include max-screen(991px){
      padding: 10px;      
   }

   .side-filter__container-wrap{
      @include max-screen(991px){
         display: flex;
      }
   }

   &__label{
      color: #8c959c;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      display: inline-block;
      margin-bottom: 11px;
   }
   &__boat-type-row{
      display: flex;
      flex-wrap: wrap;
   }
   &__boat-type-item{
      display: flex;
      width: 50%;
      height: 120px;
      border: 1px solid #eef3f6;
      background-color: #ffffff;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 6px;
      cursor: pointer;

      &._is-selected{
         box-shadow: 0 0 0 1px #26c3df;
         border-color: #26c3df;
         z-index: 1;
      }

      @include max-screen(767px){
         height: 90px;
      }
   }
   &__boat-type-title{
      color: #353c42;
      font-size: 14px;
      line-height: 18px;
   }
   &__boat-type-icon{
      font-size: 43px;
      margin-bottom: 12px;
      @include max-screen(767px){
         font-size: 31px;
      }
   }
   &__boat-selects{
      display: flex;
      margin-top: 30px;
      @include max-screen(1100px) {
         .select2-container--default {
            display: block;
         }
      }
      
   }
   &__boat-dates{
      display: flex;
   }
   &__boat-slider, &__price-slider {
      @include max-screen(1100px) {
         width: 95%;
      }
   }
   &__boat-length{
      margin-top: 32px;
      margin-bottom: 38px;
      padding-left: 9px;
      position: relative;
   }
   &__boat-length-val{
      color: #353c42;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      position: absolute;
      top: -33px;
      user-select: none;

      &._min{
         left: 0;
      }
      &._max{
         right: -12px;
         @include max-screen(1100px) {
            right: -2px;
         }
      }
   }
   &__switch{
      display: flex;
      justify-content: center;
   }
   &__switch-item{
      width: 80px;
      height: 63px;
      border: 1px solid #eef3f6;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      cursor: pointer;

      &._is-selected{
         color: #26c3df;
         box-shadow: 0 0 0 1px #26c3df;
         border-color: #26c3df;
         z-index: 1;
      }
   }
   &__price{
      position: relative;
      margin-top: 31px;
      padding-right: 5px;
      margin-bottom: 39px;
      padding-left: 9px;
      @include screen(768px, 991px){
         margin-bottom: 94px;
      }
   }
   &__price-val{
      color: #353c42;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      position: absolute;
      top: -33px;
      margin-left: -18px;

      &._min{
         left: 6%;
      }
      &._max{
         left: 95%;
         @include max-screen(576px){
            left: 92%;
         }
      }
   }
   &__duration{
      @include min-screen(992px){
         margin-bottom: 31px;
      }
   }
   &__inpression{
      display: flex;
      flex-wrap: wrap;
      padding-top: 3px;
   }
   &__inpression__item{
      width: 103px;
      height: 119px;
      border: 1px solid #eef3f6;
      background-color: #ffffff;
      color: #353c42;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      &._is-selected{
         box-shadow: 0 0 0 1px #26c3df;
         border-color: #26c3df;
         z-index: 1;
         color: #26c3df;
         
         @include max-screen(767px){
            border-width: 1px;   
         }
      }

      @include max-screen(1200px){
         width: 33%;
         height: 112px;
      }
      @include max-screen(991px){
         height: 120px;
      }
      @include max-screen(576px){
         height: 92px;
      }
      
   }
   &__inpression-title{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      @include max-screen(1100px){
         font-size: 12px;
      }
      @include max-screen(576px){
         font-size: 12px;
      }
   }
   &__inpression-img{
      font-size: 37px;
      margin-bottom: 3px;
      @include max-screen(576px){
         font-size: 30px;
      }
   }
   &__clear-filter{
      text-align: center;
      padding: 14px;
      border-top: 1px solid #eef3f6;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      @include max-screen(992px){
         display: none;
      }
   }
   &__clear-filter-link{
      color: #8c959c;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__container-wrap{
      @include max-screen(767px){
         flex-direction: column;
      }
   }
   &__container{
      @include max-screen(991px){
         display: flex;
         flex-direction: column;
         justify-content: flex-end;
         flex: 1;
      }
      &._left{
         @include screen(768px, 992px){
            margin-right: 20px;
         }
         @include max-screen(767px){
            margin-bottom: 30px;
         }
      }
   }
   &__mobile-clear-form{
      color: #8c959c;
      font-size: 16px;
      font-weight: 400;
      line-height: 56px;
      border-radius: 3px;
      border: 1px solid #b8ccd2;
      width: 100%;
      text-align: center;
      margin-right: 20px;
      @include max-screen(576px){
         margin-right: 10px;
      }
      &:hover{
         border-color: #5fd9ef;  
       }
   }
   &__mobile-submit{
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      border: 1px solid transparent;
      border-radius: 3px;
      background-color: #26c3df;
      line-height: 56px;
      width: 100%;
      text-align: center;
      &:hover{
         background-color: #09b6d5;  
       }
   }
   &__mobile-btns{
      display: flex;
      margin-top: 28px;

      @include min-screen(992px){
         display: none;
      }
   }
}