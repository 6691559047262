.profile {
  margin-bottom: 80px;
  margin-top: 50px;

  @include max-screen(767px) {
      margin-top: 0px;
  }

  &__wrap {
    display: flex;

    @include max-screen(1100px) {
      flex-direction: column;
    }
  }

  &__menu {
    min-width: 170px;
    margin-right: 30px;
    @include max-screen(1100px) {
      display: none;
    }
  }

  &__menu-elem {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid #fff;
  }

  &__menu-title {
    font-size: 14px;
    color: #383838;
    font-weight: bold;
  }

  &__menu-link {
    display: table;
    margin-top: 10px;
    font-size: 16px;
    color: #848484;

    &:hover {
      .profile__menu-badge {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__menu-badge {
    display: inline-block;
    margin-left: 5px;
    font-size: 16px;
    color: #fff;
    padding: 0 5px;
    background-color: #26c3df;
    border-radius: 3px;
  }

  &__content {
    width: 100%;
  }

  &__user {
    width: 100%;
    display: flex;
    align-items: center;

    &._blog {
      margin-top: 40px;
      padding-bottom: 50px;
      border-bottom: 2px solid #fff;

      @include max-screen(767px) {
        padding-bottom: 20px;
      }
    }
  }

  &__avatar-wrap {
    margin-right: 15px;
    @include max-screen(767px) {
      display: none;
      margin-right: 20px;
    }

    &._mobile {
      display: none;
    }
  }

  &__avatar {
    @include max-screen(767px) {
      height: 80px;
      width: 80px;
    }
  }

  &__user-info {
    width: 100%;
  }

  &__name-wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__user-name {
    font-size: 24px;
    font-weight: bold;
    color: #353c42;
    @include max-screen(767px) {
      font-size: 18px;
    }
  }

  &__user-online {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #39b54a;
    vertical-align: middle;
  }

  &__since {
    margin-left: auto;
    font-size: 14px;
    color: #848484;
    @include max-screen(767px) {
      display: none;
    }
  }

  &__since_author {
    display: inline-block;
    font-size: 14px;
    color: #848484;
  }

  &__user-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @include max-screen(767px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__user-stat {
    min-width: 40%;
    font-size: 14px;
    color: #353c42;

    &._blog {
	min-width: 185px;
    }

    @include max-screen(767px) {
      min-width: 10%;
      max-width: 65%;
    }
  }

  &__user-elem {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__user-status {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #383838;
    line-height: 16px;
    @include max-screen(767px) {
      margin-top: 20px;
      flex-direction: column;
    }
  }

  &__edit-button {
    margin-left: 40px;
    @include max-screen(767px) {
      margin-top: 40px;
      margin-left: 0;
    }
  }

  &__boat {
    color: #848484;
  }

  &__stat {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    @include max-screen(767px) {
      flex-direction: column;
    }
  }

  &__stat-block {
    width: 50%;
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 5px;

    &:nth-child(1) {
      margin-right: 10px;
      @include max-screen(767px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-right: 10px;
      @include max-screen(767px) {
        margin-left: 0;
      }
    }

    @include max-screen(767px) {
      width: 100%;
      margin-bottom: 10px;
      padding: 40px 20px;
    }
  }

  &__stat-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #353c42;
  }

  &__stat-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #353c42;
  }

  &__stat-left,
  &__stat-right {
    width: 45%;
  }
  &__stat-full {
    width: 90%;
  }

  &__stat-elem {
    line-height: 16px;
    margin-bottom: 15px;
  }

  &__stat-name {
    color: #848484;
  }

  &__rating {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-weight: bold;
    color: #26c3df;
    background: url('../img/svg/rating.svg') no-repeat left center;
    
    font-size: 15px;
    margin-left: 20px;
    
    @include max-screen(1100px) {
        margin-left: 2px!important;
    }

    &:hover {
      .profile__rating-desc {
        display: block;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: -20px;
      left: 0;
    }
  }

  &__rating-desc {
    display: none;
    position: absolute;
    top: 25px;
    left: -260%;
    width: 264px;
    padding: 11px 14px;
    background-color: rgba(53, 60, 66, .9);
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    z-index: 100;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent rgba(53, 60, 66, .9) transparent;
    }

    & a {
      color: #26c3df;
    }
  }

  &__stat-empty {
    margin-bottom: 15px;
    color: #848484;
    font-size: 14px;
  }

  &__title {
    margin-top: 45px;
    font-size: 24px;
    font-weight: bold;
    color: #353c42;

    &._no-margin {
      margin-top: 0;
    }
    &._header {
      margin: 0 0 35px 0;
    }
  }
  .first {
      margin-top: 0;
  }
  .second {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .need-verification {
    color: $base-color;
    text-decoration: none;
  }
  .need-verification:hover {
    color: $base-color;
    text-decoration: none;
  }

  &__white-block {
    margin-top: 35px;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    @include max-screen(767px) {
      padding: 30px 20px;
    }
  }

  &__tab-menu {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 2px solid #eef3f6;
    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__tab-elem {
    position: relative;
    margin-right: 33px;
    font-size: 14px;
    color: #848484;
    transition: color 0.2s ease;

    @include max-screen(767px) {
    margin-right: 10px!important;
    }

    &:hover {
      color: #383838;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &._active {
      color: #383838;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -22px;
        width: 100%;
        height: 2px;
        background-color: #26c3df;
        @include max-screen(767px) {
          bottom: -5px;
        }
      }
    }

    @include max-screen(767px) {
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  &__tab-counter {
    position: absolute;
    top: -15px;
    right: -15px;
    font-weight: bold;
    font-size: 14px;
    color: #26c3df;
  }

  &__button-wrap {
    padding-top: 30px;
    text-align: center;
  }

  &__boats {
    margin-top: 40px;
  }

  &__add-boat {
    background-size: 85%;
  }

  //TODO: replace original styles
  &__boat {
    .short-prof-boats__btn {
      padding: 0 20px;
      font-size: 14px;
    }
  }

  &__send-message {
    display: flex;
    align-items: center;
    margin-top: 5px;

    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__answer-time {
    padding-left: 17px;
    font-size: 12px;
    color: #848484;
    line-height: 18px;

    @include max-screen(767px) {
      padding-left: 0;
      margin-top: 10px;
    }
  }

  &__captain-boat {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-screen(767px) {
      align-items: flex-start;
    }
  }

  &__captain-boat-wrap {
    min-width: 135px;
    max-width: 135px;
    margin-right: 30px;
    text-align: center;

    @include max-screen(767px) {
      min-width: 70px;
      max-width: 70px;
      margin-right: 12px;
    }
  }

  &__captain-boat-image {
    width: 100%;
    height: 85px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen(767px) {
      height: 42px;
    }
  }

  &__captain-boat-btn {
    position: relative;
    top: -20px;

    &._mobile {
      display: none;

      @include max-screen(767px) {
        top: 10px;
        display: table;
      }
    }

    @include max-screen(767px) {
      display: none;
    }
  }

  &__captain-boat-desc {
    font-size: 14px;
    color: #383838;
    line-height: 18px;

    @include max-screen(767px) {
      font-size: 12px;
    }
  }

  &__captain-boat-name {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include max-screen(767px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 6px;
    }
  }

  &__submenu {
    margin-top: 60px;
  }

  &__submenu-elem {
    position: relative;
    margin-right: 38px;
    font-size: 24px;
    font-weight: bold;
    color: #848484;
    transition: color .2s ease;

    &:hover {
      color: #383838;
      text-decoration: none;
    }

    &._active {
      color: #383838;
    }

    @include max-screen(1100px) {
      margin-right: 20px;
      font-size: 18px;
    }
  }

  &__submenu-counter {
    font-size: 18px;
  }
}

._active {
  font-weight: bold;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.field_error {
    color: red;
    font-size: 14px;
}

.btn_menu {
    padding-top: 10px;
}
.profile__white-block > ul{
    padding-left: 0px;
}
.profile__tab-menu > li > a{
    margin-right: 33px!important;
    display: flex;
}
.profile__tab-menu > .active > a{
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  
    display: flex;
    padding-bottom: 20px;
    border-bottom: 2px solid #26c3df;
    font-weight: bold;
}
.profile__tab-menu > li{
    list-style-type: none;
    height: 24px;
}
.sep{
    height: 40px;
}

.v--modal-box{
    padding: 0px 20px 20px 20px!important;
}
.rating-comment{
    width: 100%;
}

.user-title{
    font-weight: bold;
}
.com-sep{
    display: inline-block;
    padding-right: 5px;
}
.profile__user{
    align-items: end!important;
}
#profile-land-static{
    vertical-align: top!important;
}
.blog__other-elem a{
    max-height: 50px;
    overflow: hidden;
}
.publication-profile{
    height: 210px!important;
}
.publication-profile > .back-shadow{
    height: 210px!important;
}
.background-link{
    position: absolute;
    width: inherit;
    height: inherit;
}
.public-profile{
    margin-bottom: 10px;
}
.rating-tab-menu{
    padding: 0px;
}
.rating-tab-menu > li{
    list-style-type: none;
    display: inline-block;
    padding-right: 20px;
}
.rating-tab-elem:hover{
    text-decoration: none;
}
.rating-tab-elem{
    text-decoration: none;
}
.active > .rating-tab-elem{
    cursor: default;
    font-weight: 700;
}
.boat-rating {
    position: absolute;
    right: 15px;
    top: 15px;
}
.profile-rating{
    margin-left: auto;
}
.black-link{
    color: #353c42;
    &:visited {
      color: #353c42;
    }
    &:hover {
      color: #353c42;
    }
    &:active {
      color: #353c42;
    }
}
.rating-tab {
    height: 0px;
}
.rating-tab.active {
    height: auto!important;
}
