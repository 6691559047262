.base-form {
  &._flex-form {
    display: flex;
    flex-wrap: wrap;
  }
  &__label {
    color: #8c959c;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    margin-bottom: 3px;

    &._rentals {
      margin-bottom: 8px;
    }
    &._filter-label {
      margin-bottom: 7px;
    }
    &._for-mobile {
      @include min-screen(375px) {
        display: none;
      }
    }
    &._for-desktop {
      @include max-screen(374px) {
        display: none;
      }
    }
  }
  &__group {
    border: 0;
    padding: 0;
    margin: 0;
    max-width: 270px;
    width: 100%;
    margin-right: 29px;
    padding-bottom: 0px;
    margin-bottom: 30px;

    @include max-screen(993px) {
      max-width: 222px;
    }
    @include max-screen(767px) {
      max-width: 100%;
      margin-right: 0;
    }

    &._term-of-use {
      font-size: 10px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    &._small {
      max-width: 170px;

      @include max-screen(992px) {
        max-width: 138px;
      }
      @include max-screen(767px) {
        max-width: 120px;
      }
    }
    &._full-width {
      max-width: 100%;
    }
    &._full {
      max-width: 100%;
      border-bottom: 0;
    }
    &._big {
      max-width: 570px;
    }
    &._features {
      margin-top: 30px;
    }
    &._half {
      max-width: 100%;
      width: 45%;
      @include max-screen(992px) {
        width: 100%;
      }
    }
    &._third {
      max-width: 100%;
      width: 25%;
      @include max-screen(992px) {
        width: 100%;
      }
    }
    &._info-third {
      max-width: 100%;
      width: 29%;
      @include max-screen(767px) {
        width: 46%;
      }
      @include screen(768px, 1100px) {
        width: 27.5%;
      }
    }
    &._rental {
      width: 100%;
      min-width: 100%;
      border-bottom: 0;

      @include max-screen(1100px) {
        max-width: 340px;
      }
    }
    &._filter-select {
      max-width: 150px;
      height: auto;
      padding-bottom: 6px;
      margin-right: 43px;

      &:last-child {
        margin-right: 0;
      }

      @include max-screen(1090px) {
        max-width: 345px;
      }
    }
    &._no-border {
      border: 0;
    }
    &._rental-date {
      max-width: 77px;
      padding-bottom: 3px;
      @include screen(576px, 992px) {
        max-width: 115px;
      }
    }
    &._devider {
      position: relative;
      margin-right: 38px;

      @include max-screen(992px) {
        margin-right: 80px;
      }
      @include max-screen(576px) {
        margin-right: 37px;
      }

      &::after {
        width: 13px;
        height: 2px;
        background-color: #b8ccd2;
        content: '';
        display: block;
        position: absolute;
        right: -27px;
        top: 20px;
        @include max-screen(992px) {
          right: -47px;
          top: 19px;
        }
        @include max-screen(576px) {
          right: -24px;
          top: 20px;
        }
      }
    }
    &._passenger-select {
      margin-right: 0;
      max-width: 77px;
      min-width: 50px;
      margin-top: 10px;
      @include max-screen(1090px) {
        max-width: 300px;
      }
      @include max-screen(992px) {
        float: right;
        max-width: 298px;
      }
    }
    &._form-day-select {
      max-width: 134px;
      width: 100%;
      @include max-screen(992px) {
        max-width: 341px;
      }
    }
    &._date-devider {
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 12px;
        height: 1px;
        background-color: #b8ccd2;
        position: absolute;
        top: 22px;
        right: -28px;
      }
    }
  }
  &__input {
    display: block;
    width: 100%;
    background: none;
    border: 0;
    color: #353c42;
    font-size: 14px;
    font-weight: 400;
    line-height: 35px;
    height: 35px;
    outline: none;
    position: relative;
    font-family: $base-font !important;
    border-bottom: 1px solid #b8ccd2;
  }
  &__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    top: -9px;
    left: -2px;

    &._filter {
      line-height: 48px;
    }
  }
  &__textarea {
    width: 100%;
    min-height: 140px;
    border: 1px solid #b8ccd2;
    outline-color: $base-color;
    max-width: 570px;
    padding: 5px;
    font-size: 14px;
    line-height: 18px;
    resize: none;

    &._rental-message {
	max-width: 100%;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	font-family: "Open Sans",sans-serif;
	display: block;
	width: 565px;
	@include max-screen(993px) {
	    width: 100%;
	}
    }

    &._rounded {
      border-radius: 5px;
    }

    &._full {
      max-width: 100%;
    }
  }
  &__number {
    -moz-appearance: textfield;
    position: relative;
    left: -3px;
    top: -6px;
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
  &__select-wrap {
    position: relative;
    height: 35px;
  }
  &__number-wrap {
    position: relative;
  }
  &__arrows-wrap {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    background-color: #fff;
    z-index: 1;
  }
  &__arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;

    &._up {
      top: 27%;
      transform: translate(-50%, 0) rotate(-180deg);
    }

    &._down {
      bottom: 27%;
    }
  }
  &__length-wrap {
    position: relative;
  }
  &__length-hint {
    width: 30px;
    height: 30px;
    background-color: #eef3f6;
    color: #919191;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    top: -3px;
    right: 3px;
    padding: 6px 8px;
    box-sizing: border-box;
    cursor: pointer;

    &._ft {
      right: 33px;
    }

    &._active {
      padding: 5px 7px;
      border: 1px solid #26c3df;
    }
  }
  &__group-devider {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    @include max-screen(767px) {
      margin-bottom: 31px;
    }
  }
  &__fieldset-hint {
    color: #848484;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    max-width: 190px;
    display: flex;
    align-items: center;
  }
  &__fieldset-hint-text {
    position: relative;
    padding-left: 25px;

    @include max-screen(1200px) {
      display: none;
    }

    &::before {
      position: absolute;
      left: 0;
      content: '\e925';
      font-family: icomoon !important;
    }
  }
  &__date-wrap {
    position: relative;
    padding-right: 22px;

    &:after {
      content: '\e947';
      color: #26c3df;
      font-family: $icon-font;
      position: absolute;
      right: -2px;
      top: 11px;
      font-size: 14px;
    }
  }
  &__date {
    line-height: 42px;
    height: 42px;
  }
  &__or-devider {
    height: 1px;
    background-color: #eef3f6;
    width: 100%;
    display: block;
    margin: 24px 0 23px;
    text-align: center;
    position: relative;
  }
  &__or-devider-text {
    position: relative;
    z-index: 50;
    background-color: #fff;
    top: -16px;
    color: #8c959c;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 0 13px;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &__rental-description {
    width: 100%;
  }

  &__description-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__checkbox-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__checkbox-group-title {
    width: 100%;
  }

  &__selector-wrap {
    display: inline-block;
    margin-left: 20px;
    font-size: 0;
  }
}

.check-form{
    padding: 0px;
}
.controls{
    display: inline-block;
    float: right;
    a{
	font-size: 14px;
	color: #8c959c;
    }
}

.vue-tags-input{
    max-width: 760px!important;
}

.ti-input{
    border: 0px!important;
}

.ti-tag{
    background-color: #26c3df!important;
}
