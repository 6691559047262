.spec{
   padding-top: 74px;
   padding-bottom: 50px;

   @include max-screen(992px){
     padding: 34px 25px 65px 25px;
   }

   @include max-screen(576px){
     padding-left: 0;
     padding-right: 0;
     padding-bottom: 23px;
     margin-right: 0;
     margin-left: 0;
   }

   &._make-money{
    padding-top: 91px;
    padding-bottom: 107px;
    background-color: #fff;

    @include max-screen(992px){
      padding-top: 60px;    
      padding-bottom: 64px;      
    }
    @include max-screen(576px){
      padding-top: 40px;
    }
   }

   &__btn-wrap{
     display: flex;
     justify-content: center;
     margin-top: 55px;

     @include max-screen(992px){
      margin-top: 38px;
     }
     @include max-screen(576px){
      margin-top: -5px;
     }
   }
 }