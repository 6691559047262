.base-tabs{
   border-bottom: 2px solid #eef3f6;
   margin-bottom: 10px;
   display: flex;

   &__item{
      color: #848484;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-right: 33px;
      padding-bottom: 15px;
      outline: 0;
      margin-bottom: -2px;

      &:hover{
         text-decoration: none;
      }
      
      &._is-active{
         color: #383838;
         line-height: 18px;
         border-bottom: 3px solid #26c3df;
         &:hover{
           color: $base-color;
         }
         @include max-screen(500px) {
            border: 2px solid #26c3df;
            &._rental {
               padding: 8px;
            }
         }
      }
      &._hide-mobile{
         @include max-screen(576px){
            display: none;
         }
      }

      @include max-screen(500px) {
         &._rental{
            box-sizing: border-box;
            width: 48%;
            margin-right: 6px;
            padding: 10px;
         }
      }
   }
}