.profile-boats{
   padding-top: 60px;
   padding-bottom: 60px;

   @include max-screen(992px){
      padding-top: 49px;
      padding-bottom: 40px;
   }

   &__title{
      text-align-last: left;
      margin-bottom: 20px;

      @include max-screen(992px){
         margin-bottom: 5px;
      }
   }

   &__wrapper {
      margin-bottom: 20px;
   }
}