.breadcrumb{
   &._blog {
      margin-bottom: 20px;
   }

   @include max-screen(992px){
      line-height: 24px;
   }
   
   &__item{
      color: #8c959c;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__devider{
      display: inline-block;
      vertical-align: middle;
      line-height: 100%;

      &::after{
         content: "\e912";
         font-family: "icomoon" !important;
         margin-left: 10px;
         margin-right: 10px;
         color: #8c959c;
      }
   }
}