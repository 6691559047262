.content-header{
   background-color: #fff;
   position: relative;
   width: 100%;
   position: fixed;
   z-index: 700;
   top: 0;
   left: 0;
   
   &__logo-img{
      @include max-screen(1140px){
         width: 120px;
       }
   }
   &__mobile-slide{
     position: absolute;
     top: 0;
     left: -500px;
     background-color: #353c42;
     height: 100%;
     width: 41px;
     display: flex;
     align-items: center;
     justify-content: center;
     transition: left .3s;
     height: calc(100% + 1px);

     &._is-show{
      left: 0;
     }
     &._main-page{
      max-height: 55px;
     }
   }
}