.post{
   padding-top: 63px;

   @include max-screen(992px){
      padding-top: 32px;
   }

   &__content{
      background-color: #ffffff;
      margin-top: 30px;
      padding-top: 40px;
      padding-bottom: 65px;
      &._create {
         padding-bottom: 0;
         padding-top: 0;
         margin-bottom: 40px;
      }
      @include max-screen(992px){
         margin-top: 60px;
         padding-top: 30px;
         margin-left: -15px;
         margin-right: -15px;
      }
      @include max-screen(767px){
         margin-top: 80px;
         padding-bottom: 60px;
      }
   }
   &__info{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      @include max-screen(992px){
         margin-bottom: 34px;
         flex-wrap: wrap;
      }
      @include min-screen(992px){
         padding-right: 5px;
      }
   }
   &__info-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 20px;
      margin-right: 14px;
   }
   &__info-profile-link{
      color: #383838;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
   }
   &__info-date{
      color: #919191;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__title{
      @include min-screen(992px){
         text-align: center;
      }
      @include max-screen(992px){
         margin-left: 15px;
         margin-right: 15px;
         margin-bottom: 15px;
      }
   }
   &__text{
      padding: 54px 60px 53px;

      @include max-screen(992px){
         padding-left: 15px;
         padding-right: 15px;
      }

      @include max-screen(767px){
         padding-bottom: 35px;

         &:last-child{
            padding-bottom: 33px;
         }
      }

      &:first-child{
         padding-top: 12px;
      }
      p{
         margin-top: 0;
         margin-bottom: 24px;
         &:last-child{
            margin-bottom: 0;
         }
      }
      li{
         color: #383838;
         font-size: 14px;
         font-weight: 400;
         line-height: 24px;
      }
      .ql-toolbar {
         position: sticky;
         top: 65px;
         background-color: #fff;
         z-index: 10;
      }
      .ql-formats {
         .ql-video {
            margin-bottom: 0;
         }
      }
      .ql-size-small {
         font-size: 0.8em;
      }
      .ql-size-large {
         font-size: 2em;
      }
      .ql-size-huge {
         font-size: 3em;
      }
      .ql-align-center {
         text-align: center;
      }
      .ql-align-right {
         text-align: right;
      }
      .ql-align-justify {
         text-align: justify;
      }
      .ql-video{
         display: block;
         width: 100%;
         height: 50vh;
         margin-bottom: 20px;
         border: 0;
      }
   }
   &__social-btns{
      padding: 0 60px;
      @include max-screen(992px){
         padding: 0 10px;
      }
      @include max-screen(767px){
         padding: 0 16px;
      }
   }

   &__popup-wrap {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, .3);
      z-index: 1100;
      text-align: center;
      
      &._visible {
         display: block;
      }
   }

   &__popup {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 30px;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 3px;

      &._modal {
         @include max-screen(576px){
            padding-left: 0;
            padding-right: 0;
         }
      }
   }

   &__popup-text {
      margin-bottom: 20px;
      text-align: center;
   }
}