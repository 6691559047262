.short-post {
  margin-bottom: 55px;
  @include max-screen(992px) {
    margin-bottom: 37px;
  }

  &__content {
    display: flex;
  }
  &__poster {
    position: relative;
    margin-bottom: 22px;
    display: block;
    text-align: center;
    @include max-screen(992px) {
      margin-bottom: 24px;
    }
    @include max-screen(573px) {
      margin-bottom: 23px;
    }
    @include screen(576px, 768px) {
      width: 100%;
    }
    &:hover {
      &::before {
        opacity: 0.5;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
  &__poster-img {
    @include max-screen(992px) {
      width: 100%;
    }
  }
  &__price {
    position: absolute;
    left: 0;
    top: 10px;
    background-color: #ff8400;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 0 10px;
    z-index: 100;

    &::after {
      content: '';
      background-image: linear-gradient(-78deg, transparent 6px, #ff8400 0);
      height: 100%;
      width: 10px;
      display: block;
      position: absolute;
      top: 0;
      right: -10px;
    }
  }
  &__price-day {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    @include min-screen(576px) {
      margin-left: 2px;
    }
  }
  &__user-info {
    width: 65px;
    margin-right: 15px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    @include max-screen(576px) {
      width: 62px;
      margin-right: 14px;
    }
  }
  &__user-info_name {
    color: #353c42;
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;

    @include max-screen(992px) {
      margin-top: 13px;
    }
    @include max-screen(576px) {
      margin-top: 11px;
    }
  }
  &__user-info_avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 4px;
    margin-top: 1px;
  }
  &__title {
    color: #353c42;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    @include max-screen(992px) {
      font-size: 18px;
    }
  }
  &__text {
    color: #353c42;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 3px;
    margin-bottom: 0;
  }
  &__slider {
    position: relative;

    &:hover {
      &::before {
        opacity: 0.2;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 10;
    }
  }
  &__slider-wrap {
    display: flex;
  }
  &__slide {
    width: 100%;
    height: 270px;
    background-color: #8d969d;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__slider-control {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 50px;
    z-index: 50;
    cursor: pointer;
    transition: background 0.5s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.14);
    }

    &::before {
      position: relative;
      font-family: $icon-font;
      color: #fff;
      font-size: 25px;
    }

    &._prev {
      left: 0;
      &::before {
        content: '\e90a';
      }
    }
    &._next {
      right: 0;
      &::before {
        content: '\e90b';
      }
    }
  }
}
