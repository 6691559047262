.comments{
   padding-top: 62px;
   &._nopadding {
     padding-top: 0;
   }

   @include max-screen(992px){
      padding-top: 54px;
   }

   &__add-comments{
      position: relative;
      background-color: #ffffff;
      padding: 30px;
      border-radius: 2px;
      @include max-screen(992px){
         padding: 8px;
      }
      &._reply {
         @include min-screen(767px) {
            margin-left: 60px;
         }         
      }
   }
   &__add-comments-avatar{
      width: 140px;
      height: 140px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 30px;
      @include max-screen(992px){
         display: none;
      }
   }
   &__add-comments-form-wrap{
      display: flex;
      justify-content: space-between;
   }
   &__add-comments-form{
      width: 100%;
   }
   &__add-comments-area{
      width: 100%;
      min-height: 140px;
      border: 1px solid #eef3f6;
      background-color: #ffffff;
      outline: 0;
      color: #848484;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding: 18px;
      box-sizing: border-box;
   }
   &__add-comments-foto{
      border: 1px solid #eef3f6;
      background-color: #ffffff;
      color: #8c959c;
      font-size: 37px;
      height: 54px;
      line-height: 54px;
      padding: 0px 15px;
      margin-right: 10px;

      &:hover{
         color: #fff;
         background-color: $base-color;
      }
   }
   &__title{
      margin-top: 21px;
      margin-bottom: 21px;

      @include max-screen(992px){
         margin-top: 5px;
         margin-bottom: 33px;
      }
   }
   &__add-comments-btns{
      display: flex;
      justify-content: flex-end;
      margin-top: 13px;
      @include max-screen(992px){
         margin-top: 3px;
      }
   }
   &__container{
      position: relative;
      padding: 25px 0 0 31px;
      
      @include max-screen(992px){
         padding: 43px 0 0 0px;
      }
   }
   &__thumb {
      display: inline-block;
      margin-right: 5px;
      width: 70px;
      height: 54px;
      background-size: cover;
   }
   &__auth{
      color: #383838;
   }
}
