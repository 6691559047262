.testimonials{
   padding-bottom: 81px;
   background-color: #fff;

   @include max-screen(992px){
      padding-bottom: 72px;
   }

   &__item {
      margin-bottom: 50px;
   }
}