.call-owner {
  padding-top: 48px;
  padding-bottom: 147px;
  @include max-screen(992px) {
    padding-top: 63px;
    padding-bottom: 56px;
  }

  &__text {
    color: #383838;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 44px;
    @include max-screen(992px) {
      margin-bottom: 33px;
    }
  }

  &__subtitle {
    color: #383838;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    margin-bottom: 51px;

    @include max-screen(992px) {
      font-size: 20px;
      margin-bottom: 31px;
    }

    @include max-screen(576px) {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 28px;
    }
  }

  &__poster {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -19px;
      right: 28px;
      background-image: url('../img/call-owner-wave.png');
      background-position: right bottom;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      z-index: -100;
      @include screen(992px, 1200px) {
        right: -10px;
      }
      @include max-screen(992px) {
        background-size: 80%;
        bottom: -8px;
        right: -15px;
      }
      @include max-screen(576px) {
        right: 0;
      }
    }

    @include max-screen(576px) {
      margin-bottom: 19px;
    }
  }

  &__title {
    margin-bottom: 10px;
    @include max-screen(992px) {
      margin-bottom: 20px;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0px;
    @include max-screen(992px) {
      padding-top: 8px;
    }
  }

  &__btn-wrap {
    @include screen(992px, 1200px) {
      display: flex;
    }
    @include screen(330px, 576px) {
      text-align: center;
    }
  }
}