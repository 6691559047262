.all-blogs{
   padding-top: 44px;

   @include max-screen(576px){
      padding-top: 37px;
      margin-right: -5px;
      margin-left: -5px;
   }

   &__item-title{
      font-size: 22px;
   }

   &__item{
      margin-bottom: 29px;
      @include max-screen(576px){
         margin-bottom: 10px;
      }
   }
}