.main-captains {
  padding: 108px 0 80px;
  background-color: #eef3f6;

  &__min-height {
    min-height: 210px;
  }

  &__mobile {
    &:nth-child(n+6){
       @include max-screen(767.98px){
         display: none;
       }
     }
  }

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    min-height: 168px;
  }

  &__avatar {
    flex: 0 0 auto;
    margin-bottom: 0;
    padding-bottom: 0;
    min-width: 130px;
    @include max-screen(767.98px) {
      min-width: 70px;
    }
  }

  &__main {
    flex: 1 1 auto;
    margin-left: 20px;
    @include max-screen(767.98px) {
      margin-left: 5px;
    }
  }

  &__header {
    margin-bottom: 10px;
  }

  &__info-location {
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding-left: 24px;
      margin-right: 14px;
      position: relative;

      @include max-screen(576px){
         margin-right: 26px;
      }

      &::before{
         content: '\e93b';
         color: #8d969d;
         position: absolute;
         left: 0;
         font-family: "icomoon" !important;
         font-size: 16px;
         top: 1px;
      }
   }

  &__icons-text {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__info-boat {
    color: #848484;
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include max-screen(576.98px) {
      flex-direction: column;
    }
  }

  &__title {
    text-align: center;
	color: #383838;
	font-size: 48px;
	font-weight: 400;
	margin: 0;
	margin-bottom: 44px;
	@include max-screen(992px){
	  font-size: 24px;
	  line-height: 30px;
	  margin-bottom: 34px;
	}
  }

  &__photo {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    @include max-screen(767.98px) {
      width: 50px;
      height: 50px;
    }
  }

  &__name {
    color: #353c42;
    font-weight: bold;
    font-size: 18px;
    @include max-screen(576.98px) {
      font-size: 16px;
    }
  }

  &__since_captain {
    display: inline-block;
    font-size: 12px;
    color: #848484;
    @include max-screen(576.98px) {
      display: block;
    }
  }

  &__info {
    min-width: 40%;
    font-size: 14px;
    color: #353c42;
  }

  &__info-elem {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__bottom-border {
    overflow: hidden;
    color: #fff;
    font-family: "Open Sans";
    font-size: 48px;
    font-weight: bold;
    line-height: 48px;
  }

  &__bottom-border:before,
  &__bottom-border:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    width: 100%;
    height: 2px;
    background: #fff;
    border: solid #eef3f6;
    border-width: 0 23px;
  }

  &__bottom-border:before {
    margin-left: -100%;
  }

  &__bottom-border:after {
    margin-right: -100%;
  }

  &__boat-photos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 204px;
    @include max-screen(576.98px) {
      justify-content: flex-start;
    }
  }

  &__boat-photo {
    position: relative;
    height: 65px;
    width: 98px;
    background-size: cover;
    margin: 2px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent 60%, #000 110%);
    }
  }

  &__boat-photo-capture {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 10px 5px 10px;
    font-family: "Open Sans";
    font-size: 12px;
  }

  &__rating-big {
    display: inline-block;
    float: right;
    @include max-screen(576.98px) {
      display: none;
    }
  }

  &__rating-small {
    display: none;
    @include max-screen(576.98px) {
      display: block;
    }
  }

  &__rating {
    position: relative;
    padding-left: 20px;
    font-weight: bold;
    color: #26c3df;
    background: url('../img/svg/rating.svg') no-repeat left center;

    font-size: 15px;
    margin-left: 20px;

    @include max-screen(1100px) {
        margin-left: 2px!important;
    }

    &:hover {
      .profile__rating-desc {
        display: block;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: -20px;
      left: 0;
    }
  }

  &__rating-desc {
    display: none;
    position: absolute;
    top: 25px;
    left: -260%;
    width: 264px;
    padding: 11px 14px;
    background-color: rgba(53, 60, 66, .9);
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    z-index: 100;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent rgba(53, 60, 66, .9) transparent;
    }

    & a {
      color: #26c3df;
    }
  }
}