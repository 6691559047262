.search-form{
   display: flex;
   justify-content: space-between;
   background-color: #fff;
   border-radius: 3px;

   &__input-wrap{
      width: 100%;
      position: relative;
      padding-left: 22px;
      display: flex;
      align-items: center;

      @include max-screen(576px){
         padding-left: 7px;
      }
   }
   &__input{
      height: 100%;
      width: 100%;
      border: 0;
      outline: 0;
      color: #919191;
      font-size: 14px;
      line-height: 18px;
      padding-left: 13px;
   }
   &__icon{
      color:  #8c959c;

      @include max-screen(576px){
         display: none;
      }
   }
   &__submit{
      &._for-mobile{
         @include min-screen(576px){
            display: none;
         }
      }
      &._for-desktop{
         @include max-screen(576px){
            display: none;
         }
      }
   }
}