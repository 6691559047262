.create-post {
  
  &__title {
    padding-bottom: 35px;
    font-size: 24px;
    font-weight: bold;
  }

  &__group {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    @include max-screen(767px) {
      flex-wrap: wrap;
    }
    
    &._editor {
      margin-bottom: 80px;
    }
  }

  &__elem {
    width: 100%;
    font-size: 14px;
    color: #8c959c;
    @include clearfix()
  }

  &__input {
    width: 100%;
    padding: 15px 0;
    border: 0;
    outline: 0;
    font-size: 14px;
    color: #353c42;
    border-bottom: 1px solid #b8ccd2;
  }

  &__select-wrap {
    padding: 5px 3px 0 3px;
    border: 1px solid #b8ccd2;
  }

  &__select {
    width: 100%;
  }

  &__wysiwyg {
    min-height: 150px;
  }

  &__error {
    display: block;
    margin-top: 10px;
    color: red;
  }

  &__button-wrap{
    display: flex;
    margin-top: -54px;
  }
}