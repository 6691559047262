.main-header{
  height: 968px;
  background-image: url('../img/main-header.jpg');
  background-color: #eef3f6;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 38px;
  @include max-screen(992px){
    height: 570px;
    padding-top: 5px;
  }
  @include max-screen(576px){
    margin-right: 0px;
    margin-left: 0px;
  }


  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 804px; 
    @include max-screen(992px){
      height: 496px;
    }
    @include max-screen(576px){
      height: 461px;
    } 
  }

  &__title{
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 21px;
    line-height: 100%;
    @include max-screen(992px){
      font-size: 32px;
      margin-bottom: 16px;
    }
    @include max-screen(576px){
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 11px;
    }
  }

  &__subtitle{
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    margin-bottom: 51px;
    @include max-screen(992px){
      font-size: 20px;
      margin-bottom: 31px;
    }
    @include max-screen(576px){
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 28px;
    }
  }

  &__icons-main {
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: 700;
    width: 90%;
    text-align: center;
    margin-bottom: 30px;
    position: relative;

    @include max-screen(992px){
      margin-bottom: 22px;
    }

    @include max-screen(767.98px){
      width: 80%;
    }

    @include max-screen(420px){
      width: 100%;
    }

  }

  &__icons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__icons-items {
    flex-grow: 1;
    flex-shrink: 1;
    width: 33.3%;
  }

  &__icons-text {
    margin-left: 20px;
    margin-right: 20px;
    opacity: .9 !important;

    @include max-screen(767.98px){
      display: block;
    }
  }

  &__start-earning{
    color: #ace5ff;
    font-weight: 400;
    transition: color .3s;
    position: relative;
    line-height: 18px;

    @include max-screen(576px){
      max-width: 180px;
      text-align: center;
      line-height: 24px; 
    }

    &::before{
      content: "\e946";
      color: #ace5ff;
      font-family: 'icomoon' !important;
      position: absolute;
      left: -40px;
      top: 2px;
      font-size: 26px;
      @include max-screen(576px){
        left: -35px;
        top: 14px;
      }
    }

    &:hover{
      text-decoration: none;
      color: #fff;
    }
  }
  &__start-earning-link{
    text-decoration: none;
    border-bottom: 1px dotted;
  }
  &__boad-logo{
    margin-right: 20px;
    display: none;
    align-items: center;
  }
  &__boad-wrap{
    width: 100%;
    padding: 0;
    transition: background, padding .3s;

    &._is-slicked{
      position: fixed;
      top: 0;
      z-index: 500;
      padding: 15px;
     background-image: url('../img/blur-bg.png');
     background-repeat: no-repeat;
     background-size: cover;
     border-bottom: 1px solid #61cdfd;
     box-shadow: 0 0 15px rgba(0,0,0,.2);

     .main-header__boad-logo{
      display: flex;
    }

     .boad-search{
       margin-bottom: 0;
       display: flex;

       &__form{
         transition: height .3s;
         height: 50px;
       }
       &__input{
        transition: height .3s;
        height: 50px;
       }
       &__submit{
        transition: height .3s;
         height: 50px;
         line-height: 50px;
       }
       &__icon{
        transition: top .3s;
        top: 17px;
       }
      }
     }
     
  }
}