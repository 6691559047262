.footer{
   min-height: 343px;
   background-color: #353c42;
   padding-top: 53px;
   @include max-screen(992px){
     padding-bottom: 18px;
   }
   @include max-screen(576px){
     padding-bottom: 54px;
   }
 
   &__logo-link{
     width: 150px;
     display: inline-block;
   }
   &__content{
     display: flex;
     flex-direction: column;
     @include max-screen(576px){
      padding-top: 24px;
     }
   }
   &__social{
     margin-top: 52px;
     padding-top: 4px;
     padding-bottom: 9px;
     
     @include max-screen(992px){
       margin-top: 48px;
     }
   }
   &__payments{
     width: 200px;
     margin-top: 16px;
     @include max-screen(1080px){
       display: flex;
     }
   }
   &__payments-system{
     width: 200px;
     margin-top: 16px;
   }
   &__payments-pay{
     color: #565e66;
     font-weight: 600;
     line-height: 16px;
     margin-bottom: 0;
     font-size: 10px;
   }
   &__payments-pay-img{
    height: 28px;
   }
   &__payments-img{
    margin-right: 15px;
    height: 16px;

    &:last-child{
        margin-right: 0;
    }
   }
   &__app-banners{
     margin-top: 16px;
     @include max-screen(1080px){
       display: flex;
     }
   }
   &__app-banners-link{
    margin-right: 5px;
    &:hover{
      text-decoration: none;
    }
    
    &:last-child{
        margin-right: 0;
    }

    @include max-screen(576px){
      &:first-child{
        margin-right: 8px;
      }
    }
  }
   &__copy{
     color: #565e66;
     font-size: 14px;
     font-weight: 400;
     line-height: 26px;
     margin-bottom: 0;
     margin-top: 35px;
   }
   &__reverse-row{
     @include max-screen(576px){
      flex-direction: column-reverse;
     }
   }

 }