@import "media";

[v-cloak] {
	display: none;
}

body {
	&._popup-open {
		overflow: hidden;
	}
}

a {
	cursor: pointer;
}
*::-webkit-input-placeholder {
	color: #666;
	opacity: 0.5;
}

*:-moz-placeholder {
	color: #666;
	opacity: 0.5;
}

*::-moz-placeholder {
	color: #666;
	opacity: 0.5;
}

*:-ms-input-placeholder {
	color: #666;
}

input::-ms-clear {
	height: 0;
	width: 0;
	display: none;
}

input {
	outline: none;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666;
}

body input:required:valid,
body textarea:required:valid {
	color: #000;
}
body input,
body textarea{
	color: #000;
}
html,
body {
	//	height: 100%;
	//	overflow-x: hidden;
}

body {
	min-width: 320px;
	position: relative;
	line-height: 1.75;
	margin: 0;
	font-family: $base-font;
	font-size: $base-font-size + px;

	&._menu-opened,
	&._dropdown-opened{
		overflow: hidden;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

a {
	text-decoration: none;
	color: #0d377b;
	outline: 0;
	transition: color, border-color .3s;
	
	&:hover {
		text-decoration: underline;
		color: #ff8400;
	}
}

p{
	color: #383838;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

.clearfix {
	display: table;
	content: '';
	clear: both;
}
.container{
	@include min-screen(992px){
	  width: 1200px;
	}
	&._content{
		@include screen(768px, 992px){
			width: 780px;
		}
	}
}
 
.content-container{
	max-width: 1170px;
	width: 100%;
	margin: 0 auto;

	@include max-screen(1200px){
		padding-left: 10px;
		padding-right: 10px;
	}
	@include min-screen(576px){
		max-width: 530px;
	}
	@include min-screen(767px){
		max-width: 770px;
	}
	@include min-screen(992px){
		max-width: 1170px;
	}

	&._rentals{
		@include max-screen(767px){
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&._margin{
		margin-top: 35px;
	}
}

.body-content{
	background-color: #eef3f6;
	padding-top: 65px;

	@include max-screen(992px){
		padding-top: 56px;
	}
}
.body-blank-page{
	background-color: rgba(222, 230, 238, 0.5);
	background-image: url('../img/blank-page-bg.png');
	background-repeat: no-repeat;

	@include max-screen(1400px){
		background-position: 40% 103%;
	}
	@include max-screen(1042px){
		background-position: 52% 103%;
		background-color: rgba(222,230,238,1);
	}
	@include max-screen(992px){
		background-position: 54% 103%;
	}
	@include max-screen(767px){
		background-position: 72% -5%;
	}
	
}
 
.base-title{
	text-align: center;
	color: #383838;
	font-size: 48px;
	font-weight: 400;
	margin: 0;
	margin-bottom: 54px;
	@include max-screen(992px){
	  font-size: 24px;
	  line-height: 30px;
	  margin-bottom: 35px;
	}
	&._content{
		text-align: left;
		margin-bottom: 16px;
		@include max-screen(992px){
			margin-bottom: 32px;
		}
	}
	&._small{
		color: #383838;
		font-size: 36px;
		font-weight: 400;
		@include max-screen(992px){
			font-size: 24px;
			text-align: left;
		}
	}
	&._smaller{
		font-size: 32px;
		margin-bottom: 25px;		
	}
	&._verified_info {
		color: $base-color;
		font-size: 24px;
		margin-bottom: 25px;

		@include max-screen(992px){
		  font-size: 16px;
		  line-height: 24px;
		  margin-bottom: 25px;
		}
	}
	&._verified_info:hover {
		text-decoration: none;
	}
	&._rental {
		text-align: left;
		font-size: 20px;
		line-height: 26px;
	}
	&._post{
		@include min-screen(992px){
         text-align: center;
      }
      @include max-screen(992px){
         margin-left: 15px;
         margin-right: 15px;
         margin-bottom: 15px;
      }
	}
	&._not-padding{
		margin: 0;
	}
	&._left{
		text-align: left;
	}
	&._profile{
		margin-bottom: 22px;
	}
}
.base-subtitle{
	color: #353c42;
	font-size: 18px;
	font-weight: 700;
	line-height: 18px;
	margin-top: 0;
	margin-bottom: 24px;
}
.table-hide{
	@include screen(576px, 992px){
	  display: none;
	}
 }
 .mobile-table-hide,
 ._mobile-table-hide{
  @include max-screen(992px){
	 display: none;
  }
 }
 .table-show{
	display: none;
	@include screen(576px, 992px){
	 display: flex;
  }
 }
 .mobile-table-show{
  display: none;
  @include max-screen(992px){
	 display: flex;
  }
 }
 .table-order-1{
  @include screen(768px, 992px){
	 order: 1;
  }
 }
 .table-order-2{
  @include screen(768px, 992px){
	 order: 2;
  }
 }
 .table-order-3{
  @include screen(768px, 992px){
	 order: 3;
  }
 }
 .table-order-4{
  @include screen(768px, 992px){
	 order: 4;
  }
 }
 .table-order-5{
  @include screen(768px, 992px){
	 order: 5;
  }
 }
 .table-order-6{
  @include screen(768px, 992px){
	 order: 6;
  }
 }

 ._table-reverse{
	 @include max-screen(992px){
		flex-direction: column-reverse;
	 }
 }
