.money-header{
   height: 760px;
   background-image: url('../img/money-bg.jpg');
   background-repeat: no-repeat;
   background-position:  center center;
   background-size: cover;
   background-color: #fff;
   padding-top: 32px;

   @include max-screen(992px){
      height: 469px;
      padding-top: 19px;
   }
   @include max-screen(576px){
      padding-top: 7px;
      height: 388px;
   }

   &__content{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-grow: 2;
      padding-bottom: 70px;

      @include max-screen(992px){
         padding-bottom: 104px;
      }
      @include max-screen(576px){
         padding-bottom: 48px;
      }
   }
   &__container{
      display: flex;
      flex-direction: column;
      height: 100%;
   }
   &__title{
      color: #ffffff;
      font-size: 48px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 8px;
      text-align: center;

      @include max-screen(992px){
         font-size: 32px;
         margin-bottom: 12px;
      }
      @include max-screen(576px){
         font-size: 18px;
         max-width: 250px;
         line-height: 24px;
         margin-bottom: 9px;
      }
   }
   &__subtitle{
      color: #ffffff;
      font-size: 24px;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
      text-align: center;

      @include max-screen(992px){
         font-size: 20px;
         line-height: 24px;
      }
      @include max-screen(576px){
         font-size: 16px;
         line-height: 22px;
      }
   }
   &__btn{
      margin-top: 60px;

      @include max-screen(992px){
         margin-top: 49px;
      }
      @include max-screen(576px){
         margin-top: 37px;
      }
   }
}