.dropdown-notice{
   display: none;
   box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
   background-color: #ffffff;
   padding: 20px 25px 10px;
   border-radius: 3px;
   position: absolute;
   top: 48px;
   left: -38px;
   width: 360px;

   &._float-left{
      @include min-screen(576px){
         left: auto;
         right: -20px;
   
         .dropdown-notice__triangle{
            right: 20px;
            left: auto;
         }
      }
   }

   @include max-screen(576px){
      right: 0;
      left: 0;
      top: 0;
      position: fixed;
      width: 100vw;
      height: 100%;
      z-index: 800;
      padding: 17px 10px 10px;
      
   }

   &__triangle{
      color: #ffffff;
      position: absolute;
      top: -24px;
      left: 37px;
      z-index: 300;
      transform: scaleX(1.5);
      font-size: 20px;
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

      @include max-screen(576px){
         display: none;
      }

      &:after{
         background-color: #fff;
         content: '';
         position: absolute;
         bottom: -2px;
         left: -5px;
         z-index: 400;
         width: 30px;
         height: 12px;
      }
      
   }
   &__header{
      display: flex;
      justify-content: space-between;
   }
   &__title{
      color: #848484;
      font-size: 16px;
      line-height: 24px;
   }
   &__header-link{
      border-bottom: 1px dashed #848484;
      display: inline-block;
      &:hover{
         border-color: $base-color;
      }
   }
   &__header-link-wrap{
      color: #848484;
      font-size: 12px;

      &:hover{
         text-decoration: none;
      }
   }
   &__item{
      display: flex;
      padding: 17px 0 13px;
      border-bottom: 2px solid #eef3f6;

      &:last-child{
         border-bottom: 0;
      }
   }
   &__item-poster{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 21px;
      margin-top: 3px;
      flex-shrink: 0;
   }
   &__item-title{
      color: #383838;
      font-size: 14px;
      line-height: 100%;
      margin-top: 3px;
      margin-bottom: 0;
      font-weight: 400;
   }
   &__item-login{
      color: #383838;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
   }
   &__item-content{
      padding-top: 10px;
   }
   &__item-time{
      margin-top: 4px;
      opacity: 0.5;
      color: #383838;
      font-size: 12px;
      line-height: 18px;
   }
   &__item-attach{
      display: inline-block;
   }
   &__item-text{
      margin-bottom: 3px;
      margin-top: 11px;
      font-size: 14px;
      line-height: 20px;

      &._system-notice{
         margin-top: 4px;
         margin-bottom: -2px;
      }
      @include max-screen(576px){
         margin-top: 5px;
      }
   }
   &__mobile-close{
      @include min-screen(576px){
         display: none;
      }

      &:hover{
         text-decoration: none;
      }
   }
   &__wrap {
      overflow: auto;
      max-height: 290px;
   }
   &__empty {
      font-size: 14px;
   }
}

.open-dropdown{
   position: relative;
}

.profile-notify {
    .notify__item {
        padding: 12px 25px;
    }
}
