.select2-container{
   height: 35px;

   &--default{
        .select2-selection--single{
            border-color: transparent;
            outline: 0;

            .select2-selection{
                &__arrow{
                    b {
                        border-color: #00c5dd transparent transparent transparent;
                        border-width: 6px 5px 0 5px;
                        margin-top: -5px;
                        margin-left: -1px;
                    }
                }
                &__rendered {
                    padding-left: 0px;
                    transition: padding .1s;
                    border-bottom: 1px solid #b8ccd2;
                }
            }
		}
		.select2-results__option--highlighted{
			&[aria-selected]{
				background-color: rgba(#353c42, .1);
                color: #353c42;
			}
        }
    }
    &--open{

        .select2-selection{
            &__arrow{
                b {
                    border-width: 0 5px 6px 5px !important;
                    border-color: transparent transparent #00c5dd transparent !important;
                }
            }
		}
		.select2-dropdown--below{
			border-top: 1px solid $base-color2;
		}
		.select2-dropdown--above{
			border-bottom: 1px solid $base-color2;
		}	
	}
}

.select2-dropdown{
	&--below,
	&--above{
		margin-top: 4px;
		border: 1px solid $base-color2;
		padding: 8px;
	}
	&--above{
		margin-bottom: 10px;
		margin-top: 0;
	}
}

.select2-results{
    &__option{
        color: #353c42;
        font-size: 14px;
        font-weight: 400;
        height: 35px;
    }
}

.select2-selection {
    &__rendered {
        @include max-screen(768px) {
            font-size: 14px;
        }
    }
}

.select2 {
    &__captain {
        min-width: 145px!important;
    }
}


.v-select .dropdown-toggle .clear { visibility: hidden; }
.v-select {
    padding-top: 5px;

    .dropdown-toggle {
	border: 0px!important;
    }
}
.dropdown-menu {
    margin-top: 4px!important;
    border: 1px solid #26c3df!important;
    padding: 8px!important;

    il{
	padding: 6px!important;
    }
}

.selected-tag {
    font-size: 16px!important;
    margin-left: 0px!important;
    border-left: 0px!important;
    padding-left: 0px!important;
}
