.topline-filter{
   width: 100%;
   max-width: 470px;
   margin-left: 50px;
   margin-right: 10px;

  &._is-no-login{
    margin-right: 10px;
  }

   @include max-screen (1200px){
     margin-left: 20px;
     margin-right: 20px;
   }
   @include max-screen (992px){
    display: none;
   }

   &__form{
      width: 100%;
      display: flex;
      height: 40px;
      border-radius: 3px 0 0 3px;
      overflow: hidden;
  
      @include max-screen(992px){
        height: 52px;
      }
      @include max-screen(768px){
        flex-wrap: wrap;
        height: auto;
      }
   }
   &__input{
      width: 100%;
      color: #848484;
      font-weight: 400;
      line-height: 18px;
      outline: 0;
      padding-left: 18px;
      height: 40px;
      border: 0;
      border-top: 1px solid #b8ccd2;
      border-bottom: 1px solid #b8ccd2;
      font-size: 14px;
  
      @include max-screen(992px){
        padding-left: 18px;
        height: 52px;
      }
      @include max-screen(768px){
        height: 54px;
      }
   }
   &__where-went{
       border-left: 1px solid #b8ccd2;
       min-width: 250px;
   }
   &__when-wrap{
      flex-shrink: 0;
      position: relative;
  
      @include max-screen(768px){
        width: 100%;
        border-top: 1px solid #b8ccd2;
      }
      @include min-screen(768px){
        border-left: 1px solid #b8ccd2;
      }
   }
   &__when-went{
      width: 129px;
      padding-right: 40px;
      padding-left: 10px;
      &::placeholder{
        padding-left: 10px;
      }
      @include screen(992px, 1140){
        font-size: 10px;
      }
      @include max-screen(1140px){
        width: 115px;
        padding-right: 46px;
      }
      @include max-screen(992px){
        width: 170px;
      }
      @include max-screen(768px){
        width: 100%;
      }
   }
   &__icon{
      position: absolute;
      top: 13px;
      right: 20px;
      font-size: 14px;

      @include max-screen(1140px){
        right: 15px;
      }
      @include max-screen(992px){
        top: 17px;
        right: 20px;
      }
   }
   &__submit{
      height: 40px;
      line-height: 40px;
      padding: 0 21px;
      color: #ffffff;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 18px;
      border-radius: 0 3px 3px 0;
      @include max-screen(992px){
        height: 52px;
        line-height: 52px;
        letter-spacing: 0.8px;
        padding: 0 44px;
      }
      @include max-screen(768px){
        width: 100%;
        font-size: 16px;
      }
   }
}
