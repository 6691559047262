.short-spec{
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
 
   @include max-screen(576px){
     margin-bottom: 39px;
   }

   &__padding {
     padding-left: 10px;
     padding-right: 10px;
   }
 
   &__title{
     color: #353c42;
     font-size: 24px;
     font-weight: 400;
     margin: 0;
     margin-bottom: 11px;
     @include max-screen(992px){
       font-size: 18px;
       margin-bottom: 14px;
     }
     @include max-screen(576px){
       margin-bottom: 17px;
     }
   }
   &__text{
     color: #919191;
     font-size: 14px;
     font-weight: 500;
     line-height: 20px;
     max-width: 450px;
     margin: 0;
     text-align: left;
     margin-bottom: 8px;

     @include max-screen(993px){
       color: #919191;
     }
     @include screen(576px, 992px){
       max-width: 200px;
     }
   }
   &__img{
     margin-bottom: 35px;
     @include max-screen(992px){
       margin-bottom: 30px;
     }
   }
  &__more-wrap{
    display: flex;
    justify-content: center;
    padding-top: 32px;
    @include max-screen(992px){
      padding-top: 15px;
    }
    @include max-screen(576px){
      padding-top: 29px;
    }
  }
 }