.payment {
  display: flex;
  padding-top: 30px;
  padding-bottom: 100px;

  @include max-screen(1100px) {
    flex-wrap: wrap;
  }

  &__wrap {
    min-height: 70vh;
  }

  &__about {
    min-width: 370px;
    max-width: 370px;
    padding: 26px 14px 70px 30px;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 3px;

    @include max-screen(1100px) {
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 40px;
    }
  }

  &__about-wrap {
    display: flex;
    align-content: center;
    margin-bottom: 40px;
  }

  &__boat-avatar {
    margin-right: 20px;
  }

  &__boat-name {
    font-size: 18px;
    font-weight: bold;
    color: #353c42;
  }

  &__desc {
    font-size: 14px;
  }

  &__options {
    width: 100%;
    margin-left: 30px;

    @include max-screen(1100px) {
      margin-left: 0;
    }
  }

  &__elem {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    padding: 25px;
    margin-bottom: 2px;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;

    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__details {
    width: 100%;
  }

  &__detail {
    display: flex;
    justify-content: space-between;
  }

  &__desctiption {
    margin-right: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  &__duration {
    margin-right: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  &__price {
    margin-left: auto;
    margin-right: 40px;
    font-size: 20px;
    font-weight: bold;

    @include max-screen(767px) {
      margin-right: 0;
    }
  }

  &__save {
    position: relative;
    padding-left: 30px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(../img/svg/i.svg) no-repeat center;
      transform: translate(0,-50%);
    }
  }

  &__old-price {
    padding-right: 15px;
    font-size: 18px;
    text-decoration: line-through;
  }

  &__new-price {
    color: #ff8400;
  }

  &__systems {
    display: flex;
    
    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__system {
    flex-grow: 1;
    height: 118px;
    border: 1px solid #b8ccd2;
    cursor: pointer;

    @include max-screen(767px) {
      width: 100%;
    }

    &._active {
      border: 2px solid #26c3df;
    }

    &._yandex {
      background: url('../img/yandex-logo.png') no-repeat center;
    }

    &._paypal {
      background: url('../img/paypal-logo.png') no-repeat center;
    }

    &:first-child {
      margin-right: -1px;
    }
  }

  &__subtitle {
    padding-top: 60px;
    padding-bottom: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #353c42;
  }

  &__types {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  &__type {
    display: flex;
    align-items: center;
    padding-left: 70px;
    padding-right: 10px;
    width: 33%;
    margin-left: -1px;
    margin-top: -1px;
    height: 100px;
    box-sizing: border-box;
    border: 1px solid #b8ccd2;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.5;
    background-position: 17px center;
    background-repeat: no-repeat;

    @include max-screen(767px) {
      width: 100%;
    }

    &._active {
      border: 2px solid #26c3df;
    }

    &._SB {
      background-image: url('../img/sb-logo.png');
    }

    &._WM {
      background-image: url('../img/wm-logo.png');
    }

    &._QW {
      background-image: url('../img/qiwi-logo.png');
    }

    &._AC {
      background-image: url('../img/card-logo.png');
    }

    &._AB {
      background-image: url('../img/alpha-logo.png');
    }

    &._GP {
      background-image: url('../img/money-logo.png');
    }

    &._PC {
      background-image: url('../img/yandex.money-logo.png');
    }

    &._PB {
      background-image: url('../img/ps-logo.png');
    }

    &._MC {
      background-image: url('../img/phone-logo.png');
    }

    &._CR {
      background-image: url('../img/parts-logo.png');
    }

    &._MA {
      background-image: url('../img/masterpass-logo.png');
    }
  }

  &__popup-opener {
    @include max-screen(767px) {
      margin-top: 10px;
      width: 100%;
      text-align: center;
    }
  }

  &__privacy-link {
    color: #353c42;
  }

  &__check {
    position: relative;
    width: 368px;
    margin: 20px auto 70px auto;
    padding: 40px 30px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 3px 3px 0 0;

    @include max-screen(767px) {
      width: 300px;
    }

    &::after {
      background: linear-gradient(-45deg, transparent 16px, #ffffff  0), linear-gradient(45deg, transparent 16px, #ffffff 0);
      background-position: left bottom;
      background-repeat: repeat-x;
      background-size: 23px 29px;
      content: " ";
      display: block;
      position: absolute;
      bottom: -23px;
      left: 0px;
      width: 100%;
      height: 32px;
    }
  }

  &__success-icon {
    position: relative;
    width: 124px;
    height: 124px;
    margin: 0 auto 40px auto;
    border-radius: 50%;
    background-color: #26c3df;

    &::after {
      content: '';
      position: absolute;
      width: 75px;
      height: 55px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('../img/svg/check-white.svg') no-repeat center;
      background-size: 90%;
    }
  }

  &__success-text {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 24px;
  }

  &__success-group {
    display: flex;
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__name {
    color: #8c959c;
  }

  &__info {
    color: $base-color;
    font-weight: bold;
  }

  &__value {
    margin-left: auto;
    font-weight: bold;
  }

  &__fail-text {
    margin-bottom: 50px;
    font-size: 14px;
    text-align: center;
  }

  &__currency {
    display: flex;
    margin-left: 10px;
    margin-bottom: -10px;
    @include max-screen(768px) {
      margin-left: auto;
    }
  }

  &__currency-elem {
    position: relative;
    width: auto;
  }
}