.post-header{
   height: 130px;
   display: flex;
   margin-top: 30px;
   background-color: #fff;
   align-items: center;

   @include max-screen(992px){
      height: 186px;
   }
   @include max-screen(767px){
      flex-direction: column;
      height: auto;
      padding-bottom: 30px;
   }

   &__poster{
      max-width: 210px;
      flex-shrink: 0;
      position: relative;
      @include max-screen(992px){
         max-width: 296px;
         margin-right: 40px;
      }
      @include max-screen(767px){
         max-width: 100%;
         width: 100%;
         margin-right: 0;
         margin-bottom: 23px;
      }
   }
   &__right-column{
      width: 100%;
      display: flex;
      align-items: center;

      @include max-screen(992px){
         flex-direction: column;
         height: 100%;
         justify-content: center;
         padding-right: 14px;
      }
      @include max-screen(576px){
         padding-right: 0;
      }
   }
   &__content{
      display: flex;
      width: 100%;
      @include max-screen(992px){
         margin-bottom: 17px;
      }
      @include max-screen(767px){
         margin-bottom: 27px;
      }
   }
   &__poster-img{
      max-height: 100%;
      @include max-screen(767px){
         width: 100%;
      }
   }
   &__profile-info{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-shrink: 0;
      margin-left: 40px;
      margin-right: 33px;
      @include max-screen(992px){
         margin-right: 14px;
         margin-left: 0;
      }
      @include max-screen(767px){
         padding-left: 17px;
         justify-content: flex-start;
      }
   }
   &__profile-info-img{
      margin-bottom: 17px;
   }
   &__profile-info-name{
      color: #353c42;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
   }
   &__price{
      position: absolute;
      left: 0;
      top: 10px;
      background-color: #ff8400;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      padding: 0 10px;

      &::after {
         content: '';
         background-image: linear-gradient(-78deg,transparent 6px,#ff8400 0);
         height: 100%;
         width: 10px;
         display: block;
         position: absolute;
         top: 0;
         right: -10px;
      }
   }
   &__price-day{
      color: #fff;
      font-size: 14px;
      font-weight: 400;
   }
   &__boat-info{
      justify-content: center;
      width: 100%;
   }
   &__boat-info-title{
      margin-bottom: 18px;
   }
   &__boat-info-place{
      &::before{
         font-size: 14px;         
      }
   }
   &__query-wrap{
      flex-shrink: 0;
      @include min-screen(992px){
         padding-right: 40px;
      }
   }
   &__query{
      height: 52px;
      line-height: 52px;
      font-size: 16px;
      font-weight: 400;
      padding: 0 95px;
      @include max-screen(992px){
         padding: 0px 122px;
      }
      @include max-screen(767px){
         padding: 0 70px;
      }
      &._edit {
         position: absolute;
         top: -7px;
         right: 20px;
         padding: 0 30px;
         @include max-screen(767px) {
            position: static;
            margin-top: 10px;
         }
      }
   }
   &__profile-avatar {
      width: 50px;
      border-radius: 50%;
   }
}