.mobile-menu{
    @include min-screen(0px){
	width: 0px;
	height: 0px;
        display: none;
    }
    @include max-screen(992px){
        display: block;
    }

   &._content-page{
      display: none;
      @include max-screen(992px){
         display: block;
      }
   }

   @include max-screen(992px){
      position: fixed;
      height: 100%;
      width: 100%;
      max-width: 320px;
      z-index: 500;
      background-color: #353c42;
      left: -500px;
      top: 54px;
      padding: 30px 45px;
      transition: left .3s;
      overflow-x: scroll;
   
      &._is-opened{
         left: 0;
      }
   }
   
   &__header-name{
      @include max-screen(992px){
         color: #ffffff;
         font-size: 14px;
         font-weight: 700;
         line-height: 18px;
      } 
   }
   &__header{
      display: none;

      @include max-screen(992px){
         display: flex;
         align-items: center;
      }   
   }
   &__header-avatar{
      @include max-screen(992px){
         width: 43px;
         height: 43px;
         border-radius: 50%;
         overflow: hidden;
         margin-right: 17px;
      }
   }
   &__links{
      @include max-screen(992px){
         display: flex;
         flex-direction: column;
         margin-top: 7px;
         margin-bottom: 17px;
         padding-left: 0;
      }
      &._extra-links{
         @include min-screen(992px){
            display: none;
         }
      }
     
   }
   &__link{
      @include max-screen(992px){
         color: #99a4ae;
         font-size: 16px;
         font-weight: 400;
         line-height: 36px;
      }
      
   }
   &__addboat{
      @include max-screen(992px){
         margin-bottom: 12px;
      }
      @include min-screen(992px){
         display: none;
      }
   }
}
