.add-boat{
   padding-top: 43px;
   padding-bottom: 19px;
   @include max-screen(992px){
      padding-top: 34px;
   }
   @include max-screen(767px){
      margin-left: -5px;
      margin-right: -5px;
   }
   &__content{
      margin-bottom: 64px;
      @include max-screen(992px){
         margin-bottom: 25px;
      }
      @include max-screen(767px){
         margin-bottom: 67px;
      }
      @include screen(768px, 992px){
         margin-left: 12px;
      }
      @include min-screen(992px){
         padding-bottom: 35px;
      }
   }
   &__nav{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include max-screen(767px){
         border-radius: 3px;
         border: 1px solid #26c3df;
         background-color: #fff;
         padding: 10px 20px;
         margin-bottom: 10px;
      }
   }
   &__nav-item{
      color: #848484;
      font-weight: 400;
      line-height: 36px;

      &._current {
         color: #000;
         font-weight: bold;
      }

      &._complete {
         position: relative;
         padding-left: 20px;
         color: #ff8400;

         &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 43%;
            display: block;
            width: 5px;
            height: 9px;
            border: solid #ff8400;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) translate(0, -50%);
         }
      }
   }
   &__nav-icon{
      color: #919191;
      font-size: 14px;
      position: relative;
      transition: transform .3s;
      @include min-screen(768px){
         display: none;
      }
   }
   &__nav-item-title{
      color: #383838;
      line-height: 14px;
      margin-top: -1px;
      display: block;
      margin-bottom: 11px;
      @include max-screen(992px){
         margin-bottom: 12px;
      }
      @include max-screen(767px){
         font-size: 16px;
         height: 54px;
         border: 1px solid #b8ccd2;
         line-height: 54px;
         border-radius: 3px;
         padding-left: 22px;
         padding-right: 18px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 22px;
      }

   }
   &__submit-wrap{
      border: 0;
      display: flex;
      justify-content: flex-end;
      padding-top: 4px;

      &.full {
         min-width: 100%;
         padding-top: 20px;
         margin-right: 0;
      }

      @include max-screen(767px){
         justify-content: center;
      }
   }
   &__submit{
      padding: 0 30px;
   }
   &__submit-icon{
      font-size: 18px;
      margin-right: 7px;
      vertical-align: middle;
   }
   &__bulk-input{
      @include screen(320px, 767px){
         margin-right: 20px;
      }
   }
   &__year-input{
      @include screen(430px, 767px){
         margin-right: 20px;
      }
   }
   &__checkbox {
      width: 33.3%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      input {
         display: none;
      }

      @include max-screen(767px) {
         width: 50%;
      }
   }
   &__custom-checkbox {
      display: block;
      width: 20px;
      min-width: 20px;
      height: 20px;
      border: 1px solid #ff8400;
      margin-right: 7px;
      position: relative;
   }
   &__radiobox {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      input {
         display: none;
      }
   }
   &__custom-radiobox {
      display: block;
      width: 20px;
      min-width: 20px;
      height: 20px;
      border: 1px solid #ff8400;
      margin-right: 7px;
      position: relative;
      border-radius: 50%;
   }
   &__text-checkbox,
   &__text-radiobox {
      font-size: 12px;
      line-height: 1;
   }
   &__photo-add-wrap {
      position: relative;
      width: 100%;
      @include screen(768px, 1000px) {
         padding-right: 20px;
      }
   }
}

.add-boat__checkbox input:checked + .add-boat__custom-checkbox {
   &:after {
      content: '✓';
      color: #ff8400;
      display: block;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 23px;
      position: absolute;
      top: -5px;
      left: 0;
   }
}

.add-boat__radiobox input:checked + .add-boat__custom-radiobox {
   &:after {
      content: '✓';
      color: #ff8400;
      display: block;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 21px;
      position: absolute;
      top: 0;
      left: 0;
   }
}

.base-form.bottom {
   overflow: hidden;
   margin-bottom: 35px;
}

#mapBoat {
   width: 100%;
   height: 400px;
}

.photos-form-wrap {
   position: relative;
   width: 100%;
   display: flex;
   justify-content: flex-start;
   flex-direction: row;
   flex-wrap: wrap;
   @include max-screen(1100px) {
      justify-content: space-between;
   }
}

.photos-item {
   position: relative;
   min-width: 24%;
   max-width: 24%;
   overflow: hidden;
   border: 1px solid #b8cdd2;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 180px;
   flex-direction: column;
   margin-bottom: 10px;
   position: relative;
   @include min-screen(1144px) {
      margin-right: 10px;
      &:nth-child(4n + 4) {
         margin-right: 0;
      }
   }
   @include max-screen(1144px) {
      min-width: 48%;
      max-width: 48%;
      height: 100px;
   }

   &:hover {
      .photos-del-overlay {
         opacity: 1;
      }
      .photos-set-main-overlay {
         opacity: 1;
      }
      .photo-main-overlay {
         opacity: 1;
      }
   }

   img {
      display: block;
      width: 100%;
      height: auto;
   }

   input {
      display: none;
   }

   &.is-main {
      border-color: #26c3df;
   }

   &.add {
      cursor: pointer;
   }

   &._spinner {
      border: 0;
   }

   .preloader-avatar {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: none;
   }

   span {
      font-size: 22px;
      text-align: center;

      &.photo-plus {
         font-size: 30px;
      }
      @include max-screen(767px) {
         font-size: 16px;
         
         &.photo-plus {
            font-size: 24px;
         }
      }
   }
}

.photos-del-overlay {
   position: absolute;
   width: 30%;
   height: 30%;
   top: 0;
   left: 0;
   background-color: rgba(#000, .2);
   opacity: 0;
   transition: opacity .3s;
   cursor: pointer;
   &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20%;
      height: 2px;
      background-color: #fff;
   }
   &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
   }
   &::after {
      transform: translate(-50%, -50%) rotate(45deg);
   }
   @include max-screen(1000px) {
      opacity: 1;
   }
}

.photos-set-main-overlay {
   position: absolute;
   width: 30%;
   height: 30%;
   top: 0;
   left: 70%;
   background-color: rgba(#26c3df, .5);
   opacity: 0;
   transition: opacity .3s;
   cursor: pointer;
   &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20%;
      height: 2px;
      background-color: #fff;
   }
   &::before {
      transform: translate(-10%, -20%) rotate(-45deg);
   }
   &::after {
      transform: translate(-80%, -80%) rotate(45deg);
   }
   @include max-screen(1000px) {
      opacity: 1;
   }
}

.photo-main-overlay {
   position: absolute;
   width: 100%;
   height: 15%;
   bottom: 0;
   left: 0;
   background-color: rgba(#26c3df, .2);
   opacity: 0;
   transition: opacity .3s;
   cursor: pointer;
   text-align: center;
   color: #ff8400;
   @include max-screen(1000px) {
      opacity: 1;
   }
   @include max-screen(1144px) {
      min-height: 30%;
      max-height: 30%;
   }
}

.photos-input {
   position: absolute;
   width: 0;
   height: 0;
}
