.sails-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include min-screen(768px) {
      min-width: 320px;
    }
  }
  &__content-group-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @include max-screen(449px) {
      margin-top: 20px;
      padding-left: 0;
      flex-direction: column;
    }
  }
  &__input {
    height: 54px;
    line-height: 54px;
    border: 1px solid #b8ccd2;
    color: #848484;
    font-size: 16px;
    font-weight: 400;
    padding: 0 20px;
    outline: 0;
    margin-bottom: 10px;
    border-radius: 2px;

    &._modal {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      margin-bottom: 5px;
    }

    &._medium {
      width: 100%;
      max-width: 340px;
      margin-bottom: 20px;
    }

    &.map {
      height: 42px;
    }

    &.error {
      border: 1px solid #9c3535;
    }

    &._payment {
      display: block;
    }
  }
  &__checkbox {
    display: none;

    &:checked + .sails-form__fake-checkbox::after {
      content: '\e91f';
      font-family: icomoon !important;
      color: #ff8400;
      font-size: 13px;
      position: absolute;
      top: -2px;
      left: 3px;
    }
  }
  &__fake-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #b8ccd2;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    flex-shrink: 0;

    @include max-screen(768px) {
      align-self: flex-start;
      margin-top: 5px;
    }
  }
  &__label {
    display: inline-block;
    vertical-align: middle;
    color: #383838;
    font-size: 16px;
    font-weight: 400;
    &._checkbox {
      display: inline-flex;
      align-items: center;

      @include max-screen(576px) {
        margin-bottom: 11px;
      }
    }
    &._list {
      margin-bottom: 12px;
    }
  }
  &__error {
    margin-bottom: 10px;
    font-size: 12px;
    color: red;

    &._modal {
      margin-bottom: 5px;
      font-size: 10px;
    }
  }
  &__submit {
    &:disabled {
      background-color: #848484;
      &:hover {
        background-color: #848484;
      }
    }
  }
  &__button {
    text-align: center;

    @include max-screen(576px){
        margin-bottom: 10px;
        max-width: 340px;
    }
    &:last-child{
        @include max-screen(576px){
            margin-bottom: 0;
        }
    }
  }
  &__hidden-radio {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    &:checked + label {
      padding: 5px 7px;
      border: 1px solid #26c3df;
    }
  }
}

.base-form__group.full {
  max-width: 22%;
  min-width: 22%;
  margin-right: 0px;
  flex: 1;
  @include max-screen(1100px) {
    max-width: 45%;
    min-width: 45%;
  }
  @include max-screen(767px) {
    max-width: 44%;
    min-width: 42%;
  }
}
