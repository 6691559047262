.grid{

   display: flex;
   flex-wrap: wrap;
   margin-left: -15px;
   margin-right: -15px;

   @include max-screen(992px){
      margin-left: -5px;
      margin-right: -5px;
   }

   &._popular-boat{
      @include max-screen(992px){
         margin-left: -10px;
         margin-right: -10px;
      }
      @include max-screen(576px){
         margin-left: -5px;
         margin-right: -5px;
      }
   }

   &__item{
      flex: 1;
      padding: 0 15px;

      @include max-screen(992px){
         padding: 0 5px;
      }

      &._col-ssm-12{
         @include min-screen(320px){
            flex: 0 0 100%;
            max-width: 100%;
         }
      }
      &._col-xs-6{
         @include min-screen(576px){
            flex: 0 0 50%;
            max-width: 50%;
         }
      }
      &._col-sm-4{
         @include min-screen(768px){
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
         }
      }
      &._col-sm-6{
         @include min-screen(768px){
            flex: 0 0 50%;
            max-width: 50%;
         }
      }
      &._col-md-4{
         @include min-screen(992px){
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
         }
      }
      &._col-md-6{
         @include min-screen(992px){
            flex: 0 0 50%;
            max-width: 50%;
         }
      }

      &._hide-md-down{
         @include max-screen(992px){
            display: none;
         }
      }

      &._popular-boat{
         @include max-screen(992px){
            padding: 0 10px;
         }
      }
      &._control-btn{
         @include max-screen(992px){
            padding: 0 10px;
         }
      }
   }
}