.popup {

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.7);
    overflow-y: auto;
    z-index: 701;
    text-align: center;
  }

  &__payment {
    display: inline-block;
    margin: 50px auto 200px auto;
    padding: 47px 60px;
    width: 760px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #fff;
    text-align: left;
    z-index: 702;

    @include max-screen(1100px) {
      margin: 50px auto 400px auto;
    }

    @include max-screen(767px) {
      max-width: 90%;
      min-width: 90%;
      padding: 20px;
      margin: 50px auto 650px auto;
    }
  }

  &__button-wrap  {
    display: flex;
    align-items: center;

    @include max-screen(767px) {
      flex-wrap: wrap;
    }
  }

  &__button-desc {
    margin-left: 20px;
    max-width: 260px;
    font-size: 14px;
    color: #8c959c; 
    line-height: 1.3;

    @include max-screen(767px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}