.boat-sales{
   position: relative;
   background-color: #fff;
   padding-top: 62px;
   padding-bottom: 70px;
   min-height: 400px;

   @include max-screen(992px){
      padding-top: 53px;
      padding-bottom: 52px;
   }
   @include max-screen(576px){
      padding-bottom: 48px;
   }

   &._profile-booking{
      padding-bottom: 69px;

      @include min-screen(992px){
         min-height: 705px;
      }
      @include max-screen(992px){
         padding-bottom: 54px;
         margin-bottom: -14px;
      }
   }

   &._rentals {
      padding-top: 0;
   }

   &._success {
      min-height: 60vh;
      text-align: center;
   }

   &._payment {
      background-color: #eef3f6;
   }

   &__title{
      text-align-last: left;
      margin-bottom: 17px;
      @include max-screen(992px){
         margin-bottom: 30px;
      } 
   }

   &__spinner-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 100;

      &._hidden {
         display: none;
      }
   }

   &__tabs {
      @include max-screen(767px) {
         flex-wrap: wrap;
      }
   }

   &__spinner {
      position: absolute;
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('../img/svg/helm.svg');
      background-size: cover;
      background-repeat: no-repeat;
      animation: rotating 2s linear infinite;
      &._inline {
        top: 2px;
        left: 37%
      }
   }
}

@keyframes rotating {
   from {
      transform: translate(-50%, -50%) rotate(0deg);
   }
   to {
      transform: translate(-50%, -50%) rotate(360deg)
   }
}
