.boad-search{
   width: 100%;
   max-width: 1170px;
   margin-bottom: 41px;
   margin-left: auto;
   margin-right: auto;
   @include max-screen(992px){
     margin-bottom: 45px;
     max-width: 739px;
     margin-left: auto;
     margin-right: auto
   }
   @include max-screen(767px){
     margin-bottom: 25px;
     max-width: 390px;
   }


   &__form{
     width: 100%;
     display: flex;
     height: 64px;
     border-radius: 3px 0 0 3px;
     overflow: hidden;
 
     @include max-screen(992px){
       height: 52px;
     }
     @include max-screen(767px){
       flex-wrap: wrap;
       height: auto;
     }
   }
   &__input{
     width: 100%;
     color: #848484;
     font-size: 16px;
     font-weight: 400;
     line-height: 18px;
     outline: 0;
     padding-left: 22px;
     height: 64px;
     border-color: transparent;
     border-radius: 0;
 
     @include max-screen(992px){
       padding-left: 18px;
       height: 52px;
     }
     @include max-screen(767px){
       height: 54px;
     }
   }
   &__when-wrap{
     flex-shrink: 0;
     position: relative;
 
     @include max-screen(767px){
       width: 100%;
       border-top: 1px solid #b8ccd2;
     }
     @include min-screen(768px){
       border-left: 1px solid #b8ccd2;
     }
   }
   &__when-went{
     width: 200px;
     padding-right: 55px;
     @include max-screen(992px){
       width: 170px;
     }
     @include max-screen(767px){
       width: 100%;
     }
   }
   &__where-went{
     @include max-screen(768px){
      border-radius: 3px 3px 0 0;
     }
   }
   &__icon{
     position: absolute;
     top: 23px;
     right: 28px;
     @include max-screen(992px){
       top: 17px;
       right: 20px;
     }
   }
   &__submit{
     height: 64px;
     line-height: 64px;
     padding: 0 75px;
     color: #ffffff;
     font-weight: 400;
     text-transform: uppercase;
     @include max-screen(992px){
       height: 52px;
       line-height: 52px;
       letter-spacing: 0.8px;
       padding: 0 44px;
     }
     @include max-screen(767px){
       width: 100%;
       font-size: 16px;
       border-radius: 0 0 3px 3px;
     }
   }
 }