.personal-message{
   display: flex;
   align-items: flex-start;
   align-self: flex-start;
   flex-shrink: 0;
   margin-bottom: 20px;  

   &._is-my-message{
      align-self: flex-end;
      flex-direction: row-reverse;

      .personal-message__text{
         background-color: $base-color;
         color: #fff;

         &:after{
            border-left-color: $base-color;
            border-right-color: transparent;
            left: auto;
            right: -13px;
         }
      }
      .personal-message__poster-date{
         margin-right: 0;
         margin-left: 30px;

         @include max-screen(992px){
            margin-left: 20px;
         }
      }
   }

   &__text{
      border-radius: 3px;
      background-color: #ffffff;
      color: #383838;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 15px 30px 15px 18px;
      max-width: 399px;
      position: relative;

      @include max-screen(992px){
         max-width: 239px;         
      }

      &:after {
         top: 18px;
         left: -6px;
         border: solid transparent;
         content: " ";
         height: 0;
         width: 0;
         position: absolute;
         pointer-events: none;
         border-color: rgba(136,183,213,0);
         border-right-color: #fff;
         border-width: 7px;
         margin-left: -7px;
     }
   }
   &__poster-date{
      margin-right: 30px;
      text-align: center;

      @include max-screen(992px){
         margin-right: 21px;
      }
   }
   &__poster{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 6px;
      margin-bottom: -3px;
      display: block;
   }
   &__date{
      opacity: 0.6;
      color: #848484;
      font-size: 12px;
      font-weight: 400;
      line-height: 100%;
      display: inline-block;  
   }
   &__add{
      background-color: #ffffff;
      border-radius: 2px;
      @include min-screen(992px){
         padding: 30px;
      }
   }
   &__add-avatar{
      width: 140px;
      height: 140px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 30px;
      @include max-screen(992px){
         display: none;
      }
   }
   &__add-form-wrap{
      display: flex;
      justify-content: space-between;
   }
   &__add-form{
      width: 100%;
      @include max-screen(992px){
         height: 54px;
      }
   }
   &__add-area{
      width: 100%;
      background-color: #ffffff;
      outline: 0;
      color: #848484;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding: 18px;
      box-sizing: border-box;
      resize: none;

      @include max-screen(992px){
         height: 54px;
         line-height: 54px;
         padding: 0 18px;
      }
      @include max-screen(576px){
        overflow: hidden;
        min-height: 54px;
        line-height: 24px;
        padding-top: 15px;
      }
      @include min-screen(992px){
         min-height: 80px;
         border: 1px solid #eef3f6;
      }
   }
   &__add-foto{
      border: 1px solid #eef3f6;
      background-color: #ffffff;
      color: #8c959c;
      font-size: 37px;
      height: 54px;
      line-height: 54px;
      padding: 0px 15px;
      margin-right: 10px;

      @include max-screen(576px){
        margin-right: 2px;
      }

      &:hover{
        color: #fff;
        background-color: $base-color;
      }
   }
   &__add-btns{
      display: flex;
      justify-content: flex-end;
      margin-top: 13px;
      @include max-screen(992px){
         display: none;
      }
   }
   &__mobile-btns{
      display: flex;
      @include min-screen(992px){
         display: none;
      }
   }
}